el-buen-inquilino-policy-detail-modal {
  .cotenants-wrapper,
  .sinisters-wrapper,
  .empty-sinisters {
    border: 1px solid var(--ion-color-medium);
    padding: 1rem;
    border-radius: 1rem;
  }

  .insurance-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
      ion-icon {
        color: var(--ion-color-dark-tint);
      }
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .input-disabled {
    opacity: 1 !important;
  }

  .sinisters-wrapper {
    border: 1px solid var(--ion-color-medium);
    padding: 1rem;
    border-radius: 1rem;
  }

  .sinister-more-info {
    display: grid;
    align-items: center;
    grid-template-columns: 15% 50% 35%;
    .sinister-status-wrapper {
      text-align: right;
    }
  }

  .document-chip {
    --background: var(--ion-color-success);
    --color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 0.5rem;
  }
}
