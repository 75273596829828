el-buen-inquilino-tenant-profile-form {
  .has-error {
    border-bottom: 2px solid var(--ion-color-danger);
  }

  ion-card {
    margin: 1rem;
    h4 {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
      color: var(--ion-color-primary);
    }

    .current-address-wrapper,
    .professional-data-wrapper {
      margin-top: 1rem;
    }
  }

  .user-data-form,
  .form {
    ion-item {
      &::part(native) {
        align-items: end;
      }
    }
  }

  .separator {
    height: 100%;
    border: 2px solid var(--ion-color-light-shade);
    width: 0;
    border-radius: 5px;
  }

  ion-text.error-msg {
    margin-left: 54px;
  }

  .password-img {
    max-height: 100px;
  }
}
