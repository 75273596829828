el-buen-inquilino-owner-analytics-pdf-modal {
  .floating-label {
    -webkit-transform: translate3d(20px, 35px, 0) !important;
    transform: translate3d(20px, 35px, 0) !important;
  }

  .item-has-focus,
  .item-has-value {
    .floating-label {
      -webkit-transform: translate3d(20px, -5px, 0) !important;
      transform: translate3d(20px, -5px, 0) !important;
    }
  }

  ion-input,
  ion-textarea {
    border: 1px solid rgba(5, 28, 44, 0.1);
    border-radius: 16px;
    --padding-start: 20px !important;
    --padding-end: 20px !important;
    --padding-top: 16px !important;
    --padding-bottom: 16px !important;
  }

  .item-interactive.ion-invalid.ion-touched.ion-dirty {
    border-bottom: 0;
    ion-input,
    ion-textarea {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }

  .insurance-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
      ion-icon {
        color: var(--ion-color-dark-tint);
      }
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .cotenants-wrapper,
  .sinisters-wrapper,
  .empty-sinisters {
    border: 1px solid var(--ion-color-medium);
    padding: 1rem;
    border-radius: 1rem;
  }

  .sinister-more-info {
    display: grid;
    align-items: center;
    grid-template-columns: 15% 50% 35%;
    .sinister-status-wrapper {
      text-align: right;
    }
  }
}
