el-buen-inquilino-add-building-modal {
  el-buen-inquilino-asset-map {
    &.edit {
      height: 300px;
      display: block;
    }
    &.map {
      height: 600px;
    }
  }

  .form-wrapper {
    overflow-y: auto;
  }

  .floating-label {
    -webkit-transform: translate3d(20px, 35px, 0) !important;
    transform: translate3d(20px, 35px, 0) !important;
  }

  .item-has-focus,
  .item-has-value {
    .floating-label {
      -webkit-transform: translate3d(20px, -5px, 0) !important;
      transform: translate3d(20px, -5px, 0) !important;
    }
  }

  .member-wrapper {
    --border-width: 1px;
    --border-style: solid;
    --border-color: #d2d2d2;
    --border-radius: 8px;
    --padding-end: 8px;
    --inner-padding-end: 8px;
    --padding-start: 8px;
    --inner-padding-start: 8px;
    .check {
      color: #219653;
    }
    .close-icon {
      color: rgba(2, 31, 55, 0.8);
    }
    .info {
      color: var(--ion-color-warning);
    }
  }

  ion-footer {
    ion-toolbar {
      --border-width: 0;
    }
  }

  .item-interactive.ion-invalid.ion-touched.ion-dirty {
    border-bottom: 0;
    ion-input {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }

  .agent-p {
    padding-left: 5px;
  }
}
