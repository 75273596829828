el-buen-inquilino-request-analysis-page {
  .grid-content {
    background-color: var(--ion-color-light-tint);
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  .fullscreen {
    width: 100%;
    height: 100%;
  }

  .detailed-analysis {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .linkedin_logo {
    font-size: 14px;
    align-items: center;
    color: #0e76a8;
    margin-left: 10px;
  }

  .score-card {
    margin: 0;
    margin-bottom: 1rem;
    ion-card-content {
      padding: 1rem !important;
    }
  }

  .tenant_title_info {
    margin-left: 15px;
  }

  ion-card-content {
    &.card-content-ios {
      padding: 0;
      h1 {
        font-size: 16px;
      }
      h2 {
        font-size: 14px;
      }
      h3 {
        font-weight: bold;
      }
    }
  }

  ion-card ion-card-header {
    ion-card-title,
    p {
      font-size: 14px;
      margin: 0;
    }
  }
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-weight: bold;
  }

  ion-card-content,
  ion-card-content ion-col {
    padding: 0;
  }

  .modify-column {
    display: flex;
    align-items: center;
    flex-direction: column;

    ion-card {
      margin: 5px 0;
    }
  }
  .success-bar {
    width: 100%;
  }

  .border-top {
    border-top: 1px solid #ccc;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .row-user {
    cursor: pointer;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 4px;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: var(--ion-color-light-tint);
    }
    ion-badge {
      margin-left: 0.5rem;
      margin-bottom: -3px;
    }
    ion-col {
      padding-right: 0;
    }
  }

  .incidences {
    &-item {
      margin: 0.5rem 0;
    }
    ion-text {
      p {
        margin: 0;
      }
    }
  }

  ion-icon {
    font-size: 1rem;
  }
  ion-button {
    ion-icon {
      font-size: 1rem;
    }
  }

  ion-textarea.ion-no-padding {
    --padding-top: 0;
    --padding-bottom: 0;
  }

  .badge-scoring-modal {
    font-size: 12px;
    height: 30px;
    line-height: 24px;
  }

  .assigned-text {
    padding-right: 20px;
  }

  .icon-space {
    min-width: 1rem;
    text-align: center;
    cursor: help;
  }

  .neutro {
    color: black;
  }

  .negativo {
    color: red;
  }

  .positivo {
    color: green;
  }

  .report-history-warning {
    --background: var(--ion-color-warning);
    --border-radius: 0.5rem;
  }

  .report-history-modal .ion-page {
    overflow-y: scroll;
  }

  ion-row {
    margin: 0;
    ion-col {
      padding: 0.5rem 0.8rem;
    }
    &.table-header {
      background-color: var(--ion-color-danger-shade);
      color: var(--ion-color-light);
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
    }
    &.table-body {
      color: var(--ion-color-primary);
      font-size: 14px;
      p {
        margin: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
      &:first-child {
        font-weight: 500;
        background-color: var(--ion-color-dark);
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }

  .container-condition-selects {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    ion-select {
      padding-left: 0;
      font-size: 14px;
    }
    .condition-select {
      min-width: 250px;
    }
    .condition-opts-select {
      min-width: 550px;
    }
  }

  .date {
    font-size: 12px;
    font-weight: bold;
  }

  .candidature-notes-card {
    max-height: 600px;
    height: 100%;
  }

  .scores-wrapper {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: repeat(2, auto);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .score-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .score-color-wrapper {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    &.success-score {
      background-color: var(--ion-color-success);
    }
    &.warning-score {
      background-color: var(--ion-color-warning);
    }
    &.danger-score {
      background-color: var(--ion-color-danger);
    }
  }
}
