el-buen-inquilino-experian-searches-page {
  ion-content {
    --padding-top: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    --padding-bottom: 1rem;
  }

  section {
    border: 1px solid var(--ion-color-light-shade);
    border-radius: 1rem;
    padding: 0.5rem;
    &.forms-section {
      height: 100%;
    }
  }

  .company-section {
    position: relative;
    .form-row {
      height: calc(100% - 34px);
      .button-row {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
  }

  .experian-response-section {
    margin-left: 5px;
    margin-right: 5px;
  }
}
