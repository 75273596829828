el-buen-inquilino-portfolio-roles-api-details-page {
  ion-content {
    --padding-top: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    --padding-bottom: 1rem;
  }

  .mat-mdc-tab-body-wrapper {
    border: 1px solid var(--ion-color-medium);
    padding: 1rem;
  }

  .mdc-tab--active {
    border: 1px solid var(--ion-color-medium);
    border-bottom: 0;
    .mdc-tab-indicator {
      .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
        border-color: transparent;
      }
    }
  }
}
