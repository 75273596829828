el-buen-inquilino-home-page {
  .profile {
    --background: #f5f6f9;
    --padding-bottom: 3rem;
    .ebi-alert {
      background-color: var(--ion-color-warning-tint);
    }
  }
  ion-progress-bar {
    height: 1rem;
    border-radius: 1rem;
  }

  ion-card {
    ion-card-title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .right-column {
    padding: 1rem;
  }

  .rocket-img {
    margin-top: 2rem;
  }

  .guarantor-container {
    img.woman-keys {
      height: 150px;
      margin-left: 2rem;
      float: right;
    }
  }

  @media (max-width: 1199px) {
    .complete-profile-item {
      --padding-start: 0;
      --inner-padding-end: 0;

      ion-label {
        white-space: break-spaces;
      }
    }

    .want-offerts-label {
      white-space: break-spaces;
    }
  }

  .color-danger {
    color: var(--ion-color-danger);
  }
  .color-success {
    color: var(--ion-color-success);
  }
  .color-medium {
    color: var(--ion-color-medium);
  }

  // .sticky {
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: 0;
  //   z-index: 100;
  // }

  // ion-item {
  //   --inner-padding-end: 0px;
  //   --padding-start: 0px;
  //   color: var(--ion-color-primary);
  // }

  // ul > li {
  //   font-size: 14px;
  //   margin-bottom: 4px;
  // }

  .incomplete-doc-wrapper {
    border: 1px solid #f2994a;
    border-radius: 1rem;
    background: rgba(242, 153, 74, 0.08);
    padding: 1rem;
    .title {
      color: #f2994a;
      margin: 0;
    }
  }

  .doc-section-title {
    color: black;
  }
}
