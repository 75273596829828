el-buen-inquilino-homeowner-profile-form {
  ion-card {
    margin: 1rem;
    h4 {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
      color: var(--ion-color-primary);
    }
  }

  .separator {
    height: 100%;
    border: 2px solid var(--ion-color-light-shade);
    width: 0;
    border-radius: 5px;
  }

  .form {
    ion-item {
      &::part(native) {
        align-items: end;
      }
    }
  }

  ion-text.error-msg {
    margin-left: 54px;
  }

  ion-label {
    opacity: 1 !important;
    font-size: 12px !important;
  }
}
