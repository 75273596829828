el-buen-inquilino-send-request-tenant {
  .highlight-text {
    background-color: #fefee2;
    padding: 8px;
    margin: 0;
    border-radius: 4px;
  }

  .custom {
    padding: 0;
    > ion-row {
      padding: 0 16px;
    }
  }

  ion-item {
    // --padding-top: 4px;
    // --padding-bottom: 4px;
    --padding-start: 0px;
    // --padding-end: 8px;
  }

  .users {
    background-color: var(--ion-color-light-tint);
    --ion-background-color: var(--ion-color-light-tint);
    border-radius: 0.5em;
  }
}
