el-buen-inquilino-guarantor-profile-page {
  ion-icon {
    color: var(--ion-color-primary);
    opacity: 0.5;
  }

  fieldset {
    padding: 0;
  }

  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
  }

  ::ng-deep .custom-alert {
    .danger {
      color: tomato;
    }
  }

  ion-item {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  .phone-wrapper {
    display: grid;
    grid-template-columns: 42px max-content auto;
    align-items: center;
    .icon-wrapper {
      ion-icon {
        font-size: 22px;
      }
    }
  }
  .ion-intl-wrapper {
    width: 100%;
    ion-intl-tel-input {
      width: 100%;
      color: black;
      .ion-intl-tel-input-code,
      .ion-intl-tel-input-flag {
        font-size: 14px;
      }
    }
  }

  ::ng-deep .ionic-selectable-value-item {
    font-size: 14px;
  }

  .has-error {
    border-bottom: 2px solid var(--ion-color-danger);
  }

  ion-item.-info {
    --border-radius: 10px;
    text-align: left;
    --padding-top: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
  }
}
