el-buen-inquilino-homeowner-management-page {
  .floating-label {
    -webkit-transform: translate3d(20px, 35px, 0) !important;
    transform: translate3d(20px, 35px, 0) !important;
  }

  .item-has-focus,
  .item-has-value {
    .floating-label {
      -webkit-transform: translate3d(20px, -5px, 0) !important;
      transform: translate3d(20px, -5px, 0) !important;
    }
  }

  .item-interactive.ion-invalid.ion-dirty,
  .item-interactive.ion-invalid.ion-touched,
  .item-interactive.ion-invalid.ion-touched.ion-dirty {
    border-bottom: 0;
    ion-input {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }

  ion-card {
    border: 1px solid rgba(5, 28, 44, 0.1);
  }

  .logo-wrapper {
    position: relative;
    .img-wrapper {
      display: flex;
      flex-direction: column;
      img {
        width: 200px;
        height: auto;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
      }
      label {
        margin-top: 5px;
        margin-left: 10px;
      }
    }
    .close-btn {
      position: absolute;
      top: 0;
      background: black;
      width: 25px;
      height: 25px;
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .read-data-wrapper {
    gap: 10px;
    display: flex;
    flex-direction: column;

    .name-wrapper {
      display: flex;
      gap: 10px;
      ion-badge {
        ion-label {
          font-size: 12px !important;
        }
      }
    }
  }

  .material-picker-wrapper {
    width: 100%;
    padding: 4px 20px;
    border: 1px solid rgba(5, 28, 44, 0.1);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    .mat-datepicker-toggle {
      height: 44px;
      width: 44px;

      .mdc-icon-button {
        height: 44px;
        width: 44px;
        padding: 8px;
      }
    }

    .datepicker-input {
      cursor: pointer;
      border: 0;
      width: calc(100% - 40px);
      &:focus-visible {
        outline: none;
      }
    }
  }

  .stacked-label {
    -webkit-transform: translate3d(20px, -5px, 0) !important;
    transform: translate3d(20px, -5px, 0) !important;
    margin-bottom: 0;
    width: fit-content;
  }
}
