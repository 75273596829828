el-buen-inquilino-shared-money-laundering{
    .container-information {
        border-radius: 0.7rem;
        margin: 0.5rem 0;
        padding: 0.5rem 0rem 0.5rem 0.25rem;
    
        .span-title {
            margin-bottom: 1rem;
        }
    }

    .success{
        border-left: 8px solid var(--ion-color-success);
    }

    .error{
        border-left: 8px solid var(--ion-color-danger);
    }
    
    .icon-medium {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        font-weight: bold;
    }
}