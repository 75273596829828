el-buen-inquilino-add-multiple-candidature {
  // deshabilita desplazamiento por cabecera del stepper
  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }
  .mat-step-icon-state-edit,
  .mat-step-icon-selected,
  .mat-step-icon-state-done {
    background-color: var(--ion-color-secondary) !important;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  ion-footer {
    ion-toolbar {
      --padding-start: 1rem;
      --padding-end: 1rem;
      --padding-top: 1rem;
      --padding-bottom: 1rem;
    }
    ion-button {
      margin: 0;
    }
  }

  .summary-tenants {
    margin-top: 0;
  }

  ion-icon {
    pointer-events: none;
  }
}

.add-multiple-candidature {
  overflow-y: auto;
}
