el-buen-inquilino-closed-page {
  ion-content {
    --background: #f2f2f2;
    --padding-bottom: 3rem;
  }

  ion-header ion-button {
    margin: 0 !important;
  }

  .badge-scoring {
    font-size: 16px;
    height: 30px;
    line-height: 24px;
  }

  ion-item.pl-0 {
    --padding-start: 0;
  }

  ion-item.asset-info {
    --ion-item-background: rgba(var(--ion-color-warning-rgb), 0.25);
  }

  .assets-button {
    cursor: pointer;
  }

  .row {
    &-candidature {
      background: rgba(#021f37, 0.1);
      border-bottom: 1px dashed rgba(#021f37, 0.4);
      margin-bottom: 0.5rem;
      border-radius: 4px 4px 0 0;
    }

    &-warning {
      background: rgba(#fa812f, 0.1);
      border-bottom: 1px dashed rgba(#fa812f, 0.4);
      margin-bottom: 0.5rem;
      border-radius: 4px 4px 0 0;
    }
  }

  .name {
    line-height: 18px;
  }

  ion-progress-bar {
    height: 6px;
    border-radius: 2px;
  }

  .bg-transparent {
    --ion-item-background: transparent;
  }

  ion-buttons {
    ion-button {
      font-size: 14px;

      &:last-child {
        margin: 0 0.5rem !important;
      }
    }
  }

  .icon-large {
    width: 32px;
  }

  .ion-boards {
    .assets {
      border-bottom: 4px solid var(--ion-color-secondary);
    }

    .candidatures {
      border-bottom: 4px solid var(--ion-color-success);
    }

    .pending {
      border-bottom: 4px solid var(--ion-color-warning);
    }

    .informes {
      border-bottom: 4px solid var(--ion-color-dark);
    }
  }

  /* Set the width to the full container and center the content */
  ion-select {
    // width: 100%;
    justify-content: center;
  }

  /* Set the flex in order to size the text width to its content */
  ion-select::part(placeholder),
  ion-select::part(text) {
    flex: 0 0 auto;
  }

  /* Set the placeholder color and opacity */
  ion-select::part(placeholder) {
    opacity: 1;
  }

  /* Set the icon color and opacity */
  ion-select::part(icon) {
    opacity: 1;
  }

  .label-witout-checking {
    text-align: end;
  }

  .document-icon {
    margin-left: 8px;
    font-size: medium;
  }
  .document-icon-red {
    color: firebrick;
  }
  .asset-label-btn {
    cursor: default;
    display: flex;
    margin-right: 0.5rem;
    ion-label {
      font-size: 11px;
      margin-top: 1px;
    }
    ion-icon {
      zoom: 0.6;
    }
    &.label-success {
      border-color: var(--ion-color-success-shade);
      --color: var(--ion-color-success-shade);
    }
  }
  .select-filter-wrapper {
    display: flex;
    align-items: center;
  }
  .select-filter {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    height: 36px;
  }
  
  ion-button.select-filter {
    --border-radius: 0.5rem;
  }

  .asset-responsive-wrapper {
    background-color: white;
    border-radius: 0.5rem;
  }

  .asset-responsive-more-info-wrapper {
    ion-badge {
      margin-right: 0.5rem;
    }
  }

  .date-item {
    width: 110px;
  }

  .first-filter-wrapper {
    display: flex;
    gap: 1rem;
  }

  .ion-flex {
    display: flex;
  }

  .asset-list {
    padding: 0.5rem;
  }

  .asset-item {
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-radius: 0.25rem;
    &:hover {
      background-color: rgba(var(--ion-color-primary-rgb), 0.05);
    }
  }

  .asset-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .asset-more-info {
    ion-chip {
      &.primary {
        --background: var(--ion-color-primary) !important;
        --color: white !important;
        font-weight: bolder;
      }
      &.success {
        --background: var(--ion-color-success) !important;
        --color: white !important;
        font-weight: bolder;
      }
    }
  }

  .filter-wrapper-col {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    .filter-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      .tag-button {
        min-width: 135px;
      }

      .date-select {
        border: 1px solid #ccc;
        border-radius: 10px;
        background: #fff;
        height: 36px;
        --padding-start: 10px;
        --padding-end: 10px;
        --padding-bottom: 0;
        --padding-top: 0;
        display: grid;
        grid-template-columns: 80% 20%;
      }

      .ion-searchbar {
        min-width: 300px;
        width: 100%;
        max-width: 325px;
      }

      .month-select {
        min-width: 130px;
      }
      .year-select {
        min-width: 85px;
      }
    }
  }
}
