el-buen-inquilino-create-sinester-modal {
  $text-area-height: 100px;
  ion-textarea {
    height: $text-area-height;
    .native-wrapper {
      height: $text-area-height !important;
    }
  }

  .item-interactive.ion-invalid.ion-touched.ion-dirty,
  .item-interactive.ion-invalid.ion-dirty {
    border-bottom: 0;
    ion-input,
    ion-textarea {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }

  .insurance-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
      ion-icon {
        color: var(--ion-color-dark-tint);
      }
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .cotenants-wrapper {
    border: 1px solid var(--ion-color-medium);
    padding: 1rem;
    border-radius: 1rem;
  }

  .datepicker-wrapper {
    &.error-input {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }

  .input-disabled {
    opacity: 1 !important;
  }

  .datepicker-wrapper {
    width: 100%;
    input {
      width: calc(100% - 40px);
      padding-left: 1rem;
    }
  }

  $mat-dapepicker-toggle-px: 36px;
  .mat-datepicker-toggle {
    height: $mat-dapepicker-toggle-px !important;
    width: $mat-dapepicker-toggle-px !important;

    .mdc-icon-button {
      height: $mat-dapepicker-toggle-px !important;
      width: $mat-dapepicker-toggle-px !important;
      padding: 4px !important;
    }
  }

  .datepicker-label {
    font-size: 13px !important;
    margin-bottom: 3px !important;
  }

  .notify-email-item {
    &::part(native) {
      display: flex;
      align-items: end;
    }
  }

  .notify-email-btn {
    margin: 0;
    font-size: 18px !important;
    margin-bottom: 8px;
  }
}
