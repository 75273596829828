el-buen-inquilino-step-two-new-tenant {
  ion-item {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  .v7-form-item .v7-input-w-icon .input-wrapper {
    height: 40px;
    min-height: 40px;
  }
}
