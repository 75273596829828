el-buen-inquilino-vive-modal {
  .radio-col {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .radio-group {
      display: flex;
      gap: 10px;
    }
  }

  .mat-radio-button.mat-accent {
    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: var(--ion-color-secondary);
    }

    .mat-radio-inner-circle {
      background-color: var(--ion-color-secondary);
    }

    &.mat-radio-checked .mat-radio-persistent-ripple {
      background-color: var(--ion-color-secondary);
    }

    .mat-radio-ripple .mat-ripple-element {
      background-color: var(--ion-color-secondary) !important;
    }
  }

  ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
    --backdrop-opacity: 0.75;
  }

  .margin-top {
    margin-top: 20px;
  }

  .italic-text {
    font-style: italic;
  }
}
