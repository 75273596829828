el-buen-inquilino-information-page {
  ul > li {
    margin-bottom: 0.5rem;
  }

  .ion-card-img {
    max-height: 200px;
    padding: 1rem;
  }

  .features {
    background-color: #f2f2f2;
    padding-top: 3rem;
    padding-bottom: 3rem;
    ion-card {
      text-align: center;
      margin-bottom: 0;
    }
    ion-icon {
      display: block;
      top: 1rem;
      margin: 0 auto 1rem auto;
      height: 32px;
      width: 32px;
    }
    &.steps {
      p {
        margin-top: 0.5rem;
      }
    }
  }

  .-min-height-video-card {
    min-height: 475px;
  }
}
