el-buen-inquilino-professional-data-page {
  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
    --padding-bottom: 3rem;
  }

  .form {
    ion-item {
      &::part(native) {
        align-items: end;
      }
    }
  }

  ion-card {
    h4 {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
      color: var(--ion-color-primary);
    }
  }

  ion-item.-info {
    --border-radius: 10px;
    text-align: left;
    --padding-top: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    margin-left: 36px;
  }

  ion-text.error-msg {
    margin-left: 54px;
  }

  ion-list-header {
    padding: 0;
    ion-label {
      margin: 0;
    }
  }

  .radios-wrapper {
    display: flex;
    ion-radio {
      max-width: 75px;
      &::part(container) {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #ddd;
      }
      &.radio-checked::part(container) {
        background: var(--ion-color-primary);
        border-color: transparent;
      }
      &.radio-checked::part(mark) {
        width: 6px;
        height: 10px;
        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: #fff;
        transform: rotate(45deg);
      }
    }
  }
}
