el-buen-inquilino-fee-pocket-page {
  ion-content {
    --background: #f2f2f2;
  }

  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    ion-img {
      width: 70%;
      max-height: 200px;
    }
  }

  hr {
    margin: 1rem 0;
  }

  .pocket-profits {
    ul {
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 0.5rem;
        line-height: 24px;
      }
      ion-icon {
        margin-right: 0.5rem;
      }
    }
  }

  .container-img {
    h4 {
      font-weight: 300;
      margin: 0;
    }
    ion-card {
      margin-bottom: 0;
    }
    background-image: url('/images/img-promo-1.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    & .img-overlay {
      overflow-y: scroll;
      height: 100%;
      background-color: RGBA(2, 31, 55, 0.9);
      padding: 1rem;
    }
  }

  .table {
    &-header ion-col {
      // background-color: var(--ion-color-light);
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
    &-body {
      border-bottom: 1px solid var(--ion-color-light);
    }
    &-footer {
      font-weight: bold;
      margin-top: 0.5rem;
      background-color: var(--ion-color-primary);
      color: var(--ion-color-light);
      border-radius: 0.3rem;
    }
  }

  .pointer {
    cursor: default;
  }
}
