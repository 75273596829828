el-buen-inquilino-appointment-form {
  .datepicker-wrapper {
    width: 100%;
    height: 40px;

    .mat-datepicker-toggle {
      height: 40px;
      width: 40px;
    }
  }
}
