el-buen-inquilino-preview-report {
  ion-content {
    --background: #f2f2f2;
  }

  ion-card {
    --background: white;
    margin: 1rem 0;
    &.first {
      margin: 0;
      margin-bottom: 1rem;
    }
  }
  .badge-scoring-wrapper {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .badge-scoring {
    background: var(--ion-color-secondary);
    color: var(--ion-color-white);
    border-radius: 1rem;
    padding: 1rem;
    margin-right: 0.5rem;
    text-align: center;
    small {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
    p {
      margin: 0;
      font-weight: bold;
      font-size: 32px;
    }
    @media (max-width: 575px) {
      width: 100%;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin-right: auto;
    }
  }

  .badge-scoring-risk {
    background: red;
    color: var(--ion-color-white);
    border-radius: 1rem;
    padding: 1rem;
    margin-right: 0.5rem;
    text-align: center;
    small {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
    p {
      margin: 0;
      font-weight: bold;
      font-size: 32px;
    }
    @media (max-width: 575px) {
      width: 100%;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin-right: auto;
    }
  }

  .row-title {
    border-top: 1px dotted #ccc;
    padding-top: 0.3rem;
    margin-top: 0.3rem;
    h5 {
      font-size: 16px;
    }
  }

  ion-chip {
    border-radius: 1rem;
    --color: white;
    height: 1rem;
    padding: 0 0.5rem;
    float: right;
    margin: 0;
    min-height: 1rem;
    ion-label {
      font-size: 12px !important;
    }
  }

  .more-info-chip {
    --background: var(--ion-color-success);
  }
  .more-info-chip-warning {
    --background: #ff8803;
  }

  ion-fab {
    margin-right: 1rem;
  }

  .text-international-sanction {
    display: flex;
    align-items: center;
    gap: 5px;
    ion-icon {
      font-size: 18px;
    }
  }
}
