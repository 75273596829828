el-buen-inquilino-register-user-page {
  .register-container {
    width: 100%;
    height: 100%;
    background-image: url('/images/bg-owner-2.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: scroll;
    display: grid;
    place-items: center;
    padding: 1rem;
    overflow-y: auto;
  }

  .container {
    width: 100%;
  }

  el-buen-inquilino-register-user-template,
  el-buen-inquilino-register-user-email-form {
    ion-card {
      width: initial;
    }
  }

  @media (min-width: 576px) {
    .container {
      width: 500px;
    }
  }
}
