el-buen-inquilino-success-new-insurance-modal {
  .success-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    ion-icon {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
  }
}
