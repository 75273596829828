el-buen-inquilino-recommendation-list {
  .container {
    background-color: #ffffff;
    padding: 1rem;
  }

  ion-card-title {
    font-size: 14px;
    font-weight: bold;
  }

  ion-card {
    margin: 1rem;
  }

  ion-item.alert {
    border-radius: 4px;
    // --background: var(--ion-color-warning-shade);

    ion-label {
      white-space: break-spaces;
    }
  }

  .recommendation {
    h3 {
      text-transform: capitalize;
    }
    & + .recommendation {
      margin-top: 1rem;
    }
    .text {
      margin-top: 0.2rem;
    }
  }

  .actions-toolbar {
    --background: transparent;
  }
}
