el-buen-inquilino-enterprise-login-page {
  ion-item {
    --ion-background-color: transparent;
  }

  ion-content {
    & > ion-grid {
      height: 100%;
      padding: 0;
      ion-row {
        height: 100%;
      }
    }
  }

  .enterprise-demo {
    background-image: url('/images/enterprise-login.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
  }
  .enterprise-dark {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0 50px;
  }
  .no-account {
    font-size: 0.8em;
  }
}
