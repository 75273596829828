el-buen-inquilino-asset-list {
  ion-card.building-card {
    height: 100%;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1400px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;
  }

  .item-list {
    padding: 1rem;
    padding-bottom: 0.5rem;
  }

  .building-list {
    height: 100%;
    overflow-y: auto;
  }

  .item {
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-radius: 0.25rem;
    &:hover {
      background-color: rgba(var(--ion-color-primary-rgb), 0.05);
    }
  }

  .item-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
      ion-icon.building-icon {
        color: var(--ion-color-dark-tint);
      }
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .building-address-wrapper {
    .building-title {
      font-weight: 500;
    }
    .building-subtitle {
      font-size: 12px;
      text-transform: capitalize;
    }
  }

  .ion-flex {
    display: flex;
  }

  .item-label-btn {
    width: fit-content;
    cursor: default;
    display: flex;
    margin-right: 0.5rem;
    ion-label {
      font-size: 11px !important;
      margin-top: 1px !important;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.label-success {
      border-color: var(--ion-color-success-shade);
      --color: var(--ion-color-success-shade);
    }
  }

  .insurance-chip {
    --background: var(--ion-color-primary) !important;
    --color: white !important;
    font-weight: bolder;
    ion-label {
      font-size: 11px !important;
    }
  }

  .insurance-rc-chip {
    font-weight: bolder;
    ion-label {
      font-size: 11px !important;
    }
  }

  .statistics-chip {
    --background: #f2f2f2;
    --color: #d3d3d3;
    ion-icon {
      color: #c1c1c1;
    }
  }

  .green-number {
    color: var(--ion-color-success);
  }
  .red-number {
    color: var(--ion-color-danger);
  }
  .black-number {
    color: #1e1e1e;
  }

  .item-price-chip {
    min-width: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-label-chip {
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    width: 100%;
  }
  .item-api-chip {
    min-width: 75px;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notVisitedAsset {
    background-color: rgba(var(--ion-color-warning-rgb), 0.1);
  }

  .more-info-cell-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  td.mat-cell {
    padding: 8px 0;
  }

  .address-cell-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    p {
      margin: 0;
    }

    ion-badge {
      width: fit-content;
      font-size: 10px;
      font-weight: normal;
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
    }
  }

  .select-th,
  .select-td {
    width: 80px;
  }

  .label-th,
  .label-td {
    width: 175px;
  }

  .api-th,
  .api-td {
    width: 175px;
  }

  .rental-price-th,
  .rental-price-td {
    width: 150px;
  }

  .creation-date-th,
  .creation-date-td {
    width: 125px;
  }

  .label-td,
  .api-td,
  .rental-price-td,
  .creation-date-td {
    ion-chip {
      margin: 0;
    }
  }

  .action-th,
  .action-td {
    width: 50px;
    ion-button {
      margin: 0;
      --padding-start: 0;
      --padding-end: 0;
    }
  }

  .address-badge-wrapper {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  .no-building-text-wrapper {
    border: 1px solid #f2994a;
    padding: 1rem;
    border-radius: 1rem;
    background: rgba(242, 153, 74, 0.08);
    .no-building-text-title {
      color: #f2994a;
    }
  }
}
