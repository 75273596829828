el-buen-inquilino-customer-care-request-analysis-list-page {
  ion-content {
    --padding-bottom: 3rem;
  }
  ion-row {
    margin: 0;
    ion-col {
      padding: 0.5rem 0.8rem;
    }
    &.table-header {
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
      border-bottom: 1px solid var(--ion-color-light);
      font-weight: bolder;
    }
    &.table-body {
      cursor: pointer;
      color: var(--ion-color-primary);
      font-size: 14px;
      p {
        margin: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
      &:first-child {
        font-weight: 500;
        background-color: var(--ion-color-dark);
      }
      &:hover {
        background-color: var(--ion-color-secondary) !important;
        color: white;
        ion-chip {
          color: white !important;
        }
      }
    }
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1400px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;

    p {
      margin: 0;
      color: var(--ion-color-primary);
      font-size: 14px;
      font-weight: 400;
    }
  }

  tbody {
    td.mat-cell {
      padding-top: 1rem;
      padding-bottom: 1rem;

      color: var(--ion-color-primary);
      font-size: 14px;
      font-weight: 400;
    }
  }

  .social-media-icon:last-child {
    margin-inline: 0px 0px;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .truncate {
    width: fit-content;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
  }

  .red-person {
    color: var(--ion-color-danger);
  }

  .filters-col {
    display: flex;
  }

  .filters-label {
    white-space: break-spaces;
    text-align: center;
  }

  .item-toggle {
    --padding-start: 0;
    ion-label {
      margin-left: 10px;
    }
  }

  .options-col {
    ion-icon {
      font-size: 25px;
    }
  }

  .entry-date-col-content {
    display: flex;
    align-items: center;
    ion-icon {
      color: var(--ion-color-medium);
      margin-right: 1rem;
      font-size: 25px;
    }
  }

  .archive-active-btn {
    font-weight: bolder;
    border: 1px solid black;
    padding: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin: 0;
  }

  // Increase the overall size of the paginator
  ion-pagination {
    font-size: 1.5rem;
  }

  ion-button.paginator-button {
    font-weight: bold;
    font-size: 16px;
    margin: 0 5px;
    min-width: 40px;
    border-radius: 50px;
    color: #fff;
    background-color: #007bff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  ion-button.paginator-button:hover {
    background-color: #0069d9;
  }

  ion-button.paginator-button[disabled] {
    background-color: #6c757d;
    box-shadow: none;
    cursor: default;
  }

  .th-date,
  .td-date {
    width: 300px;
  }

  .th-candidature,
  .td-candidature {
    width: 500px;
  }

  .th-archived,
  .td-archived {
    width: 100px;
    text-align: center;
  }
  .th-archived span {
    padding: 1rem;
  }

  .filters-row {
    ion-item {
      --min-height: 44px;
      height: 44px;
    }
  }

  .filters-col {
    height: fit-content;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .datepicker-range-wrapper {
    .mat-datepicker-toggle {
      height: 44px;
      width: 44px;

      .mdc-icon-button {
        height: 44px;
        width: 44px;
        padding: 8px;
      }
    }
  }
}
