// Solo para las cookies

.osano-cm-window__dialog {
  background: rgba(0, 0, 0, 0.9);
}
.osano-cm-window__dialog .osano-cm-content,
.osano-cm-window__dialog .osano-cm-buttons {
  width: 100%;
}
.osano-cm-save {
  background: black;
  opacity: 0.6;
  border-radius: 16px !important;
  max-width: 120px;
}
.osano-cm-accept-all {
  border-radius: 16px !important;
  max-width: 180px;
}
.osano-cm-dialog__list {
  margin: 1rem 0;
}
.osano-cm-dialog__list li {
  display: none;
}
.osano-cm-dialog__list li:last-child {
  display: block;
}
.osano-cm-window__widget {
  display: none !important;
}
