el-buen-inquilino-add-asset {
  ion-item {
    --padding-start: 4px;
    --padding-end: 4px;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
  }

  .bg-white {
    background: #fff;
  }

  el-buen-inquilino-asset-map {
    &.edit {
      height: 300px;
      width: 100%;
      display: block;
    }
    &.map {
      height: 600px;
    }
  }

  .wrapper {
    overflow-y: auto;
    padding: 1rem;
  }

  .-scroll-y-auto {
    overflow-y: auto;
  }

  .pill-round {
    display: flex;
    color: white;
    padding: 10px;
    border-radius: 10px;
    vertical-align: -4px;
    background-color: white;
    margin-right: 8px;
  }

  .building-form {
    .floating-label {
      -webkit-transform: translate3d(20px, 35px, 0);
      transform: translate3d(20px, 35px, 0);
    }

    .item-has-focus,
    .item-has-value {
      .floating-label {
        -webkit-transform: translate3d(20px, -5px, 0);
        transform: translate3d(20px, -5px, 0);
      }
    }

    .item-interactive.ion-invalid.ion-touched.ion-dirty {
      border-bottom: 0;
      ion-input,
      ion-select {
        border-color: var(--highlight-color-invalid);
        border-width: 2px;
      }
    }

    .member-wrapper {
      --border-width: 1px;
      --border-style: solid;
      --border-color: #d2d2d2;
      --border-radius: 16px;
      --padding-end: 8px;
      --inner-padding-end: 8px;
      --padding-start: 8px;
      --inner-padding-start: 8px;

      .info {
        color: var(--ion-color-warning);
      }
    }
    // TODO: modificar estilos de input disabled al crear asset de building
  }
}
