el-buen-inquilino-login-page {
  .login-box {
    background-image: url('/images/bg-owner-2.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: scroll;
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > ion-row {
      width: 100%;
    }
  }

  .login-container {
    width: 100%;
    height: 100%;
    background-image: url('/images/bg-owner-2.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: scroll;
    display: grid;
    place-items: center;
    padding: 1rem;
  }

  @media (min-width: 576px) {
    .login-box {
      display: block;
      padding-top: 30px;
    }
  }
}
