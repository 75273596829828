el-buen-inquilino-phone-flag-modal {
  .selected {
    --background: #ddd;
  }

  .btn {
    --padding-start: 10px;
    --padding-end: 10px;
  }
}
