el-buen-inquilino-guarantor-profile-page {
  .password-img {
    max-height: 100px;
  }

  .form {
    ion-item {
      &::part(native) {
        align-items: end;
      }
    }
  }
}
