el-buen-inquilino-google-maps {
  google-map {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
  }

  .item-selected-wrapper {
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    .item-selected-body {
      background: #ffffff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      opacity: 0.9;
      border-radius: 1rem;
      padding: 0.5rem 1rem;
      // max-width: 500px;
      ion-item {
        --padding-start: 0;
        --inner-padding-end: 0;
      }
    }
  }

  .city-chip {
    --background: #eee;
    --color: #ffaa1b;
  }

  .icon-xl {
    margin-right: 0.5rem;
    min-height: 2rem;
    ion-icon {
      color: var(--ion-color-dark-tint);
    }
  }

  @media (max-width: 767px) {
    .item-selected-body {
      max-width: 400px;
      .item-selected-street-label {
        white-space: break-spaces;
      }
    }
  }
  @media (max-width: 575px) {
    .item-selected-wrapper {
      top: 0;
      bottom: initial;
      .item-selected-body {
        max-width: 100%;
      }
    }
  }
}
