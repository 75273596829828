el-buen-inquilino-add-multiple-candidature-modal {
  .add-candidature-content {
    overflow-y: scroll;
  }

  ion-footer {
    ion-toolbar {
      --padding-start: 1rem;
      --padding-end: 1rem;
      --padding-top: 1rem;
      --padding-bottom: 1rem;
    }
    ion-button {
      margin: 0;
    }
  }
  .summary-tenants {
    margin-top: 0;
  }

  ion-icon {
    pointer-events: none;
  }
}
