el-buen-inquilino-register-user-email-form {
  ion-card {
    width: 500px;
    margin: 0;
  }

  .v7-form-item {
    &::part(native) {
      align-items: end;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  ion-text.error-msg {
    margin-left: 1rem;
  }

  .terms-item {
    border-bottom: 0 !important;
  }
}
