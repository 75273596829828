el-buen-inquilino-documentation-tenants-modal {
  .badge-scoring-modal {
    font-size: 12px;
    height: 30px;
    line-height: 24px;
  }

  ion-item.ion-no-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  ion-item.ion-left-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 16px;
    --inner-padding-start: 0px;
  }

  ion-card.-grey-bg {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
  }

  ion-card.-banner-bg {
    background-image: url('/assets/images/Group-2.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
  }

  .bg-transparent {
    --ion-item-background: transparent;
  }
  .-center-title-label {
    align-self: center;
  }
}
