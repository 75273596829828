el-buen-inquilino-insurances-management-page {
  ion-segment {
    --background: transparent;
    ion-segment-button {
      --padding-start: 10px;
      --padding-end: 10px;
      --margin-end: 0;
    }
  }
}
