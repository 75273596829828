el-buen-inquilino-vertical-menu {
  ion-item {
    font-size: 14px;
    .activateHighLigth {
      --ion-background-color: var(--ion-color-light);
    }
  }

  .-help-align {
    vertical-align: middle;
  }
}
