el-buen-inquilino-candidatures-page {
  ion-content {
    --background: #f2f2f2;
    --padding-bottom: 150px;
  }

  .candidature-card {
    border-radius: 1rem;
    margin: 0 0 1rem 0;
    box-shadow: none !important;
  }

  .mat-mdc-card-header {
    flex-direction: column;
  }

  .mat-expansion-panel-header {
    height: auto !important;
    padding: 5px 0;
  }

  mat-expansion-panel {
    border-radius: 0 !important;
    border-top: 1px solid #e6e6e6;
    margin-top: 5px;
    box-shadow: none !important;
    mat-expansion-panel-header {
      &:hover {
        background: white !important;
      }
      .tenant-info-item {
        width: 100%;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 10px 0 !important;
  }

  .status-badge {
    --padding-bottom: 5px;
    --padding-top: 5px;
    --padding-start: 10px;
    --padding-end: 10px;
    min-width: 115px;
  }

  .status-unfilled {
    --background: #f2994a;
  }
  .status-incidence {
    --background: #eb5757;
  }
  .status-revision {
    --background: #2d9cdb;
  }
  .status-selected {
    --background: #219653;
  }
  .status-pre-selected {
    --background: var(--ion-color-primary);
  }
  .status-rejected {
    --background: #828282;
  }
  .status-rejected-tenant {
    --background: var(--ion-color-danger);
  }
  .status-expired {
    --background: #828282;
  }

  .desc-status {
    border: 2px solid;
    padding: 1rem;
    width: 100%;
    border-radius: 0.5rem;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      opacity: 0.25;
    }
  }
  .desc-status-unfilled {
    border-color: #f2994a;
    &::before {
      background-color: #f2994a;
    }
  }
  .desc-status-incidence {
    border-color: #eb5757;
    &::before {
      background-color: #eb5757;
    }
  }
  .desc-status-revision {
    border-color: #2d9cdb;
    &::before {
      background-color: #2d9cdb;
    }
  }
  .desc-status-revision-ebi {
    border-color: #2d9cdb;
    &::before {
      background-color: #2d9cdb;
    }
  }
  .desc-status-selected {
    border-color: #219653;
    &::before {
      background-color: #219653;
    }
  }
  .desc-status-pre-selected {
    border-color: var(--ion-color-primary);
    &::before {
      background-color: var(--ion-color-primary);
    }
  }
  .desc-status-rejected {
    border-color: #828282;
    &::before {
      background-color: #828282;
    }
  }
  .desc-status-rejected-tenant {
    border-color: var(--ion-color-danger);
    &::before {
      background-color: var(--ion-color-danger);
    }
  }
  .desc-status-expired {
    border-color: #828282;
    &::before {
      background-color: #828282;
    }
  }
  .color-status-unfilled {
    color: #f2994a;
  }
  .color-status-incidence {
    color: #eb5757;
  }
  .color-status-revision {
    color: #2d9cdb;
  }
  .color-status-selected {
    color: #219653;
  }
  .color-status-pre-selected {
    color: var(--ion-color-primary);
  }
  .color-status-rejected {
    color: #828282;
  }
  .color-status-rejected-tenant {
    color: var(--ion-color-danger);
  }
  .color-status-expired {
    color: #828282;
  }

  .tenant-info-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .chip-completed {
    --color: #219653;
    border-color: #219653;
  }
  .chip-unfilled {
    --color: #f2994a;
    border-color: #f2994a;
  }
  .chip-incidence {
    --color: #eb5757;
    border-color: #eb5757;
  }
  .chip-icon {
    color: inherit;
  }

  ::ng-deep .tenant-accordion-card {
    margin: 0 !important;
    box-shadow: none !important;
    border: 0;
  }

  .tenant-more-info {
    margin-bottom: 5px;
    border-radius: 0.5rem;
    padding: 10px;
    border: 2px solid;
    p {
      color: black;
    }
  }

  .tenant-more-info-success {
    border-color: #219653;
    color: #219653;
    background-color: rgba(33, 150, 84, 0.25);
  }
  .tenant-more-info-warning {
    border-color: #f2994a;
    color: #f2994a;
    background-color: rgba(242, 152, 74, 0.25);

    .button-documents {
      --border-color: #f2994a;
      --color: #f2994a;
    }
  }
  .tenant-more-info-fail {
    border-color: #eb5757;
    color: #eb5757;
    background-color: rgba(235, 87, 87, 0.25);
    .button-documents {
      --border-color: #eb5757;
      --color: #eb5757;
    }
  }

  .button-documents {
    margin: 0;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    width: 100%;
  }

  .resolve-incidences-btn {
    margin-top: 1rem;
  }

  .text-break-spaces {
    white-space: break-spaces;
  }

  .mat-card-actions {
    margin: 0;
  }
  .invitation-toolbar {
    --background: transparent;
    border-top: 1px solid #e6e6e6;
    --padding-top: 8px;

    ion-buttons {
      gap: 1rem;
    }

    ion-button {
      --padding-start: 1rem;
      --padding-end: 1rem;
      margin: 0;
    }
  }

  .candidature-status-modal-btn {
    font-size: 16px !important;
  }

  @media (min-width: 576px) {
    .button-documents {
      width: inherit;
    }
    .resolve-incidences-btn {
      margin-left: 1rem;
      margin-top: inherit;
    }
  }
  @media (min-width: 768px) {
    $circle: 30px;
    $line-height: calc($circle / 5);
    $img-height: 40px;
  }
  @media (min-width: 1500px) {
    $circle: 40px;
    $line-height: calc($circle / 5);
    $img-height: 50px;
  }

  @media (max-width: 575px) {
    .end-row {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
}
