el-buen-inquilino-edit-candidature {
  ion-item {
    --padding-start: 4px;
    --padding-end: 4px;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
  }

  .bg-white {
    background: #fff;
  }

  el-buen-inquilino-asset-map {
    height: 500px;
  }

  .-scroll-y-auto {
    overflow-y: auto;
  }

  ion-modal.auto-height {
    &.bottom {
      align-items: flex-end;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 10px;
      }
    }
  }
}
