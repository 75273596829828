el-buen-inquilino-multiple-price-assign {
  .item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  ion-item.ion-invalid.ion-dirty {
    border-bottom: 0 !important;
  }

  .control-item {
    --border-width: 1px;
    --border-radius: 1rem;
    --border-color: var(--ion-color-light-shade);
    &.ion-invalid.ion-dirty.ion-touched {
      --border-width: 2px;
      --border-color: var(--ion-color-danger);
    }
  }
}
