el-buen-inquilino-aml-table {
  .aml-table-wrapper {
    padding: 5px 16px;
    table {
      width: 100%;
      td {
        border: 1px solid #ccc;
        padding: 5px 16px;
        &:first-child {
          width: 50%;
          color: black;
        }
        width: 25%;

        .aml-no-wrapper,
        .aml-yes-wrapper {
          width: 20px;
          height: 20px;
          color: white;
          display: grid;
          place-content: center;
          border-radius: 50%;
          margin: 0 auto;
        }

        .aml-no-wrapper {
          background-color: #0e950e;
        }

        .aml-yes-wrapper {
          background-color: var(--ion-color-danger);
        }
      }
    }
  }

  ion-radio {
    --border-radius: 4px;
    --inner-border-radius: 4px;

    --color: #ccc;
    --color-checked: white;

    &.radio-disabled {
      opacity: 1 !important;
    }
  }

  ion-radio::part(container) {
    width: 25px;
    height: 25px;

    border: 2px solid #ccc;
    border-radius: 50%;
  }

  .aml-yes-radio.radio-checked::part(container) {
    background-color: var(--ion-color-danger);
    border-color: transparent;
  }

  .aml-yes-radio.radio-checked::part(mark) {
    display: none;
  }

  .aml-no-radio.radio-checked::part(container) {
    background-color: #0e950e;
    border-color: transparent;
  }

  .radio-wrapper {
    height: 100%;
    display: grid;
    place-items: center;
    &:first-child {
      border-right: 1px solid #ccc;
    }
  }

  .radio-td {
    padding: 0 !important;
  }

  .radios-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    height: 100%;
  }

  table td {
    height: 35px;
  }

  .new-note-wrapper {
    height: 250px;
    padding: 0 1rem 1rem;
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  ion-textarea.message {
    border: 1px solid var(--ion-color-light-shade);
    --padding-start: 10px;
    border-radius: 1rem;
    height: 150px;
    margin-top: 0.5rem;
  }

  ion-button {
    margin: 0;
    --padding-start: 10px;
    --padding-end: 10px;
  }

  .textarea-wrapper {
    height: 100%;
  }
}
