el-buen-inquilino-policy-conversation {
  .sinister-comments-wrapper {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    border: 1px solid;
    border-radius: 1rem;
    position: relative;

    &.left-comment {
      border-bottom-left-radius: 0;
    }

    &.right-comment {
      border-bottom-right-radius: 0;
    }
  }

  .sinister-client-comment {
    border-color: #f0f0f0;
    background: #f0f0f0;
  }

  .sinister-ebi-comment {
    border-color: #d9fdd3;
    background: #d9fdd3;
  }

  .message-date,
  .message-name {
    color: var(--ion-color-medium);
    font-size: 12px;
  }

  ion-textarea {
    border: 1px solid rgba(5, 28, 44, 0.1);
    border-radius: 16px;
    --padding-start: 20px !important;
    --padding-end: 20px !important;
    --padding-top: 16px !important;
    --padding-bottom: 16px !important;
  }

  .item-interactive.ion-invalid.ion-touched.ion-dirty,
  .item-interactive.ion-invalid.ion-dirty {
    border-bottom: 0;
    ion-textarea {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }
}
