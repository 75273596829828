el-buen-inquilino-renew-insurance-modal {
  // deshabilita desplazamiento por cabecera del stepper
  ::ng-deep .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }
  ::ng-deep .mat-step-icon-state-edit,
  ::ng-deep .mat-step-icon-selected,
  ::ng-deep .mat-step-icon-state-done {
    background-color: var(--ion-color-secondary) !important;
  }

  .header {
    padding: 0;
    span {
      margin: 0;
      font-weight: bold;
    }
  }

  .search-client-btn {
    height: 36px;
  }

  .item-class {
    cursor: pointer;
    &:hover {
      --background: var(--ion-color-primary);
      --color: white;
    }
    &.selected {
      --background: var(--ion-color-primary);
      --color: white;
    }
  }

  .tenants {
    font-size: 14px;
  }

  .address {
    width: 25%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .portal,
  .stair,
  .floor,
  .door {
    width: 12.5%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .town {
    width: 25%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .candidatures-item {
    --padding-top: 0.5rem;
    --padding-bottom: 0.5rem;
    border-top: 1px solid var(--ion-color-primary);
    &:last-of-type {
      border-bottom: 1px solid var(--ion-color-primary);
    }
    //   &:hover {
    &:hover,
    &.selected {
      --background: var(--ion-color-primary);
      cursor: pointer;
      ion-chip {
        background: var(--ion-color-light);
      }
    }
    // &.selected {
    //   --background: var(--ion-color-light);
    // //   --color: white;
    // }
    //   }
    .tenants-wrapper {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }

    .candidature-status {
      width: 115px;
      justify-content: center;
    }
  }

  ion-footer {
    ion-toolbar {
      padding: 8px;
    }
  }
}
