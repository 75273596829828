el-buen-inquilino-custom-login-page {
  ion-item {
    --ion-background-color: transparent;
  }

  ion-content {
    --background: transparent;
    & > ion-grid {
      height: 100%;
      padding: 0;
      ion-row {
        height: 100%;
      }
    }
  }

  .ebi-demo {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
  }

  .no-account {
    font-size: 0.8em;
  }

  ::ng-deep ion-content {
    --offset-bottom: 0% !important;
  }
}
