el-buen-inquilino-update-debt-sinister-modal {
  .remember-wrapper {
    border: 1px solid #f2994a;
    background: rgba(242, 153, 74, 0.08);
    padding: 16px;
    border-radius: 1rem;
    .remember-title {
      color: #f2994a;
    }
    a {
      color: black;
      font-weight: bold;
    }
  }

  ion-input {
    border: 1px solid rgba(5, 28, 44, 0.1);
    border-radius: 16px;
    --padding-start: 20px !important;
    --padding-end: 20px !important;
    --padding-top: 16px !important;
    --padding-bottom: 16px !important;
  }

  .item-interactive.ion-invalid.ion-touched.ion-dirty {
    border-bottom: 0;
    ion-input,
    ion-select,
    .fileInput {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }
}
