el-buen-inquilino-candidature-information {
    width: 100%;

    ion-grid {
        ion-row {           
            ion-col {
                padding: 0.8rem 0.3rem;

            }
        }
    }

    .container-detail {
        background-color: rgba(var(--ion-color-success-rgb), 0.1);
        border-left: 8px solid var(--ion-color-success);
        border-radius: 0.7rem;
        padding: 1rem 1.5rem 1rem 1rem;
        margin: 1rem 0;

        .title {
            font-size: 1rem;
        }

        .big-title {
            font-size: 1.7rem;
        }
    }

    .container-information {
        border-radius: 0.7rem;
        margin: 0.5rem 0;
        padding: 0.5rem 0rem 0.5rem 0.25rem;
        
        &.full-padding {
            padding: 0.5rem 1rem 1rem 1rem;
        }

        &.border-left {
            border-left: 8px solid var(--ion-color-danger);
        }
        
        .span-title {
            margin-bottom: 1rem;
        }

        .button-expand {
            width: 100%;
        }
    }

    .icon-medium {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        font-weight: bold;
    }

    .border-top {
        border-top: 0.80px solid var(--ion-color-medium-tint) !important;
        padding-top: 1rem;
        margin-top: 1rem;
    }

    .border-bottom {
        border-bottom: 0.80px solid var(--ion-color-medium-tint) !important;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .tag-info {
        font-weight: 500;
    }

    .chip-scoring {
        width: 60px;
        margin: 0;
    }
}