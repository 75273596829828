el-buen-inquilino-selected-candidature-modal {
  .badge-scoring-modal {
    font-size: 12px;
    height: 30px;
    line-height: 24px;
  }

  ion-item.ion-no-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
    --padding-top: 10px;
    --padding-bottom: 10px;
  }

  ion-item.ion-left-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 16px;
    --inner-padding-start: 0px;
  }

  ion-card.-grey-bg {
    // --background: #f2f2f2;
    border: 1px solid #ddd;
    --offset-bottom: 0px !important;
    border-radius: 1rem;
  }

  ion-card.-banner-bg {
    background-image: url('/assets/images/Group-2.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
  }

  .bg-transparent {
    --ion-item-background: transparent;
  }
  .-center-title-label {
    align-self: center;
  }

  .icon-success {
    margin: 1rem 1rem 0 0;
  }
  .btn-documents {
    --padding-end: 15px;
    --padding-start: 15px;
  }

  .wrapper {
    overflow-y: auto;
    height: 100%;
  }

  @media (max-width: 767px) {
    .wrapper {
      overflow-y: scroll;
    }
  }

  .selected-candidature-wrapper {
    border: 1px solid #219653;
    padding: 1rem;
    width: 100%;
    border-radius: 1rem;
    position: relative;
    background: rgba(33, 150, 83, 0.08);

    h5 {
      color: #219653;
    }

    p {
      font-size: 14px;
    }

    .score-wrapper {
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
      span {
        color: #219653;
        font-weight: 500;
        font-size: 30px;
        line-height: 120%;
      }
    }
  }

  .user-info-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    ion-avatar {
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
    }

    ion-label {
      min-width: 200px;
      max-width: 200px;
      width: 100%;
    }
  }

  .role-badge {
    background-color: #f2f2f2;
    color: black;
    font-weight: normal;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-info-btns-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .congratulations {
    color: #ffc409;
    font-size: 20px;
    margin: 0;
  }

  .card-text {
    color: white;
  }

  .insurances-wrapper {
    background-color: hsla(0, 0%, 100%, 0.9);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ion-item {
      --background: transparent;
      --background-activated: transparent;
      --background-focused: transparent;
      --background-hover: transparent;
      --padding-start: 0;
      --inner-padding-end: 0;
    }
    .icon-xl {
      margin: 0;
      margin-right: 0.5rem;
      --border-radius: 1rem;
      --padding-start: 0;
      --padding-end: 0;
      height: 36px;
      width: 36px;
      --border-color: #ccc;
      ion-icon {
        color: #ccc;
      }
    }

    .insurance-item {
      display: flex;
      align-items: center;
      gap: 10px;

      div {
        display: inherit;
        flex-direction: column;
      }
    }
  }
}
