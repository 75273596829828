el-buen-inquilino-upload-tenant-document-card {
  ion-card {
    &[color='transparent'] {
      border: 2px dashed var(--ion-color-primary);
    }
    min-height: 240px;
    height: 100%;
    &.upload-card {
      display: flex;
      flex-direction: column;
    }
  }

  .ebi-card-footer {
    &.upload-footer {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      &.work-life-footer {
        justify-content: space-between;
      }
    }
    ion-button {
      margin: 0;
      --padding-start: 26px;
      --padding-end: 26px;
    }
  }

  .social-security-btn {
    text-decoration: underline;
  }
}
