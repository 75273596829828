el-buen-inquilino-register-by-invitation-page {
  .register-container {
    width: 100%;
    height: 100%;
    background-image: url('/images/bg-owner-2.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: scroll;
    display: grid;
    place-items: center;
    padding: 2rem;
    overflow-y: auto;
  }

  .register-welcome-container {
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 50px -18px rgba(0, 0, 0, 0.8);
    background: rgba(255, 255, 255, 0.9);
  }

  .container {
    width: 500px;
  }

  el-buen-inquilino-register-user-email-form {
    ion-card {
      width: initial;
    }
  }
}
