el-buen-inquilino-drag-and-drop-file {

  .file-upload-container { 
    border: 2px dashed #cccccc;
    border-radius: 1rem;
    max-width: 600px;
  }

  .file-upload-header {
    display: flex; 
    align-items: center;
    margin-bottom: 5px;

    ion-icon {
      font-size: 24px;
      margin-right: 10px;
    }

    h3 {
      margin: 0;
      font-size: 18px;
    }
  }

  .drop-row-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ion-button,
    p {
      margin: 0;
    }

    p {
      color: #666;
    }
  }

  .drop-zone {
    border-radius: 1rem;
    padding: 20px;
    transition: background-color 0.3s ease;
  }

  .drop-zone.drag-over {
    background-color: #e0f7fa;
  }

  .uploaded-file {
    margin-top: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --border-width: 1px;
    --border-style: solid;
    --border-color: #cccccc;
    --border-radius: 5px;
    --background: white;
    --background-activated: white;
    --background-focused: white;
    --background-hover: white;
    --color: black;
  }
}
