el-buen-inquilino-maintenance-page {
  .big-circle {
    /* position: absolute; */
    /* z-index: 888; */
    background-color: #ebf3fb;
    color: #004778 !important;
    border-radius: 100%;
    width: 600px;
    height: 600px;
    padding: 8rem 6rem;
  }
  .big-circle h1 {
    font-size: 40px;
  }
  .ilustration-right {
    transform: rotate(180deg);
    width: 100%;
    right: 0;
    bottom: 22%;
    position: absolute;
    z-index: 9999;
  }
  .ilustration-left {
    width: 100%;
    left: 0;
    bottom: 22%;
    position: absolute;
    z-index: 9999;
  }
  .enchufe {
    width: 60%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 3rem 0;
  }
  .color-white {
    background-color: rgb(249, 253, 253);
    border: 4px solid #004778;
    border-left: none;
  }
  .color-blue {
    background-color: #97cdec;
    border: 6px solid #004778;
  }
  .box1 {
    border-radius: 1rem 0 0 1rem;
    border-right: 0;
    width: 18%;
    height: 40%;
  }
  .box2 {
    border-radius: 1rem 0 0 1rem;
    width: 40%;
    height: 80%;
  }
  .box3 {
    width: 12%;
    height: 100%;
  }
  .macho {
    width: 18%;
  }
  .pletina {
    height: 24px;
    border-left: none;
    border-width: 4px;
    border-radius: 0 1rem 1rem 0;
  }
  .pletina + .pletina {
    margin-top: 20px;
  }
  .cable {
    position: relative;
    width: 50%;
    max-width: calc(80% - 200px);
    height: 24px;
    border-right: 0;
  }
  .conector {
    position: relative;
    display: flex;
    min-width: 200px;
    max-width: 200px;
    width: 40%;
    height: 120px;
    align-items: center;
    justify-content: left;
  }
}
