el-buen-inquilino-insurance-type-modal {
  ion-textarea {
    border: 1px solid var(--ion-color-light-shade);
    --padding-start: 10px !important;
    border-radius: 1rem;
    height: 150px;
    .textarea-wrapper-inner {
      height: 100%;
    }
  }
}
