el-buen-inquilino-candidature-card-item {
  .doc-chip {
    &.chip-disabled {
      opacity: 1;
    }
  }

  .tenant-role-col {
    display: flex;
    align-items: center;
  }
}
