el-buen-inquilino-trial-tab-document {
  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
  }

  ion-item.-student {
    --border-radius: 8px;
  }

  .-tab-height {
    height: 60vh;
  }

  ion-card-content {
    padding: 20px !important;
  }

  .ebi-card-footer {
    display: block;
    margin-top: 20px;
    ion-button {
      margin: 0;
      margin-right: 0.5rem;
      --padding-start: 1rem;
      --padding-end: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .truncate {
    margin: 0;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ion-card {
    min-height: 15rem;
    &[color='transparent'] {
      border: 2px dashed var(--ion-color-primary);
    }
  }
}
