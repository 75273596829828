el-buen-inquilino-asset-color-label-popover {
  ion-item {
    --padding: 0;
  }
  .pill-round {
    display: flex;
    position: fixed;
    color: white;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
  }
  .label-name {
    margin: 0px 0 0 1rem;
  }
}
