el-buen-inquilino-owner-calendar {
  ion-header {
    ion-toolbar {
      --background: white;
    }
  }

  ion-content {
    --background: #f2f2f2;
  }

  .new-appointment-button {
    --padding-start: 1rem;
    --padding-end: 1rem;
  }

  .cal-current-time-marker {
    background-color: var(--ion-color-secondary) !important;
  }

  .cal-week-view .cal-header.cal-today {
    background-color: #2ba4d840 !important;
  }

  .buttons-wrapper {
    background-color: var(--ion-color-primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 10px 16px;
    width: fit-content;
    border-radius: 8px;
  }

  .btn {
    padding: 5px 10px;
    cursor: pointer;
    &.active {
      background-color: white;
      color: var(--ion-color-primary);
      border-radius: 8px;
    }
  }

  .cancelled-appointment {
    .cal-event {
      background-color: #ffbec6;
      border-color: var(--ion-color-danger);
      color: var(--ion-color-danger);
    }
  }

  .other-user-appointment {
    .cal-event {
      // background-color: #ffbec6;
      border-color: #6dda5b;
      color: #6dda5b;
    }
  }
}
