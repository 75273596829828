el-buen-inquilino-backoffice-template-status-modal{
    .form-reason {
        border-radius: 0.5rem;
        margin-top: 1rem;
        display: flex;
        justify-content: end;
    }

    ion-textarea {
        textarea{
            background: #fff;
            border: 1px solid var(--ion-color-medium-tint) !important;
            border-radius: 0.5rem;
            padding: 0.5rem !important;
        }
    }

    .status-wrapper {
        width: 180px;
        border-radius: 0.5rem;
        padding: 2px;
        
        .incidence-types-select {
            width: 100%;
            padding: 0 0.5rem;
            border: none;
        }

        &.status-in_progress {
            border: 1px solid var(--ion-color-warning);

            ion-item {
                color: var(--ion-color-warning);

                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-warning);
                    }
                }

                :hover {
                    color: var(--ion-color-warning);
                }
            }
        }

        &.status-ready {
            border: 1px solid var(--ion-color-success);

            ion-item {
                color: var(--ion-color-success);
        
                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-success);
                    }
                }
        
                :hover {
                    color: var(--ion-color-success);
                }
            }
        }

        &.status-rejected {
            border: 1px solid var(--ion-color-danger);

            ion-item {
                color: var(--ion-color-danger);
        
                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-danger);
                    }
                }
        
                :hover {
                    color: var(--ion-color-danger);
                }
            }
        }
    }
}