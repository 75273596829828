el-buen-inquilino-contract-rc-insurance {
  .datepicker-label {
    margin-top: 6px;
    font-size: 12px !important;
  }

  .datepicker-wrapper {
    width: 100%;
    height: 40px;

    .mat-datepicker-toggle {
      height: 40px;
      width: 40px;
    }
  }

  .img-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .rotating-icon {
    animation: rotate 1.5s linear infinite;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
