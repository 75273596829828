el-buen-inquilino-building-item {
  ion-card {
    --background: #f2f2f2;
    padding: 1rem;
    p {
      margin: 0;
    }
  }

  .btn-wrapper {
    margin-left: auto;
  }

  ion-chip {
    height: 32px;
  }
}
