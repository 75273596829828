el-buen-inquilino-sinister-detail-modal {
  .input-disabled {
    opacity: 1 !important;
  }

  .sinister-more-info {
    display: grid;
    align-items: center;
    grid-template-columns: 15% 50% 35%;
    .sinister-status-wrapper {
      text-align: right;
    }
  }

  .material-picker-wrapper {
    width: 100%;
    padding: 4px 20px;
    border: 1px solid rgba(5, 28, 44, 0.1);
    border-radius: 1rem;
    &.error-input {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
    .datepicker-input {
      cursor: pointer;
      border: 0;
      width: calc(100% - 40px);
      &:focus-visible {
        outline: none;
      }
    }
  }
}
