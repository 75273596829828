el-buen-inquilino-third-party-asset-page {
  ion-content {
    --background: #f2f2f2;
  }
  ion-header ion-button {
    margin: 0 !important;
  }
  .badge-scoring {
    font-size: 14px;
    height: 30px;
    line-height: 24px;
  }

  ion-badge[color='default'] {
    font-weight: normal;
  }

  ion-item.pl-0 {
    --padding-start: 0;
  }
  ion-item.asset-info {
    --ion-item-background: rgba(var(--ion-color-warning-rgb), 0.25);
  }

  .assets-button {
    cursor: pointer;
  }

  .name {
    line-height: 18px;
  }

  ion-progress-bar {
    border-radius: 2px;
  }

  .bg-transparent {
    --ion-item-background: transparent;
    span {
      font-size: 12px;
    }
  }

  ion-toolbar ion-buttons ion-icon {
    margin-right: 0.3rem;
  }

  ion-buttons {
    ion-button {
      font-size: 14px;
      margin-left: 0.5rem !important;
    }
  }
  /* Set the width to the full container and center the content */
  ion-select {
    // width: 100%;
    justify-content: center;
  }

  /* Set the flex in order to size the text width to its content */
  ion-select::part(placeholder),
  ion-select::part(text) {
    flex: 0 0 auto;
  }

  /* Set the placeholder color and opacity */
  ion-select::part(placeholder) {
    opacity: 1;
  }

  /* Set the icon color and opacity */
  ion-select::part(icon) {
    opacity: 1;
  }

  .ion-searchbar {
    padding: 0 1rem;
  }
  .label-witout-checking {
    text-align: end;
  }
  .request-date {
    display: inline-flex;
    font-size: 12px;
    float: right;
    margin-right: 1rem;
  }
  .document-icon {
    font-size: medium;
  }
  .document-icon-red {
    color: var(--ion-color-danger);
  }
  ion-item.ion-no-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }
  .percentage-box {
    @media (min-width: 500px) {
      padding-left: 1rem;
    }
  }
  @media (max-width: 380px) {
    ion-item {
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --padding-start: 0.5rem;
      --inner-padding-start: 0px;
    }
  }

  .notVisitedAsset {
    --background: gold;
  }
  .select-filter {
    border: 1px solid #999;
    margin: 0.5rem 1rem 0 0;
    border-radius: 0.5rem;
  }
  .asset-label-btn {
    background-color: #f2f2f2;
    color: var(--ion-color-primary);
    display: flex;
    margin-right: 0.5rem;
    ion-label {
      font-size: 11px;
      margin-top: 1px;
    }
  }
  .pill {
    display: flex;
    position: fixed;
    height: 100%;
    padding: 0.8em 3px;
    border-radius: 5px 5px 5px 5px;
    vertical-align: -4px;
    background-color: #e5ab00;
  }
  .asset-info {
    position: relative;
    padding: 0.5em 0;
  }

  .pill-round {
    display: flex;
    padding: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: white;
    margin: 1px 4px 0 0;
  }

  .select-filter-wrapper {
    display: flex;
    align-items: center;
  }

  .date-item {
    width: 110px;
  }
}
