html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: Lato;
}
a {
  text-decoration: none;
  color: var(--ion-color-secondary);
  cursor: pointer;
}

ion-toolbar {
  &.tenant-header-toolbar {
    --background: white;
    ion-title,
    ion-label {
      font-weight: 600;
    }
  }
}

ion-header {
  // height: 3rem;
  background-color: white;
  .logo {
    height: 31px;
  }
}
ion-card {
  border-radius: 0.5rem;
  box-shadow: none;
  ion-card-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }
}
ion-title {
  padding: 1rem;
  text-align: left;
  font-size: 14px;
}
ion-segment-button {
  margin: 0.5rem;
  ion-label {
    padding: 0.5rem 0;
  }
}

ion-item.ion-no-padding {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-start: 0px;
}

$split-pane-side-max-width: 250px;

.split-pane-visible > .split-pane-side {
  min-width: $split-pane-side-max-width !important;
  max-width: $split-pane-side-max-width !important;
}

.container {
  border-radius: 8px;
  background-color: #ffffff;
}

.container-transparent {
  -webkit-box-shadow: 0px 0px 50px -18px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 0px 50px -18px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 50px -18px rgba(0, 0, 0, 0.8);
  background: rgba(255, 255, 255, 0.9);
}

.modal-wrapper {
  --border-radius: 8px;
}

hr {
  border: 0;
  height: 1px;
  width: 100%;
  background: gray;
  opacity: 0.4;
  display: block;
}

fieldset {
  border: 0;
}

.text-center {
  text-align: center;
}

ion-modal.auto-height {
  --height: auto;
  .modal-wrapper {
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  ion-backdrop {
    --backdrop-opacity: 0.7;
  }
  &.modal-extend {
    --width: 800px;
  }
}

.reopen-cancel-policy-modal {
  --backdrop-opacity: 0.7 !important;
}

.vive-doc-modal {
  --height: 350px;
}

ion-modal.custom-modal-xl {
  --height: 90%;
  .modal-wrapper {
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  ion-backdrop {
    --backdrop-opacity: 0.7;
  }
  &.modal-extend {
    --width: 800px;
  }
}

.see-tenant-sinister-modal {
  --max-width: 1200px;
  --width: 100%;
}

.confirmation-modal {
  --max-height: 200px;
  --backdrop-opacity: 0.7 !important;

  ion-button {
    min-width: 50px;
  }
}

.cancel-policy-alert {
  --max-height: 300px;
  --backdrop-opacity: 0.7 !important;
}

.modal-backdrop {
  --backdrop-opacity: 0.7 !important;
}

ion-modal.custom-trial-modal-xl {
  --height: 100%;
  --max-height: 90vh;
  .modal-wrapper {
    .ion-page {
      position: relative;
      contain: content;
      .modal-content {
        overflow: auto;
      }
    }
  }
  ion-backdrop {
    --backdrop-opacity: 0.7;
  }
  &.modal-extend {
    --width: 800px;
  }
}

ion-modal.custom-over-modal {
  --backdrop-opacity: 0.7 !important;
}

ion-modal.no-auto-height {
  .modal-wrapper {
    .ion-page {
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  ion-backdrop {
    --backdrop-opacity: 0.7;
  }
  &.modal-extend {
    --width: 800px;
  }
  &.modal-medium {
    --width: 400px;
  }
}

ion-item {
  &.ion-invalid.ion-dirty {
    ion-icon {
      color: var(--ion-color-danger);
    }
    border-bottom: 2px solid var(--ion-color-danger);
  }
}

.-avoid-truncate-text {
  white-space: pre-line;
}

:root[mode='ios'] .searchbar-input.sc-ion-searchbar-ios {
  font-size: 14px;
}
:root[mode='ios'] ion-content,
:root[mode='ios'] ion-input,
:root[mode='ios'] ion-item,
:root[mode='ios'] ion-text,
:root[mode='ios'] ion-label,
:root[mode='ios'] ion-button {
  font-size: 14px;
}
ion-menu ion-icon {
  font-size: 1.2rem;
  padding-left: 0.5rem;
  margin-right: 1rem;
}
:root[mode='ios'] ion-badge {
  font-size: 11px;
}

ion-range {
  --bar-height: 5px;
  --knob-size: 25px;
}

.report-history-modal {
  --max-width: 1200px;
  --width: 100%;
  .ion-page {
    overflow-y: scroll;
  }
}

// Slider documents
.tab-button-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

swiper-container {
  height: 100%;
}

.arrow-wrapper {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  ion-icon {
    font-size: 30px;
  }
}

.arrow-wrapper.active-arrows ion-icon {
  color: white;
}

.left-arrow-wrapper {
  left: 0;
}

.right-arrow-wrapper {
  right: 0;
}

// End slider

// Signin slider
.signin-slider {
  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    width: 100%;
    --border-width: 1px;
    --border-radius: 5px;
    --border-color: var(--ion-color-primary);
    ion-label {
      margin: 0;
      text-align: center;
    }
  }

  .active-slide-email-registration {
    --background: var(--ion-color-primary);
    color: white;
  }
}
// End signin slider

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-danger {
  color: var(--ion-color-danger);
}

// Customer care
.reliability-popover .popover-content {
  --min-width: 350px;
}
.in-out-select-popover .popover-content {
  --min-width: 275px;
}
.multiple-api-assign-popover {
  --min-width: 275px;
}
.multiple-price-assign-popover {
  --min-width: 525px;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
// End Customer care

.clean-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

@media (max-width: 767px) {
  .last-component-responsive {
    margin-bottom: 2rem !important;
  }

  ion-modal.auto-height {
    --height: 100%;
    .modal-wrapper {
      .ion-page {
        position: absolute;
        contain: content;
        max-height: initial;
      }
    }
    ion-backdrop {
      --backdrop-opacity: 0.7;
    }
    &.modal-extend {
      --width: 100%;
    }
  }

  .container.container-email,
  .container.container-registry-form {
    overflow-y: scroll;
    transform: translateY(-25px);
  }
  .container.container-email {
    height: calc(90vh - 100px);
  }
  .container.container-registry-form {
    height: calc(90vh - 150px);
  }
  ion-modal.contract-rc-insurance {
    --max-height: 80vh;
    --height: 100%;
    transform: translateY(-8vh);
  }

  ion-modal.cotenant-modal.candidature-received-modal {
    --height: 30% !important;
    --min-height: 30% !important;
  }

  ion-modal.custom-modal-xl {
    --height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) and (max-height: 850px) {
  // @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .container.container-email {
    margin-bottom: 150px;
  }
}

@media (max-width: 575px) {
  .container.container-email {
    overflow-y: initial;
    transform: initial;
    margin-bottom: 150px;
    height: initial;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-break-spaces {
  white-space: break-spaces !important;
}

// Estilos selects de operaciones abiertas y cerradas
.date-item {
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 36px;
}
.date-item::part(native) {
  height: 35px;
  --min-height: 35px;
}

.date-select::part(icon),
.address-select::part(icon) {
  content: url('/icons/chevron-down-outline.svg');
  width: 20px;
  height: 20px;
  position: absolute;
  right: 1rem;
}
.more-filters-select::part(icon) {
  right: 10px;
}

.sc-ion-searchbar-ios-h {
  height: 36px;
}

/********************** Estilos componentes hijos de multipage **********************/
.table-wrapper.multipage {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 4px 10px;
  margin-bottom: 1rem;

  .table-header {
    font-weight: bolder;
    border-bottom: 1px solid var(--ion-color-light);
    padding-bottom: 5px;
  }

  .transparent-button {
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
  }

  .clean-date {
    --color: var(--ion-color-contrast);
  }

  .font-size-12 {
    font-size: 12px !important;
  }

  .table-body {
    p {
      margin: 0 !important;
    }
  }
}
/********************** FIN estilos componentes hijos de multipage **********************/

/********************** Estilos Preview Report modal **********************/
@media (min-width: 1200px) {
  .preview-report {
    --max-width: 1000px !important;
    --width: 100% !important;
  }
}
@media (min-width: 992px) {
  .preview-report {
    --max-width: 750px;
    --width: 100%;
  }
}
@media (min-width: 768px) {
  .preview-report {
    --max-height: 90vh;
    --height: 100%;
  }
}
@media (max-width: 575px) {
  .preview-report {
    --height: 100%;
    align-items: flex-start;
    .modal-wrapper {
      max-height: 80%;
      border-radius: 0;
    }
  }
}
/********************** FIN estilos Preview Report modal **********************/

.administrator-popover ion-select-popover {
  overflow-y: auto !important;
}

.type-document-popover,
.sector-profile-popover,
.position-profile-popover,
.incofisa-owner-select-popover,
.more-filters-popover,
.building-backoffice-popover {
  --min-width: 325px;
  ion-select-popover {
    overflow-y: auto !important;
  }
}

.incidence-types-select-popover {
  --width: 100%;
  --max-width: 375px;
  --max-height: 300px;
}

.incidence-building-select-popover {
  --width: 100%;
  --max-width: max-content;
  --max-height: 300px;
}

.educational-level-profile-popover,
.educational-profile-popover,
.marital-status-profile-popover {
  ion-select-popover {
    overflow-y: auto !important;
  }
}

.candidature-status-info-modal {
  .modal-wrapper {
    max-height: 150px;
  }
}

.quarter-popover {
  --min-width: 275px;
}

.tenant-insurance-popover {
  --backdrop-opacity: 0;
  --box-shadow: none;
  --offset-y: 10px;

  ion-content {
    --background: #219652;
    --color: white;
  }

  &.contracted-popover {
    --background: #219652;
    ion-content {
      --background: #219652;
    }
  }

  &.in-progress-popover {
    --background: var(--ion-color-warning);
    --min-width: 275px;
    ion-content {
      --background: var(--ion-color-warning);
    }
  }
}

/********************** Estilos Request Report modal **********************/
.request-report-modal {
  --min-height: 200px;
  --max-height: 250px;
}
/********************** FIN estilos Request Report modal **********************/

/********************** Estilos ADD BUILDING modal **********************/
.add-building-modal {
  --max-width: 800px;
  --width: 100%;
  --height: 100%;
  --max-height: 800px;
}

@media (max-width: 800px) {
  .add-building-modal .modal-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (max-height: 800px) {
  .add-building-modal .modal-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
/********************** FIN estilos ADD BUILDING modal **********************/

.asset-label-chip {
  min-width: 100px;
  justify-content: center;
}

.asset-price-chip {
  min-width: 75px;
  justify-content: center;
}

.asset-api-chip {
  min-width: 75px;
  justify-content: center;
}

.asset-date-chip {
  min-width: 75px;
  justify-content: center;
}

.address-select-filter-popover,
.add-document-year-popover {
  ion-select-popover {
    overflow-y: auto !important;
    ion-item {
      --inner-border-width: 0;
    }
  }
}

.incofisa-insurance-homeowner-popover {
  --min-width: 400px;
  ion-select-popover {
    overflow-y: auto !important;
    ion-item {
      --inner-border-width: 0;
    }
  }
}

.cc-owner-select-popover {
  --min-width: 325px;
  --max-height: 500px;
  ion-select-popover {
    overflow-y: auto !important;
  }
}

.more-filters-popover .popover-content {
  --min-width: 275px;
}

// ESTILOS GLOBALES INPUTS
.global-input-item {
  --border-width: 1px;
  --border-radius: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --inner-padding-end: 0;
  border-bottom: 0 !important;
  --min-height: 65px;
  --height: 65px;

  &.ion-invalid.ion-touched.ion-dirty {
    --border-color: red;
  }

  .global-input-label {
    color: var(--ion-color-medium);
    margin-bottom: 0px;
  }
  .global-input {
    font-weight: 700;
    --padding-top: 5px;
  }
  .datepicker-input {
    font-weight: 700;
    width: calc(100% - 40px);
  }
}

.global-yearly-fee-item {
  --boder-color: #219653;
  --background: #219653;
  --color: white;
  .global-input-label {
    color: white;
  }
}

//TODO: Elimninar con la actualización de IONIC
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
// END Elimninar con la actualización de IONIC

/******* Estilos angular material calendar *******/
.mat-datepicker-toggle-active {
  color: var(--ion-color-secondary);
}
.mat-calendar-body-active {
  .mat-calendar-body-cell-content {
    background-color: #2ba5d84d !important;
  }
}

.mat-calendar-body-cell:hover {
  .mat-calendar-body-cell-content {
    background-color: #2ba5d84d !important;
  }
}

.mat-calendar-body-in-range::before {
  background: #2ba5d84d !important;
}

.mat-calendar-body-selected {
  background-color: var(--ion-color-secondary) !important;
  color: black;
}

.datepicker-input {
  cursor: pointer;
  border: 0;
  &:focus-visible {
    outline: none;
  }
  &.profile-datepicker {
    text-align: right;
  }
}

.datepicker-range-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.hide-period-button .mat-calendar-period-button {
  display: none;
}

@media (min-height: 1080px) {
  .update-basic-modal.custom-modal-xl {
    --height: 80%;
  }
}

@media (min-height: 1152px) {
  .update-basic-modal.custom-modal-xl {
    --height: 75%;
  }
}
.renew-insurances-modal {
  --max-width: 900px;
  --width: 100%;
}

.condition-select-popover {
  --min-width: 250px;
}

.condition-opts-select-popover {
  --min-width: 550px;
}

.reject-candidature-alert {
  .reject-candidature-alert-cancel {
    font-weight: 600;
  }
  .reject-candidature-alert-reject {
    font-weight: normal !important;
  }
}

tr.mat-mdc-row {
  border-bottom: 1px dashed
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
    );
}
td.mat-mdc-cell {
  border-bottom: 0px !important;
}

.mat-mdc-paginator-container {
  font-size: 12px;
}

.v7-form-item {
  &.ion-invalid {
    border-bottom: 0 !important;
  }
  &.ion-invalid.ion-touched.ion-dirty {
    .input-wrapper {
      .native-input {
        border: 2px solid var(--ion-color-danger);
      }
    }
    .textarea-wrapper {
      .native-textarea {
        border: 2px solid var(--ion-color-danger);
      }
    }
    .v7-select,
    .v7-select-2 {
      border: 2px solid var(--ion-color-danger);
    }
    ion-intl-tel-input {
      border: 2px solid var(--ion-color-danger);
      .native-input {
        border: 0 !important;
      }
    }

    .datepicker-wrapper {
      border: 2px solid var(--ion-color-danger);
    }

    .v7-phone-input {
      border: 2px solid var(--ion-color-danger);
    }

    .v7-input-w-icon {
      .input-wrapper {
        border: 2px solid var(--ion-color-danger);
        .native-input {
          border: 0;
        }
      }
    }
  }
  .v7-input,
  .v7-input-w-icon {
    .input-wrapper {
      gap: 10px;
      .label-text-wrapper {
        padding-left: 20px;
        div {
          font-size: 18px;
        }
      }
    }
  }
  .v7-input {
    .input-wrapper {
      .native-input {
        border: 1px solid #ccc;
        padding: 10px 1rem;
        border-radius: 10px;
      }
    }
  }
  .v7-input-w-icon {
    .input-wrapper {
      border: 1px solid #ccc;
      padding: 10px 1rem;
      border-radius: 10px;
    }
  }
  .v7-textarea {
    .textarea-wrapper {
      gap: 3px;
      .label-text-wrapper {
        padding-left: 20px;
        div {
          font-size: 18px;
        }
      }
      .native-textarea {
        border: 1px solid #ccc;
        padding: 10px 1rem;
        border-radius: 10px;
      }
    }
  }
  .v7-select {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0px 1rem;
  }
  .v7-select-2 {
    width: 100%;
    &::part(label) {
      padding-left: 1rem;
      font-size: 18px;
      margin-bottom: 10px;
    }
    &::part(container) {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 0.5rem 1rem;
    }
    &::part(icon) {
      bottom: 9px;
      height: 18px;
    }
  }

  &.v7-item-select-w-icon,
  &.v7-item-phone-w-icon {
    border: 1px solid #ccc;
    border-radius: 10px;
    --padding-start: 1rem;
    --padding-end: 1rem;

    .input-icon {
      font-size: 14px;
    }
  }

  &.v7-item-phone-w-icon {
    --padding-start: 0rem;
  }

  .datepicker-label {
    padding-left: 1rem;
    font-size: 14px;
  }
  .datepicker-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;

    .mat-datepicker-toggle {
      height: 44px;
      width: 44px;

      .mdc-icon-button {
        height: 44px;
        width: 44px;
        padding: 8px;
      }
    }
  }

  .v7-phone-input {
    border: 1px solid #ccc;
    border-radius: 10px;
    .ion-intl-tel-input-code,
    .ion-intl-tel-input-flag {
      font-size: 14px;
    }
    ion-input {
      --padding-top: 0;
      --padding-bottom: 0;
      min-height: 40px;
    }
  }

  .v7-phone-label {
    font-size: 12px !important;
    padding-left: 1rem !important;
  }
}

.v7-text-error {
  padding-left: 1rem;
}

.border-error {
  border: 2px solid var(--ion-color-danger) !important;
}

.width-fit-content {
  width: fit-content;
}

.item-label-col {
  padding: 0 1rem;
  ion-label {
    color: black;
  }
}

.full-screen-modal {
  --width: 95vw;
  --height: 95vh;
  --max-width: 100vw;
  --max-height: 100vh;
}
