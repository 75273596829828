el-buen-inquilino-recommendation-page {
  ion-item {
    --ion-background-color: transparent;
  }

  .enterprise-demo {
    background-image: url('/images/enterprise-login.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
  }

  .no-account {
    font-size: 0.8em;
  }

  ::ng-deep ion-content {
    --offset-bottom: 0% !important;
  }

  ::ng-deep ion-footer {
    display: none !important;
  }

  .logo-container {
    height: 10%;
  }

  .form-container {
    height: 90%;
  }

  .return-btn {
    float: right;
  }
}
