el-buen-inquilino-selected-candidature-page {
  ion-content {
    --background: #f2f2f2;
    --padding-bottom: 3rem;
  }

  ion-card {
    --background: var(--ion-color-white);

    .wrapper-content {
      font-size: 0.875rem;
      background: var(--ion-color-white);
    }

    &.border {
      border: 0.80px solid var(--ion-color-medium-tint) !important;
    }
  }

  .title {
    font-weight: bold;
  }

  .font-medium {
    font-size: 1.1rem;
  }
  
  .link {
    cursor: pointer;
    padding-right: 0.25rem;
    font-weight: bold;

    &:hover {
      color: var(--ion-color-secondary);
    }
  }
}
