el-buen-inquilino-profile-preferences-content {
  .ebi-preference-block {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 1rem;
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  .filled {
    background: var(--ion-color-primary);
    color: white;
  }
}
