el-buen-inquilino-edit-tenant-email-modal {
  .alignement-angle-brackets {
    align-self: center;
    text-align-last: center;
  }

  .light-bold {
    font-weight: 600;
  }
}
