el-buen-inquilino-data-login {
  ion-row {
    height: 100%;
  }
  ion-grid {
    height: 100%;
  }
  ion-item {
    --ion-background-color: transparent;
  }

  ion-radio {
    border: 1px solid var(--ion-color-primary);
    border-radius: 100%;
    width: 24px;
    height: 24px;
    margin: 0 1rem 0 0;
  }

  .enterprise-login {
    h2 {
      font-size: 2em;
      font-weight: bold;
    }
    h3 {
      font-size: 1.25em;
      font-weight: bold;
    }
    h4 {
      font-size: 1.15em;
    }
    h5 {
      font-size: 1.1em;
    }
  }

  .no-show {
    display: none;
  }

  .show {
    display: block;
  }

  .go-back-phone {
    display: flex;
    align-items: self-end;
  }

  .google-btn-col {
    ion-button {
      --background: #4285f4;
      --background-activated: #4285f4;
      --background-activated-opacity: #4285f4;
      --background-focused: #4285f4;
      --background-focused-opacity: #4285f4;
      --background-hover: #4285f4;
      --background-hover-opacity: #4285f4;
      font-family: 'Roboto';

      .img-google-btn {
        height: 100%;
      }
    }
  }
}
