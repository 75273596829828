el-buen-inquilino-request-analysis-list-page {
  ion-content {
    --padding-bottom: 3rem;
  }

  ion-row {
    margin: 0;
    ion-col {
      padding: 0.5rem 0.8rem !important;
    }
    &.table-header {
      background-color: var(--ion-color-dark);
      color: var(--ion-color-light);
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
    }
    &.table-body {
      cursor: pointer;
      color: var(--ion-color-primary);
      font-size: 14px;
      p {
        margin: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
      &:first-child {
        font-weight: 500;
        background-color: var(--ion-color-dark);
      }
      &:hover {
        background-color: var(--ion-color-secondary) !important;
        color: white;
        ion-chip {
          color: white !important;
        }
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1400px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;

    p {
      margin: 0;
      color: var(--ion-color-primary);
      font-size: 14px;
      font-weight: 400;
    }
  }

  tbody {
    td.mat-cell {
      padding-top: 1rem;
      padding-bottom: 1rem;

      color: var(--ion-color-primary);
      font-size: 14px;
      font-weight: 400;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .truncate {
    width: fit-content;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
  }

  .red-person {
    color: var(--ion-color-danger);
  }

  ion-icon {
    pointer-events: none;
  }

  .advance-search-col {
    display: flex;
    ion-item {
      width: 90%;
    }
  }

  ion-button.advance-search {
    --color: var(--ion-color-secondary);
  }

  .transparent-button {
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
  }

  .clean-date {
    --color: var(--ion-color-contrast);
  }

  .filter-text-item {
    // --padding-start: 5px;
    --inner-padding-end: 0;
  }

  .archive-active-btn {
    --color: white;
    font-weight: bolder;
    border: 1px solid white;
    padding: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin: 0;
  }

  .advanced-user-filter-btn-icon {
    font-size: 28px;
  }

  .advanced-user-filter-error {
    --padding-top: 0;
    --padding-bottom: 0;
  }

  .filters-row {
    ion-item {
      --min-height: 44px;
      height: 44px;
    }
  }

  .filters-col {
    height: fit-content;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .material-picker-wrapper {
    display: flex;
    align-items: center;
    .mat-datepicker-toggle {
      height: 44px;
      width: 44px;

      .mdc-icon-button {
        height: 44px;
        width: 44px;
        padding: 8px;
      }
    }
  }

  .search-date-btn {
    margin: 0;
    height: 44px;
  }

  .th-archived,
  .td-archived {
    width: 100px;
    text-align: center;
  }
  .th-archived span {
    padding: 1rem;
  }

  .archive-active-btn {
    font-weight: bolder;
    border: 1px solid black;
    padding: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin: 0;
  }
}
