el-buen-inquilino-insurances-candidature-list {
    width: 100%;

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    table {
        border: 0px !important;
        min-width: 100% !important;

        tbody {
            tr {
                margin: 0.5rem 0;

                td {
                    padding-top: 0.7rem !important;
                    padding-bottom: 0.7rem !important;
                }
            }
        }
    }

    .icon-medium {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        font-weight: bold;
    }
}