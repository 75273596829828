el-buen-inquilino-ofuscate-bank-account-number {
  .ofuscate-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    ion-label {
      max-width: 250px;
      width: 100%;
    }
  }
}
