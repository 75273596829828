el-buen-inquilino-step-one {
  .selected {
    background-color: var(--ion-color-primary);
    color: white;
  }

  .item-class {
    cursor: pointer;
    &:nth-child(even) {
      --background: var(--ion-color-light-tint);
    }
  }

  .header {
    padding: 0;
    span {
      margin: 0;
      font-weight: bold;
    }
  }

  .address {
    width: 30%;
    font-size: 14px;
    // margin-left: 1rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .portal,
  .floor,
  .door {
    width: 15%;
    font-size: 14px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .town,
  .province {
    width: 25%;
    font-size: 14px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (max-width: 767px) {
    .address-row {
      border-top: 1px solid var(--ion-color-medium);
    }

    .address {
      margin-left: 0 !important;
    }

    .address-col {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
