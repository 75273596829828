el-buen-inquilino-backoffice-sinister-page {
  el-buen-inquilino-shared-sinisters {
    .filters-col {
      padding: 0 0.5rem;
    }

    .pocket-profits {
      padding-left: 0;
      padding-right: 0;
    }

    .table-col {
      padding: 0;
    }

    ion-grid {
      padding: 0.5rem !important;
    }
  }
}
