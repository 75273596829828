el-buen-inquilino-multiple-document-modal {
  pdf-viewer {
    display: block;
  }

  .pdf-viewer {
    width: 100%;
    height: 450px;
  }

  .image-container {
    &.not-pdf {
      overflow: scroll;
    }
    height: 100%;
  }
  ion-img {
    overflow: hidden;
    width: 56%;
    position: fixed;
    opacity: 0.2;
    z-index: 2000;
    margin: 0;
    margin-top: 14%;
    transform: translateX(32%);
    -ms-transform: translateX(30%);
  }
  .doc-selected {
    background-color: aliceblue !important;
  }

  ion-footer {
    ion-toolbar {
      --padding-start: 1rem;
      --padding-end: 1rem;
      --padding-top: 1rem;
      --padding-bottom: 1rem;
      .wrapper {
        display: grid;
        grid-template-columns: 40% 40%;
        gap: 20%;
      }
    }
    ion-button {
      margin: 0;
    }
  }

  @media (max-width: 575px) {
    ion-footer {
      ion-toolbar {
        .wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }

  @media (min-height: 1080px) {
    .pdf-viewer {
      height: 700px;
    }
  }

  @media (min-height: 1200px) {
    .pdf-viewer {
      height: 900px;
    }
  }
}
