el-buen-inquilino-phone-input-ngmodel {
  .phone-wrapper {
    display: flex;
  }

  .prefix-wraper {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
