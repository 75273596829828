el-buen-inquilino-data-signin-enterprise {
  ion-item {
    --ion-background-color: transparent;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
  }

  ion-row {
    height: 100%;
  }
  ion-grid {
    height: 100%;
  }

  .link-modal {
    text-decoration: underline;
    cursor: pointer;
  }

  .terms-class .modal-wrapper {
    --height: 80vh !important;
  }

  .couponError {
    color: red;
    margin-left: 20px;
    padding-top: 0.2rem;
  }

  .couponOK {
    color: green;
    margin-left: 20px;
    padding-top: 0.2rem;
  }

  .phone-item {
    --padding-start: 0;
  }
  ion-intl-tel-input {
    .ion-intl-tel-input-code,
    .ion-intl-tel-input-flag {
      font-size: 14px;
    }
  }
}
