el-buen-inquilino-billing-group-table {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1400px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;

    &.modal-table {
      min-width: auto !important;
    }

    p {
      margin: 0;
      color: var(--ion-color-primary);
      font-size: 14px;
      font-weight: 400;
    }
  }

  tbody {
    td.mat-cell {
      padding-top: 1rem;
      padding-bottom: 1rem;

      color: var(--ion-color-primary);
      font-size: 14px;
      font-weight: 400;
    }
  }
}
