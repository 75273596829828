el-buen-inquilino-incidence-detail-modal {
    ion-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        color: var(--ion-color-medium);
        --border-radius: 30%;
        border: 1px solid var(--ion-color-medium);
    }

    .message { 
        border: 1px solid var(--ion-color-medium);
        color: var(--ion-color-medium);
        border-radius: 0.5rem;
        padding: 0.75rem;
    }

    .date {
        font-size: 12px !important;
    }

    .message-no-documents {
        padding: 1rem;
        margin-top: 1rem;
    }

    .form-comment {
        border-radius: 0.5rem;
        margin-top: 1rem;
        display: flex;
        justify-content: end;
        
        ion-textarea {
            background: #fff;
            border: 1px solid var(--ion-color-medium-tint);
            border-radius: 0.5rem;
            padding: 0.5rem;
        }
    }

    .document-name {
        color: var(--ion-color-medium);
        font-size: 13px !important;
    }

    .document-date {
        color: var(--ion-color-secondary);
        background-color: var(--ion-color-light-tint);
        font-size: 13px !important;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
    }

    ion-footer {
        ion-toolbar {
            --background: #fff;
        }
    }

    .status-wrapper {
        width: 180px;
        border-radius: 0.5rem;
        padding: 2px;
        
        .incidence-types-select {
            width: 100%;
            padding: 0 0.5rem;
            border: none;
        }

        &.status-new {
            border: 1px solid var(--ion-color-warning);

            ion-item {
                color: var(--ion-color-warning);

                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-warning);
                    }
                }

                :hover {
                    color: var(--ion-color-warning);
                }
            }
        }

        &.status-escalated {
            border: 1px solid var(--ion-color-secondary);

            ion-item {
                color: var(--ion-color-secondary);

                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-secondary);
                    }
                }

                :hover {
                    color: var(--ion-color-secondary);
                }
            }
        }

        &.status-closed {
            border: 1px solid var(--ion-color-success);

            ion-item {
                color: var(--ion-color-success);
        
                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-success);
                    }
                }
        
                :hover {
                    color: var(--ion-color-success);
                }
            }
        }

        &.status-cancelled {
            border: 1px solid var(--ion-color-danger);

            ion-item {
                color: var(--ion-color-danger);
        
                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-danger);
                    }
                }
        
                :hover {
                    color: var(--ion-color-danger);
                }
            }
        }

        &.status-ejecucion {
            border: 1px solid var(--ion-color-primary);

            ion-item {
                color: var(--ion-color-primary);
        
                ion-select {
                    &::part(icon) {
                        color: var(--ion-color-primary);
                    }
                }
        
                :hover {
                    color: var(--ion-color-primary);
                }
            }
        }
    }
}