el-buen-inquilino-add-multiple-candidature-modal {
  .add-candidature-content {
    overflow-y: scroll;
  }

  ion-footer {
    ion-toolbar {
      padding-bottom: 6px !important;
    }
    ion-button {
      margin: 0;
    }
  }
  .summary-tenants {
    margin-top: 0;
  }

  ion-icon {
    pointer-events: none;
  }

  el-buen-inquilino-candidature-new-tenants {
    ion-card {
      margin: 0;
    }
  }
}
