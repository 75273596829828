el-buen-inquilino-fee-pocket-banner {
  ion-card.-pocket-banner-bg {
    background-image: url('/assets/images/banner-homeowner.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    min-height: 18.4rem;
    width: 21rem;
    .-fee-data {
      margin-top: 8rem;
    }
  }

  ion-item {
    --min-height: 1.8rem;
    ion-label {
      margin: 0;
    }
  }

  ::ng-deep .custom-alert {
    .danger {
      color: tomato;
    }
  }
}
