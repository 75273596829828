el-buen-inquilino-multiple-api-assign {
  .item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .control-item {
    --border-width: 1px;
    --border-radius: 1rem;
    --border-color: var(--ion-color-light-shade);
  }

  ion-checkbox {
    --border-radius: 6px;
  }
}
