el-buen-inquilino-business-rol-data-form-modal {
  .boton {
    color: #ffffff;
    background-color: #1b94bd;
    border-color: #1b94bd;
    padding: 10px;
    border-radius: 20px;
  }

  ion-card {
    background-color: rgba(252, 193, 0, 0.5);
  }
}
