el-buen-inquilino-payment-analysis-success-page {
  .grid-content {
    background-image: url('/images/bg-owner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
    height: 100%;
    width: 100%;
  }

  ion-row {
    width: 100%;
    height: 100%;
    ion-card {
      background-color: rgba(250, 250, 250, 0.9);
    }
  }

  ion-img {
    max-width: 60%;
    margin: 2rem auto;
  }
}
