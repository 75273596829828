el-buen-inquilino-modify-net-amount-modal {
  .background-grey {
    background-color: #f2f2f2;
  }

  .fields {
    margin-top: 20px;
    margin-bottom: 35px;

    max-width: 50% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
