el-buen-inquilino-customer-care-request-analysis-page {
  ion-content {
    --padding-start: 15px;
    --padding-end: 15px;
    --background: var(--ion-color-light);
  }

  .wrapper {
    background-color: white;
    border-radius: 0.5rem;
    padding-top: 10px;
    padding-bottom: 10px;

    .wrapper-border-title {
      padding: 5px;
    }
  }

  .clean-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .padding-item {
    --padding-start: 5px !important;
  }

  .margin-element {
    margin-left: 10px;
    margin-top: 10px;
  }

  .chip-medium-strong {
    color: black;
    border: 1px solid black;
  }

  .reliability-select {
    --padding-start: 0;
  }

  .no-padding-top {
    padding-top: 0;
  }

  .no-padding-bottom {
    padding-top: 0;
  }

  .no-padding-horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  .no-padding-vertical {
    padding-top: 0;
    padding-bottom: 0;
  }

  .social-network-select {
    --padding-start: 0;
    padding-right: 5px;
  }

  ion-textarea {
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    border-color: #719ece;
    box-shadow: 0 0 1px #719ece;
    border-radius: 0.5rem;
    max-width: 75% !important;
  }

  ion-input {
    border-color: #719ece;
    box-shadow: 0 0 1px #719ece;
    border-radius: 0.5rem;
    text-align: left;
    --padding-top: 10px;
    --padding-end: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
  }

  ion-input[type='number'] {
    max-width: 120px !important;
    text-align: right;
  }

  ion-input[type='number']::after {
    content: '€';
    padding-right: 15px;
  }

  .smallInput {
    max-width: 50px;
  }

  .bigInput {
    max-width: 200px;
  }

  .smallInput,
  .bigInput {
    --padding-start: 10px;
  }

  .label-certifier {
    min-height: 27px;
  }

  .tenant-col {
    padding: 0 10px;
    ion-item {
      --padding-start: 5px;
      --padding-end: 5px;
      --padding-top: 5px;
      --padding-bottom: 5px;
      &:hover {
        --background: #2ba5d840;
        cursor: pointer;
      }
    }
  }

  .active-tenant-item {
    --background: #2ba5d840;
  }

  .tenant-icon {
    font-size: 28px;
    margin-right: 10px;
  }

  ion-icon[name='arrow-forward-outline'] {
    font-size: 28px;
  }

  .student-color {
    color: var(--ion-color-medium);
  }
  .freelance-color {
    color: var(--ion-color-secondary);
  }
  .retired-color,
  .guarantor-color {
    color: orange;
  }

  .font-normal-weight {
    font-weight: normal;
  }

  .label-radio {
    margin-left: 10px;
  }

  .actions-row {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: #221e1e;

    div {
      text-align: right;
      padding-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .form-name-title {
    padding: 5px 0;
  }

  .validated-document-item {
    --padding-top: 0;
    --padding-bottom: 0;
    height: 14px;
    display: flex;
    align-items: center;
  }

  .validated-document-checkbox {
    height: 14px;
    width: 14px;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 1;
  }

  .validated-document-label {
    opacity: 1 !important;
  }

  .eye-button {
    --padding-start: 0;
  }

  .margin-footer-bar {
    margin-bottom: 100px;
  }

  .homeowner-label {
    background-color: var(--ion-color-medium);
    color: white;
    border-radius: 0.5rem;
  }

  .validated-document-col {
    padding-right: 22px;
  }
}
