el-buen-inquilino-insurance-fees-page {
  .table-row {
    background-color: var(--ion-color-light-tint);
    ion-col {
      border: 1px solid white;
    }
    &-header ion-col {
      background-color: var(--ion-color-medium);
      color: #fff;
      line-height: 12px;
      padding: 8px 8px;
    }
  }

  ion-row {
    margin: 0;
    ion-col {
      padding: 0.5rem 0.8rem;
    }
    &.table-body {
      cursor: pointer;
      color: var(--ion-color-primary);
      p {
        margin: 0;
        line-height: 2.5;
      }
      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
      &:first-child {
        font-weight: 500;
        background-color: var(--ion-color-dark);
      }
      &:hover {
        background-color: var(--ion-color-secondary) !important;
        color: white;
        ion-chip {
          color: white !important;
        }
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }

  .tabs {
    cursor: pointer;
    margin: 5px 0;
    .index {
      display: flex;
      * {
        border: 1px solid #ccc;
        background: #eee;
        padding: 4px 12px;
        border-radius: 4px 4px 0 0;
        position: relative;
        top: 1px;
      }
      .active {
        background: #fff;
        border-bottom: 1px solid transparent;
      }
    }
    .contents {
      border: 1px solid #ccc;
      min-height: 120px;
      padding: 12px;
    }
  }

  .table {
    &-header ion-col {
      font-weight: bold;
      font-size: 14px;
      background-color: black;
      color: white;
    }
    &-body {
      border-bottom: 1px solid var(--ion-color-light);
    }
    &-footer {
      font-weight: bold;
      margin-top: 0.5rem;
      background-color: var(--ion-color-primary);
      color: var(--ion-color-light);
      border-radius: 0.3rem;
    }
  }

  .filters-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    .name-filter-item {
      max-width: 350px;
      width: 100%;
    }
  }
}
