el-buen-inquilino-candidature-notes {
  $note-wrapper-height: 250px;

  .wrapper {
    height: 100%;
    display: grid;
    grid-template-rows: auto $note-wrapper-height;
  }

  .notes-wrapper {
    padding: 1rem;
    overflow-y: scroll;
  }

  .note-wrapper {
    background: #f0f4ff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    border: 1px solid #f0f4ff;
    border-radius: 1rem;
    position: relative;
    border-bottom-left-radius: 0;

    &.own-message {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 0;
      background: #d9fdd3;
      border-color: #d9fdd3;
    }
  }

  .writer-wrapper,
  .date-wrapper {
    span {
      color: var(--ion-color-medium);
      font-size: 12px;
    }
  }

  .date-wrapper {
    text-align: right;
  }

  .new-note-wrapper {
    height: $note-wrapper-height;
    padding: 0 1rem 1rem;
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  ion-textarea.message {
    border: 1px solid var(--ion-color-light-shade);
    --padding-start: 10px;
    border-radius: 1rem;
    height: 150px;
    margin-top: 0.5rem;
  }

  ion-button {
    margin: 0;
    --padding-start: 10px;
    --padding-end: 10px;
  }

  .textarea-wrapper {
    height: 100%;
  }
}
