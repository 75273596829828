el-buen-inquilino-not-found-page {
  .wrapper {
    display: grid;
    place-content: center;
    width: 100%;
    height: 100%;
    gap: 4rem;
  }
  p {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
  }
  img {
    width: auto;
    max-height: 500px;
  }

  ion-button {
    width: fit-content;
    margin: 0 auto;
  }
}
