el-buen-inquilino-backoffice-user-document-item {
  .copy-button-label {
    display: grid;
    grid-template-columns: 120px 50px auto;
    place-items: center;

    ion-button {
      margin: 0;
      height: 14px;
    }
    ion-icon {
      font-size: 14px;
    }
  }
}
