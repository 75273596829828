el-buen-inquilino-associated-users-resume {
  .table-row {
    background-color: var(--ion-color-light-tint);
    ion-col {
      border: 1px solid white;
    }
    &-header ion-col {
      background-color: var(--ion-color-medium);
      color: #fff;
      line-height: 12px;
      padding: 8px 8px;
    }
  }

  .table-totals {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);
    ion-col {
      border: 1px solid white;
    }
  }

  ion-row {
    margin: 0;
    ion-col {
      padding: 0.2rem 0.8rem;
      &.on {
        background-color: var(--ion-color-light-tint);
      }
      &.off {
        background-color: none;
      }
    }
    &.table-body {
      cursor: pointer;
      color: var(--ion-color-primary);
      p {
        margin: 0;
        line-height: 2.5;
      }
      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
      &:first-child {
        font-weight: 500;
      }
      &:hover {
        background-color: var(--ion-color-secondary) !important;
        color: white;
        ion-chip {
          color: white !important;
        }
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }
}
