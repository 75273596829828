el-buen-inquilino-social-media-analysis {
  .no-padding-top {
    padding-top: 0;
  }

  .form-name-title {
    padding: 5px 0;
  }

  .font-normal-weight {
    font-weight: normal;
  }

  .wrapper {
    background-color: white;
    border-radius: 0.5rem;
    padding-top: 10px;
    padding-bottom: 10px;

    .wrapper-border-title {
      padding: 5px;
    }
  }

  ion-textarea {
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    border-color: #719ece;
    box-shadow: 0 0 1px #719ece;
    border-radius: 0.5rem;
    max-width: 97% !important;
  }

  .text-area-col {
    margin: 5px 7px;
  }

  ion-input {
    border-color: #719ece;
    box-shadow: 0 0 1px #719ece;
    border-radius: 0.5rem;
    text-align: left;
    --padding-top: 10px;
    --padding-end: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px !important;
    margin: 2px;
  }

  ion-input[type='number'] {
    max-width: 120px !important;
    text-align: right;
  }

  .label-radio {
    margin-left: 10px;
  }

  .clean-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .copy-button-label {
    display: flex;
    align-items: center;
    ion-button {
      margin: 0;
      height: 14px;
    }
    ion-icon {
      font-size: 14px;
    }
  }

  .modal-container {
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px;
  }
}
