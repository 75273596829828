el-buen-inquilino-onboarding-modal {
  img.-onBoarding-image {
    max-width: 20rem;
    margin: 2rem 0 0 0;
  }
  .slide {
    margin: 30px;
  }

  @media (max-width: 767px) {
    img.-onBoarding-image {
      max-width: 15rem;
    }
    .slide {
      margin: 18px;
    }
  }
}
