el-buen-inquilino-cotenant-modal {
  .my-custom-modal-css .modal-wrapper {
    width: 50% !important;
    height: 30% !important;
  }

  .users {
    background-color: var(--ion-color-light-tint);
    --ion-background-color: var(--ion-color-light-tint);
    border-radius: 0.5em;
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    ion-card {
      background-color: var(--ion-color-light-tint);
      --ion-background-color: var(--ion-color-light-tint);

      ion-item {
        --background: var(--ion-color-light-tint);
        --ion-background-color: var(--ion-color-light-tint);
      }
    }
  }

  ion-text {
    font-size: 14px;
  }

  ion-card {
    margin: 0;
  }

  div.users {
    ion-icon {
      font-size: 22px;
    }
  }
}
