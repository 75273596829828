el-buen-inquilino-login-phone-page {
  .login-container {
    width: 100%;
    height: 100%;
    background-image: url('/images/bg-owner-2.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: scroll;
    display: grid;
    place-items: center;
    padding: 1rem;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  ion-card {
    width: 100%;
    margin: 0;
  }

  ion-button {
    margin: 0;
  }

  @media (min-width: 576px) {
    .container {
      width: 500px;
    }
  }
}
