el-buen-inquilino-login-methods {
  .container {
    width: 100%;
  }

  ion-card {
    margin: 0;
  }

  .google-btn {
    --background: #4285f4;
    --background-activated: #4285f4;
    --background-activated-opacity: #4285f4;
    --background-focused: #4285f4;
    --background-focused-opacity: #4285f4;
    --background-hover: #4285f4;
    --background-hover-opacity: #4285f4;
    font-family: 'Roboto';

    .img-google-btn {
      height: 100%;
    }
  }

  ion-button {
    margin: 0;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .card-title {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--ion-color-primary);
  }

  @media (min-width: 576px) {
    .container {
      min-width: 500px;
      max-width: 500px;
    }
  }
}
