el-buen-inquilino-documents-certifications-page {
  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
  }
  ion-card-header {
    padding: 20px 20px 0 20px;
  }
  ion-card-content {
    ion-button {
      --padding-start: 0;
      --padding-end: 0;
    }
  }
  hr {
    margin: 8px 0 0 0;
  }

  ion-item.-student {
    --border-radius: 10px;
  }

  .-tab-height {
    height: 100%;
  }

  ion-tab-bar {
    height: 5rem;
  }

  ion-tab-button {
    text-align: left;
  }

  .isActive {
    background-color: var(--ion-color-primary) !important;
    ion-label {
      color: white;
    }
  }

  ion-item {
    --ion-background-color: transparent;
  }

  ion-avatar {
    border: 2px solid var(--ion-color-medium-tint);
    &.-incidence-tabs-border {
      border: 2px solid var(--ion-color-danger);
    }
  }

  ion-icon.-incidence-tabs-border {
    position: absolute;
    font-size: 30px;
    top: -5px;
    right: 0;
    color: var(--ion-color-danger);
  }

  .-not-register-user {
    border: 3px solid #ffc409;
  }
}
