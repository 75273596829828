el-buen-inquilino-document-modal {
  pdf-viewer {
    display: block;
    height: 100%;
  }

  .image-container {
    overflow: auto;
    height: 100%;

    ion-img {
      overflow: hidden;
      width: 56%;
      position: fixed;
      opacity: 0.2;
      z-index: 2000;
      margin: 0;
      margin-top: 14%;
      transform: translateX(32%);
      -ms-transform: translateX(30%);
    }
  }
}
