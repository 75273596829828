el-buen-inquilino-insurance-page {
  ion-header {
    ion-toolbar {
      --background: white;
      padding-left: 1.25rem;
    }
  }
  ion-content {
    --background: #f2f2f2;
    --padding-bottom: 3rem;
  }

  .select-filter-wrapper {
    display: flex;
    align-items: center;
  }

  .select-filter {
    border: 1px solid #ccc;
    border-radius: 10px;
    --background: #fff;
    height: 36px;
    ion-label {
      font-weight: normal;
    }
  }

  .insurance-list {
    padding: 0.5rem;
  }

  .insurance-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-radius: 0.25rem;
    &-warning {
      background-color: rgba(var(--ion-color-warning-rgb), 0.1);
    }
    &:hover {
      background-color: rgba(var(--ion-color-primary-rgb), 0.05);
    }
  }

  .insurance-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
      ion-icon {
        color: var(--ion-color-dark-tint);
      }
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .insurance-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    ion-button {
      --padding-start: 0;
      --padding-end: 0;
      padding: 0;
    }
  }

  ion-button.icon-xl {
    --border-radius: 1rem;
    --padding-start: 0;
    --padding-end: 0;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0;
  }

  .insurance-address-wrapper {
    .insurance-title {
      font-weight: 500;
    }
    .insurance-subtitle {
      font-size: 12px;
      text-transform: capitalize;
      span {
        color: var(--ion-color-secondary);
      }
    }
  }

  ion-footer {
    ion-toolbar {
      --background: #f2f2f2;
      --border-width: 0;
      ion-item {
        --background: transparent;
      }
    }
  }

  .more-info-wrapper {
    gap: 10px;
  }

  .asset-rent-chip {
    min-width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .asset-date-chip {
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
