el-buen-inquilino-tenant-incidences-page {
    ion-content {
        --background: #f2f2f2;
        --offset-bottom: 0px !important;
        --padding-bottom: 3rem;
    }

    el-buen-inquilino-incidences-list {
        font-size: 0.875rem;
    }

    .container-information {
        border-radius: 0.7rem;
        margin: 0.5rem;
        padding: 0.5rem 0rem 0.5rem 0.25rem;
        border-left: 8px solid var(--ion-color-danger);

        .span-title {
            margin-bottom: 1rem;
        }
    }

    .icon-medium {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        font-weight: bold;
    }
}