el-buen-inquilino-cancel-policy-alert {
  .material-picker-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    .mat-datepicker-toggle {
      height: 35px;
      width: 35px;

      .mdc-icon-button {
        height: 35px;
        width: 35px;
        padding: 4px;
      }

      .mat-mdc-button-touch-target {
        height: 35px;
        width: 35px;
      }
    }
  }
}
