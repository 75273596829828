el-buen-inquilino-add-arag-insurance {
  .recalculate-btn {
    width: 100%;
    height: 100%;
    margin: 0;
    --border-radius: 1rem;
    --border-width: 2px;
    font-weight: 700;
  }

  .datepicker-label {
    margin-top: 6px;
    font-size: 12px !important;

    &.start-datepicker-label {
      margin-top: 0;
    }
  }

  .datepicker-wrapper {
    width: 100%;
    height: 40px;

    .mat-datepicker-toggle {
      height: 40px;
      width: 40px;
    }
  }

  .phone-label {
    margin-top: 6px;
  }

  .v7-phone-input {
    height: 40px;
  }

  @media (max-width: 767px) {
    .recalculate-btn {
      height: 100%;
      min-height: 65px;
    }
  }
}
