el-buen-inquilino-tenant-actions-modal {
  .background-grey {
    background-color: #f2f2f2;
  }

  .text-wrapper {
    border: 1px solid #f2994a;
    padding: 1rem;
    border-radius: 1rem;
    background: rgba(242, 153, 74, 0.08);
    .text-title {
      color: #f2994a;
    }
  }

  .error-text-wrapper {
    border: 1px solid #dd5b6d;
    padding: 1rem;
    border-radius: 1rem;
    background: rgba(242, 153, 74, 0.08);
    .error-text-title {
      color: #dd5b6d;
    }
  }

  .candidature-card {
    border-radius: 1rem;
    border: 1px solid #40aedc;
    padding: 1rem;
    background: rgba(242, 153, 74, 0.08);

    ::ng-deep .mat-card-header-text {
      display: none;
    }
  }

  ::ng-deep .candidature-card {
    border-radius: 0.5rem;
    margin: 0 0 1rem 0 !important;
  }
}
