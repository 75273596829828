el-buen-inquilino-shared-sinisters {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1600px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;
  }

  .filters-col {
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
  }

  .table-col {
    padding: 0.5rem 0.5rem;
  }

  .pocket-profits {
    margin: 0;
  }

  .tabs {
    cursor: pointer;
    margin: 5px 0;
    .index {
      display: flex;
      * {
        border: 1px solid #ccc;
        background: #eee;
        padding: 4px 12px;
        border-radius: 4px 4px 0 0;
        position: relative;
        top: 1px;
      }
      .active {
        background: #fff;
        border-bottom: 1px solid transparent;
      }
    }
    .contents {
      border: 1px solid #ccc;
      min-height: 120px;
      padding: 12px;
    }
  }

  .td-title {
    font-weight: 400;
    color: var(--ion-color-primary);
  }

  .td-title-secondary {
    font-weight: 400;
    color: var(--ion-color-secondary);
  }
  
  .td-subtitle {
    font-size: 12px;
    text-transform: capitalize;

    span {
      color: var(--ion-color-secondary);
      &:hover {
        color: white;
      }
    }
  }

  .sinister-data {
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.sinister-data-resolved {
      background-color: var(--ion-color-success);
    }
    &.sinister-data-unresolved {
      background-color: var(--ion-color-danger);
    }
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell {
    padding: 8px;
  }

  .action-th,
  .action-td {
    width: 50px;
    ion-button {
      margin: 0;
      --padding-start: 0;
      --padding-end: 0;
    }
  }

  .resolved-th,
  .unresolved-th,
  .resolved-td,
  .unresolved-td {
    width: 195px;
  }

  .net-premium-th,
  .net-premium-td {
    min-width: 175px;
  }

  .asset-rent-th,
  .asset-rent-td {
    min-width: 175px;
  }

  .creation-date-th,
  .start-date-th,
  .closed-date-th,
  .creation-date-td,
  .start-date-td,
  .closed-date-td {
    width: 150px;
  }

  .address-th,
  .address-td {
    min-width: 400px;
  }

  .statistics-container,
  .numbers-wrapper {
    display: flex;
    gap: 1rem;
  }

  .statistics-container {
    flex-wrap: wrap;
  }

  .number-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    min-width: 200px;
    height: 142px;
    justify-content: center;
  }

  .phases-wrapper {
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    flex-wrap: wrap;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 32%);
    gap: 2%;
  }

  .phase-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    height: 100%;

    .two-phase-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      width: 100%;
    }
  }

  .phase-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    $phase-number-size: 75px;
    .phase-number {
      width: $phase-number-size;
      height: $phase-number-size;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid;
      border-radius: 50%;
      cursor: pointer;

      span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .phase-title {
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .phase-subtitle {
    font-size: 14px;
    text-align: center;
  }

  $notified-ebi-color: var(--ion-color-success);
  $ic-initial-color: var(--ion-color-success);
  $ic-burofax-color: var(--ion-color-warning);
  $ic-complaint-filled-color: var(--ion-color-warning);
  $pending-trial-color: var(--ion-color-danger);
  $scheduled-eviction-color: var(--ion-color-danger);
  .notified-to-ebi {
    border-color: $notified-ebi-color !important;
    &.selected {
      background-color: $notified-ebi-color;
      color: white;
    }
  }
  .ic-initial {
    border-color: $ic-initial-color !important;
    &.selected {
      background-color: $ic-initial-color;
      color: white;
    }
  }
  .ic-burofax {
    border-color: $ic-burofax-color !important;
    &.selected {
      background-color: $ic-burofax-color;
      color: white;
    }
  }
  .ic-complaint-filled {
    border-color: $ic-complaint-filled-color !important;
    &.selected {
      background-color: $ic-complaint-filled-color;
      color: white;
    }
  }
  .pending-trial {
    border-color: $pending-trial-color !important;
    &.selected {
      background-color: $pending-trial-color;
      color: white;
    }
  }
  .scheduled-eviction {
    border-color: $scheduled-eviction-color !important;
    &.selected {
      background-color: $scheduled-eviction-color;
      color: white;
    }
  }

  @media (min-width: 1500px) {
    .phase-wrapper {
      $phase-number-size: 100px;
      .phase-number {
        width: $phase-number-size;
        height: $phase-number-size;
      }
    }

    .phase-subtitle {
      font-size: 16px;
    }
  }

  @media (min-width: 1920px) {
    .numbers-wrapper {
      flex-direction: column;
    }

    .phases-wrapper {
      min-width: 1200px;
      width: auto;
    }

    .phase-container {
      justify-content: center;
    }
  }

  .notVisitedSinister {
    background-color: rgba(var(--ion-color-warning-rgb), 0.1);
  }
}
