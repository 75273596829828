el-buen-inquilino-tenant-list {
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
    }
    
    table {
        width: 100%;
        cursor: default;
        padding-bottom: 1rem;

        tbody {
            tr {
                margin: 0.5rem 0;

                td {
                    padding-top: 0.7rem !important;
                    padding-bottom: 0.7rem !important;
                }
            }

        }
    }

    ion-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        color: var(--ion-color-medium);
        --border-radius: 30%;
        border: 1px solid var(--ion-color-medium-tint);
        cursor: pointer;
    }   
}