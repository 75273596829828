el-buen-inquilino-calculated-default-table {
  ion-row {
    margin: 0;
    ion-col {
      padding: 0.5rem 0.8rem;
    }
    &.table-header {
      background-color: var(--ion-color-danger-shade);
      color: var(--ion-color-light);
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
    }
    &.table-body {
      color: var(--ion-color-primary);
      font-size: 14px;
      p {
        margin: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
      &:first-child {
        font-weight: 500;
        background-color: var(--ion-color-dark);
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }
}
