el-buen-inquilino-home-owner-tenants-page {
  ion-content {
    --background: #f2f2f2;
  }

  p {
    margin: 0;
    &:first-child {
      margin-top: 0.5rem;
    }
    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  ion-row {
    border-bottom: 1px solid #f2f2f2;
    &:last-child {
      border: 0;
    }
  }

  ion-buttons {
    ion-button {
      font-size: 14px;
      &:last-child {
        margin: 0 0.5rem !important;
      }
    }
  }
  ion-card-header {
    ion-item {
      border-bottom: 1px solid var(--ion-color-light);
    }
  }
  ion-item {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  @media (max-width: 767px) {
    .empty-label {
      white-space: break-spaces;
    }
  }
}
