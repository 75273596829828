el-buen-inquilino-internal-events {
  .form-block-nota {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 1rem 1rem 0 1rem;
    overflow: hidden;
  }
  .note-item {
    padding: 0 1rem 1rem 1rem;
  }

  ion-footer {
    padding-top: 1rem;
  }
}
