el-buen-inquilino-add-tenant-insurance {
  .info-wrapper {
    padding: 1rem;
    border: 1px solid #f2994a;
    background: rgba(242, 153, 74, 0.08);
    border-radius: 1rem;
    .info-title {
      color: #f2994a;
      p {
        margin: 0;
      }
    }
  }
}
