el-buen-inquilino-money-laundering-list{
    .table-responsive {
        display: block;
        overflow-x: auto;
      }
    
    table {
        border: 0.55px solid
        var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
        ) !important;
    }

    .view-detail-button {
        --padding-start: 1rem;
        --padding-end: 1rem;
    }

    .building-list {
        height: 100%;
        overflow-y: auto;
        padding: 0.5rem;
      }
    
      .building-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 0;
        border-radius: 0.25rem;
        &-warning {
          background-color: rgba(var(--ion-color-warning-rgb), 0.1);
        }
        &:hover {
          background-color: rgba(var(--ion-color-primary-rgb), 0.05);
        }
      }
    
      .building-ref {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        .icon-xl {
          margin-right: 0.5rem;
          ion-icon {
            color: var(--ion-color-dark-tint);
          }
        }
        p {
          display: block;
          width: 100%;
          margin-top: 2px;
        }
        ion-badge {
          --background: #f2f2f2;
          --color: var(--ion-color-dark-tint);
          margin-right: 0.5rem;
          ion-icon {
            zoom: 0.9;
          }
          span + span ::after {
            display: inline-block;
            content: ' · ';
          }
          font-size: 10px;
          font-weight: normal;
        }
      }
    
      .building-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        ion-button {
          --padding-start: 0;
          --padding-end: 0;
          padding: 0;
        }
      }
    
      ion-button.icon-xl {
        --border-radius: 1rem;
        --padding-start: 0;
        --padding-end: 0;
        height: 2.5rem;
        width: 2.5rem;
        margin: 0;
      }
    
      .building-address-wrapper {
        width: 100%;
        .building-title {
          font-weight: 500;
        }
        .building-subtitle {
          font-size: 12px;
          text-transform: capitalize;
        }
      }
}