el-buen-inquilino-home-subscription-page {
  .bg-color-top {
    background-image: url('/images/Mockup-2.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    & .parallax-bg {
      padding-top: 5rem;
      padding-bottom: 5rem;
      background-color: RGBA(2, 31, 55, 0.9);
      color: #fff;
      text-align: center;
    }
  }
  .pricing {
    h1 {
      font-size: 40px;
      margin-bottom: 2rem;
    }
    section {
      margin: 2rem;
      text-align: center;
      .name {
        font-size: 24px;
        margin: 2rem 0 1rem 0;
      }
      .price {
        font-size: 36px;
        font-weight: bold;
        margin: 1rem 0 0 0;
      }
      .duration {
        margin: 0.5rem 0 0 0;
        opacity: 0.7;
      }
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style-type: none;
      line-height: 2rem;
      font-size: 16px;
    }
    hr {
      margin: 2rem 0;
    }
    ion-button {
      margin-top: 2rem;
    }
  }

  .values {
    background: url('/images/home/bg_pattern.png') #ffffff no-repeat center
      bottom;
    padding-top: 3rem;
    height: 820px;
    h3 {
      font-size: 24px;
      font-weight: bold;
    }
    .item {
      padding-top: 30px;
      text-align: center;
      ion-icon {
        width: 40px;
        height: 40px;
        color: var(--ion-color-primary);
        background: #fff;
        padding: 1rem;
        border: 1px solid var(--ion-color-primary);
        border-radius: 100%;
        cursor: pointer;
        &:hover {
          background: var(--ion-color-primary);
          color: #fff;
          transition: all 0.2s ease 0s;
        }
      }
      &-first {
        margin-top: 200px;
      }
      &-third {
        margin-top: 200px;
      }
    }
  }

  .img-problems {
    max-width: 70%;
  }
  .ion-card-img {
    max-height: 200px;
    padding: 1rem;
  }

  .features {
    background-color: #f2f2f2;
    padding-top: 3rem;
    padding-bottom: 3rem;
    ion-card {
      text-align: center;
      margin-bottom: 0;
    }
    ion-icon {
      display: block;
      top: 1rem;
      margin: 0 auto 1rem auto;
      height: 32px;
      width: 32px;
    }
    &.steps {
      p {
        margin-top: 0.5rem;
      }
    }
  }

  .sub-menu {
    ion-label {
      padding: 0 1rem;
      cursor: pointer;
      &.active {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          height: 4px;
          width: 100%;
          background-color: #fff;
          top: 27px;
          left: 0;
        }
      }
    }
  }

  /* Section - Parallax */
  /**************************/
  .parallax ion-grid {
    ion-icon {
      margin: 1rem 1rem 0 0;
      width: 40px;
      height: 40px;
    }
  }

  .parallax .parallax-metrics {
    position: relative;
    width: 100%;
    background-image: url('/images/home/bg_footer.jpg');
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    & .parallax-bg {
      background-color: RGBA(2, 31, 55, 0.9);
      color: #fff;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
