el-buen-inquilino-action-page {
  .altura {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: url('/images/bg-owner.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    ion-row {
      width: 100%;
    }
  }

  .wrapper {
    border-radius: 0.5rem;
    background-color: white;
  }

  .border-color {
    border: 1px solid #40aedc !important;
    border-radius: 25px;
  }

  @media (max-width: 767px) {
    .altura {
      padding: 0 1rem;
    }
  }

  .vivia-img {
    height: 48px;
    margin: 1rem;
  }
}
