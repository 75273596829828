el-buen-inquilino-sinister-page {
  ion-header {
    ion-toolbar {
      --background: white;
    }
  }

  ion-content {
    --background: #f2f2f2;
  }

  el-buen-inquilino-shared-sinisters {
    .filters-col {
      margin-top: 1rem;
      padding: 0.5rem 1rem;
    }

    .table-col {
      padding: 0.5rem 0;
    }
  }
}
