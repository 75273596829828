el-buen-inquilino-pay-certification-modal {
  .my-custom-modal-css .modal-wrapper {
    width: 50% !important;
    height: 30% !important;
  }

  .users {
    background-color: var(--ion-color-light-tint);
    --ion-background-color: var(--ion-color-light-tint);
    border-radius: 0.5em;
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ion-text {
    font-size: 14px;
  }

  ion-card {
    margin: 0;
  }
}
