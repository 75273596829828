el-buen-inquilino-contact-new-api {
  .form-block-nota {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 1rem 1rem 0 1rem;
    overflow: hidden;
  }
  .note-item {
    padding: 0 1rem 1rem 1rem;
  }

  ion-footer {
    padding-top: 1rem;
  }

  .building-form {
    ion-item {
      &:not(:first-child) {
        margin-top: 1rem;
      }
      &::part(native) {
        align-items: end;
      }

      ion-textarea {
        border: 1px solid #ccc;
        border-radius: 16px;
        --padding-start: 18px !important;
        --padding-end: 20px !important;
        --padding-top: 16px !important;
        --padding-bottom: 16px !important;
      }
    }
  }

  .textarea-item {
    --padding-end: 20px !important;
  }

  ion-text.error-text {
    padding-left: 20px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
