el-buen-inquilino-privacy-policy-page {
  h4 {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }
  .return-col {
    padding-top: 1rem;
  }

  @media (max-width: 767px) {
    .return-col {
      position: fixed;
      background-color: white;
      z-index: 10;
      padding-top: 5px;
    }

    .content-col {
      margin-top: 55px;
    }
  }

  @media (max-width: 575px) {
    ion-content {
      --padding-bottom: 130px;
    }
  }
}
