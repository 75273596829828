el-buen-inquilino-tabs-menu {
    width: 100%;

    ion-segment {
        border-bottom: 0.80px solid var(--ion-color-medium-tint) !important;
        --background: var(--ion-color-light);
        border-radius: 0rem;
        padding: 0 0.4rem;
    }

    ion-segment-button {
        --border-color: transparent;
        --color-checked: var(--ion-color-secondary);
        --indicator-box-shadow: none;
        --border-radius: 0rem;
        margin: 0.3rem 0 0 0;

        &.segment-button-checked {
            border-bottom: 3px solid var(--ion-color-secondary);
            color: var(--ion-color-secondary);
            font-weight: 700;
        }
    }
}