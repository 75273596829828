el-buen-inquilino-move-document-modal {
  .background-grey {
    background-color: #f2f2f2;
  }

  .fields {
    margin-top: 20px;
    margin-bottom: 35px;

    max-width: 50% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .warning {
    --background: var(--ion-color-warning);
    --border-radius: 0.5rem;
  }
}
