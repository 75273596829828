el-buen-inquilino-sinister-chronology-modal {
  .mat-mdc-header-cell {
    font-weight: bold;
  }

  $notified-ebi-color: var(--ion-color-success);
  $ic-initial-color: var(--ion-color-success);
  $ic-burofax-color: var(--ion-color-warning);
  $ic-complaint-filled-color: var(--ion-color-warning);
  $pending-trial-color: var(--ion-color-danger);
  $scheduled-eviction-color: var(--ion-color-danger);
  .notified-to-ebi {
    border-color: $notified-ebi-color !important;
  }
  .ic-initial {
    border-color: $ic-initial-color !important;
  }
  .ic-burofax {
    border-color: $ic-burofax-color !important;
  }
  .ic-complaint-filled {
    border-color: $ic-complaint-filled-color !important;
  }
  .pending-trial {
    border-color: $pending-trial-color !important;
  }
  .scheduled-eviction {
    border-color: $scheduled-eviction-color !important;
  }
}
