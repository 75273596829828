el-buen-inquilino-manage-tags {
  .pill-round {
    display: flex;
    color: white;
    padding: 10px;
    border-radius: 10px;
    vertical-align: -4px;
    background-color: white;
    margin-right: 8px;
  }
  ion-icon {
    cursor: pointer;
  }
  ion-input:focus {
    background-color: blue;
  }
}
