el-buen-inquilino-add-document {
  .items-container:not(:first-child) {
    margin-top: 10px;
  }

  .ion-alert {
    border-radius: 4px;
    background-color: RGBA(255, 223, 83, 1);
    padding: 0.5rem 0.75rem;
  }

  ion-toolbar {
    --border-width: 0 !important;
  }

  .datepicker-wrapper {
    width: 100%;
    input {
      width: calc(100% - 40px);
    }
  }

  .help-container {
    position: relative;
    width: 18px;
    height: 100%;

    ion-button {
      margin: 0;
      position: absolute;
      --padding-end: 0;
      --padding-start: 0;
      bottom: 4px;
    }
  }
}
