// Estas clases ya las tiene ionic ion-color-primary

.color-secondary {
    color: var(--ion-color-secondary);
}

.color-primary {
    color: var(--ion-color-primary);
}

.color-light {
    color: var(--ion-color-light);
}

.color-medium {
    color: var(--ion-color-medium);
}

// Estas clases no las he visto en ionic son para poner el color de fondo

.bg-secondary {
    background-color: var(--ion-color-secondary);
    color: #fff;
}

.bg-primary {
    background-color: var(--ion-color-primary);
    color: #fff;
}

.bg-warning {
    background-color: var(--ion-color-warning);
}

.bg-light {
    background-color: var(--ion-color-light-tint);
}

.ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
}
