el-buen-inquilino-portfolio-page {
  ion-content {
    --background: #f2f2f2;
  }

  .row-content {
    height: calc(100% - 56px);
    ion-col,
    ion-card {
      height: 100%;
    }
  }

  .select-filter-wrapper {
    display: flex;
    align-items: center;
  }

  .select-filter {
    border: 1px solid #ccc;
    border-radius: 10px;
    --background: #fff;
    height: 36px;
    ion-label {
      font-weight: normal;
    }
  }

  .building-list {
    height: 100%;
    overflow-y: auto;
    padding: 0.5rem;
  }

  .building-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-radius: 0.25rem;
    &-warning {
      background-color: rgba(var(--ion-color-warning-rgb), 0.1);
    }
    &:hover {
      background-color: rgba(var(--ion-color-primary-rgb), 0.05);
    }
  }

  .building-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
      ion-icon {
        color: var(--ion-color-dark-tint);
      }
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .building-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    ion-button {
      --padding-start: 0;
      --padding-end: 0;
      padding: 0;
    }
  }

  ion-button.icon-xl {
    --border-radius: 1rem;
    --padding-start: 0;
    --padding-end: 0;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0;
  }

  .building-address-wrapper {
    .building-title {
      font-weight: 500;
    }
    .building-subtitle {
      font-size: 12px;
      text-transform: capitalize;
    }
  }

  ion-footer {
    ion-toolbar {
      --background: #f2f2f2;
      --border-width: 0;
      ion-item {
        --background: transparent;
      }
    }
  }

  .map-col {
    height: 100%;
    ion-card {
      height: 100%;
      el-buen-inquilino-google-maps {
        height: 100%;
      }
    }
  }
  .row-content-map-mobile {
    height: calc(100% - 56px) !important;
    .map-col {
      padding-left: 1rem;
    }
  }

  .maps-wrapper,
  el-buen-inquilino-google-maps {
    height: 100%;
    width: 100%;
  }

  .statistics-chip {
    --background: #f2f2f2;
    --color: #d3d3d3;
    ion-icon {
      color: #c1c1c1;
    }
  }

  .green-number {
    color: var(--ion-color-success);
  }
  .red-number {
    color: var(--ion-color-danger);
  }
  .black-number {
    color: #1e1e1e;
  }

  .hide-map-btn {
    float: right;
    margin-right: 1rem;
  }

  .no-building-text-wrapper {
    border: 1px solid #f2994a;
    padding: 1rem;
    border-radius: 1rem;
    background: rgba(242, 153, 74, 0.08);
    .no-building-text-title {
      color: #f2994a;
    }
  }

  .filters-wrapper {
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 10%;
    align-items: center;

    .internal-code-wrapper {
      background-color: white;
      height: 36px;
      border-radius: 10px;

      ion-select {
        min-height: 36px;
        --padding-start: 1rem;
      }
    }

    ion-button {
      justify-self: end;
    }
  }

  .v7-form-item {
    --min-height: 36px;
    background-color: transparent;
    --border-radius: 10px;
  }

  @media (max-width: 767px) {
    .row-content {
      height: calc(100% - 56px);
    }
    .filters-wrapper {
      padding-left: 21px;
    }
  }

  @media (max-width: 575px) {
    .row-content {
      height: initial;
    }
  }
}
