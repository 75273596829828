el-buen-inquilino-shared-policies {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1600px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;
    cursor: default;
  }

  .td-title {
    font-weight: 400;
    color: var(--ion-color-primary);
  }

  .td-title-secondary {
    font-weight: 400;
    color: var(--ion-color-secondary);
  }

  .td-subtitle {
    font-size: 12px;
    text-transform: capitalize;

    span {
      color: var(--ion-color-secondary);

      &:hover {
        color: white;
      }
    }
  }
}
