el-buen-inquilino-asset-filters {
  .filters-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    .filter-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .tag-button {
        min-width: 135px;
      }

      .ion-searchbar {
        min-height: 44px;
        min-width: 300px;
        width: 100%;
        max-width: 325px;
        &.searchbar-borders {
          border: 1px solid #ccc;
          border-radius: 10px;
        }
      }
    }
  }

  .month-select {
    min-width: 130px;
  }

  .year-select {
    min-width: 85px;
  }

  .date-select {
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    height: 36px;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-bottom: 0;
    --padding-top: 0;
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .date-filter-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .select-filter {
    border: 1px solid #ccc;
    border-radius: 10px;
    --background: #fff;
    height: 36px;
    ion-label {
      font-weight: normal;
    }
  }

  .building-filter-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    ion-select {
      border: 1px solid #ccc;
      border-radius: 10px;
      background: #fff;
      height: 36px;
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-bottom: 0;
      --padding-top: 0;
      min-width: 105px;
      display: grid;
      grid-template-columns: 80% 20%;
    }
  }

  ion-segment {
    height: 36px;
    max-width: 120px;
    width: 100%;
    min-width: 120px;
    ion-segment-button {
      height: 26px;
      margin: 4px !important;
      min-width: 50px !important;
      --padding-end: 4px;
      --padding-start: 4px;
    }
  }

  .no-insurances-item {
    --background: transparent;
  }
}
