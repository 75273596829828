el-buen-inquilino-success-page {
  ion-item {
    --ion-background-color: transparent;
  }

  ion-content {
    --background: transparent;
    & > ion-grid {
      height: 100%;
      padding: 0;
      ion-row {
        height: 100%;
      }
    }
  }

  .incofisa-demo {
    background-image: url('/images/bg-owner.jpg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
  }

  .no-account {
    font-size: 0.8em;
  }

  ::ng-deep ion-content {
    --offset-bottom: 0% !important;
  }

  .sucess-message {
    position: fixed;
    padding: 8px 8px 8px 8px;
    border: 1px solid;
    border-radius: 8px;
    margin-left: 10%;
    margin-top: 5%;
  }
  .ebi-container {
    display: inline-flex;
  }

  .logo-box {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .-rocket-image img {
    max-width: 10rem;
    margin: 2rem 0 0 0;
  }

  .-rocket-container {
    display: inline-grid;
  }

  .-row-container {
    height: 100%;
  }
}
