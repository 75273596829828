el-buen-inquilino-cancelled-page {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1400px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;
  }

  .tabs {
    cursor: pointer;
    margin: 5px 0;
    .index {
      display: flex;
      * {
        border: 1px solid #ccc;
        background: #eee;
        padding: 4px 12px;
        border-radius: 4px 4px 0 0;
        position: relative;
        top: 1px;
      }
      .active {
        background: #fff;
        border-bottom: 1px solid transparent;
      }
    }
    .contents {
      border: 1px solid #ccc;
      min-height: 120px;
      padding: 12px;
      background-color: white;
    }
  }
}
