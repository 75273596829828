el-buen-inquilino-vpp-survey-results-modal {
  .my-custom-modal-css .modal-wrapper {
    width: 50% !important;
    height: 30% !important;
  }

  ion-text {
    font-size: 14px;
  }

  ion-card {
    margin: 0;
  }

  .radio-col {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .radio-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
