el-buen-inquilino-request-insurance-policy-modal {
  .badge-scoring-modal {
    font-size: 12px;
    height: 30px;
    line-height: 24px;
  }

  ion-item.ion-no-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  ion-item.ion-left-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 16px;
    --inner-padding-start: 0px;
  }

  ion-card.-grey-bg {
    // --background: #f2f2f2;
    border: 1px solid var(--ion-color-medium);
    --offset-bottom: 0px !important;
  }

  ion-card.-banner-bg {
    background-image: url('/images/Group-2.png');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
  }

  .bg-transparent {
    --ion-item-background: transparent;
  }
  .-center-title-label {
    align-self: center;
  }

  .icon-success {
    margin: 1rem 1rem 0 0;
  }
  .btn-documents {
    margin-top: 0.6rem;
    margin-right: 1rem;
    float: right;
  }

  .wrapper {
    overflow-y: auto;
  }

  @media (max-width: 767px) {
    .wrapper {
      overflow-y: scroll;
    }
  }
}
