el-buen-inquilino-profitability-candidature-information {
    .container-information {
        border-radius: 0.7rem;
        margin: 0.5rem 0;
        padding: 0.5rem 0rem 0.5rem 0.25rem;
        border-left: 8px solid var(--ion-color-danger);

        &.full-padding {
            padding: 0.5rem 1rem 1rem 1rem;
        }

        .span-title {
            margin-bottom: 1rem;
        }

        .button-expand {
            width: 100%;
        }
    }

    .icon-medium {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        font-weight: bold;
    }
}