el-buen-inquilino-custom-data-login {
  ion-item {
    --ion-background-color: transparent;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 22px;
  }

  ion-row {
    height: 100%;
  }
  ion-grid {
    height: 100%;
  }

  .without-height-width-row {
    height: fit-content !important;
  }

  .ebi-logo {
    width: 82px;
    height: 40px;
  }
  .logo {
    max-height: 40px;
    width: auto;
  }

  @media (max-width: 575px) {
    .ebi-logo {
      width: 82px;
      height: 35px;
    }
    .bialto-logo {
      width: 100%;
      height: 40px;
    }
  }
}
