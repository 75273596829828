el-buen-inquilino-shared-templates-list{
    .table-responsive {
        display: block;
        overflow-x: auto;
      }
    
    table {
        border: 0.55px solid
        var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
        ) !important;

        .template-file{
            display: flex;
            align-items: center;
        }

        .status-wrapper {
            border: 1px solid;
            width: 100%;
            padding: 6px 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            // padding: 2px;
      
            &.status-ready {
              border-color: var(--ion-color-success);
              color: var(--ion-color-success);
            }
      
            &.status-rejected {
              border-color: var(--ion-color-danger);
              color: var(--ion-color-danger);
            }
      
            &.status-in_progress {
              border-color: var(--ion-color-warning);
              color: var(--ion-color-warning);
            }
        }
    }

    .view-detail-button {
        --padding-start: 1rem;
        --padding-end: 1rem;
    }

    pdf-viewer {
      display: block;
    }
  
    .pdf-viewer {
      width: 100%;
      height: 450px;
    }

}