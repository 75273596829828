@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$custom-primary-palette: (
  50: #e0ebff,
  100: #bad0ec,
  200: #99b0cf,
  300: #7690b3,
  400: #5d7a9e,
  500: #42648b,
  600: #35577a,
  700: #254464,
  800: #17324e,
  900: #021f37,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff
  )
);

$custom-secondary-palette: (
  50: #e1f4fa,
  100: #b4e3f2,
  200: #85d1ea,
  300: #5bbfe1,
  400: #3fb1dd,
  500: #2ba4d8,
  600: #2597ca,
  700: #1c84b7,
  800: #1a73a4,
  900: #105482,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette($custom-primary-palette, 900);
$app-accent: mat.m2-define-palette($custom-secondary-palette, 500);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn
    )
  )
);

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: #fff !important;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
