el-buen-inquilino-analytics-list-page {
  ion-content {
    --background: #f2f2f2;
  }
  ion-card-title {
    font-size: 14px;
    color: var(--ion-color-dark);
  }
  .table-row {
    ion-col {
      border: 1px solid white;
    }
    &-header ion-col {
      background-color: var(--ion-color-medium);
      color: #fff;
      line-height: 12px;
      padding: 8px 8px;
    }
  }

  .table-totals {
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);
    ion-col {
      border: 1px solid white;
    }
  }

  .table-scroll-y {
    max-height: 21rem;
    overflow-y: scroll;
    &.xl {
      max-height: 42rem;
    }
  }

  ion-row {
    margin: 0;
    ion-col {
      &.on {
        background-color: var(--ion-color-light-tint);
      }
    }
    &.table-body {
      cursor: pointer;
      color: var(--ion-color-primary);
      p {
        margin: 0;
        line-height: 2.5;
      }
      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
      &:first-child {
        font-weight: 500;
      }
      &:hover {
        background-color: var(--ion-color-secondary) !important;
        color: white;
        ion-chip {
          color: white !important;
        }
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }

  .homeowners {
    .table-row {
      &-header ion-col {
        background-color: transparent;
        border-bottom: 2px var(--ion-color-medium) solid;
        color: var(--ion-color-dark);
        font-size: 14px;
      }
    }
    ion-row:hover ion-item {
      --color: white !important;
    }
  }
}
