el-buen-inquilino-card-profile {
  ion-card {
    margin: 1rem;

    ion-col {
      // margin-top: 0.5rem;
      padding: 0.5rem;
      &.name-col {
        padding-top: 0;
      }
    }
    h3 {
      color: var(--ion-color-primary);
    }
  }
}
