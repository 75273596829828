el-buen-inquilino-tenants-section {
  ion-item {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
    border-radius: 4px;

    ion-text {
      padding: 16px 8px 4px 8px;
    }

    ion-label {
      padding: 0px 8px 0px 8px;
    }

    ion-select,
    ion-input {
      --padding-start: 8px;
      --padding-end: 8px;
    }
  }

  .grid-content {
    background-color: var(--ion-color-light-tint);
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  }

  .fullscreen {
    width: 100%;
    height: 100%;
  }

  ion-card ion-card-header {
    ion-card-title,
    p {
      margin: 0;
    }
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-weight: bold;
  }

  ion-card-content ion-col {
    padding: 0;
  }
  .modify-column {
    display: flex;
    align-items: center;
  }
  .success-bar {
    width: 100%;
  }

  .row-user {
    margin-top: 1rem;
    cursor: pointer;
    &:hover {
      background-color: var(--ion-color-light) !important;
      color: white;
      ion-chip {
        color: white !important;
      }
    }
  }

  .badge-scoring {
    font-size: 14px;
    height: 30px;
    line-height: 24px;
  }

  ion-badge[color='default'] {
    font-weight: normal;
  }

  .danger-text {
    color: var(--ion-color-danger);
  }

  .item-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 1rem 0;

    .user-info-wrapper {
      display: inherit;
      align-items: inherit;
      min-width: 50%;
    }
    .btn-wrapper {
      width: 100%;
      text-align: right;
    }
  }
}
