el-buen-inquilino-register-user-template {
  ion-card {
    width: 500px;
    margin: 0;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  ion-radio-group {
    display: flex;
    gap: 5%;
    ion-radio {
      width: 50%;
      border: 2px solid #ddd;
      border-radius: 8px;
      padding: 1rem 0.5rem;
    }
  }

  ion-radio::part(label) {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  ion-radio::part(container) {
    display: none;
  }

  ion-radio.radio-checked {
    background-color: var(--ion-color-primary);
    color: white;
    border: 2px solid var(--ion-color-primary);
  }

  .google-btn {
    --background: #4285f4;
    --background-activated: #4285f4;
    --background-activated-opacity: #4285f4;
    --background-focused: #4285f4;
    --background-focused-opacity: #4285f4;
    --background-hover: #4285f4;
    --background-hover-opacity: #4285f4;
    font-family: 'Roboto';

    .img-google-btn {
      height: 100%;
    }
  }
}
