el-buen-inquilino-update-basic-info {
  .boton {
    color: #ffffff;
    background-color: #1b94bd;
    border-color: #1b94bd;
    padding: 10px;
    border-radius: 20px;
  }

  ion-card {
    background-color: rgba(252, 193, 0, 0.5);
  }

  .phone-item {
    --padding-start: 0;
  }

  .material-picker-wrapper {
    width: 100%;
    input {
      width: calc(100% - 40px);
    }
  }

  .wrapper {
    overflow-y: auto;
    height: 100%;
  }

  .document-number {
    width: 100%;
  }

  .datepicker-label {
    margin-top: 10px;
    font-size: 12px !important;
  }

  .datepicker-wrapper {
    width: 100%;
    height: 40px;

    .mat-datepicker-toggle {
      height: 40px;
      width: 40px;
    }
  }

  ion-item::part(native) {
    align-items: end;
  }

  .password-item,
  .confirm-password-item {
    --padding-bottom: 1rem;
  }

  .input-disabled {
    opacity: 1 !important;
  }
}
