el-buen-inquilino-data-signin {
  ion-item {
    --ion-background-color: transparent;
  }
  ion-row,
  ion-grid {
    height: 100%;
  }

  .link-modal {
    text-decoration: underline;
    cursor: pointer;
  }

  .terms-class .modal-wrapper {
    --height: 80vh !important;
  }

  .custom-font-size {
    font-size: 12px;
  }

  ion-text {
    p {
      line-height: 20px;
    }
  }
}
