el-buen-inquilino-homeowner-insurances-list {
  ion-content {
    --background: #f2f2f2;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  table {
    width: 100%;
    min-width: 1400px !important;
    border: 0.55px solid
      var(
        --ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
      ) !important;
  }

  .select-filter-wrapper {
    display: flex;
    align-items: center;
  }

  .select-filter {
    border: 1px solid #ccc;
    border-radius: 10px;
    --background: #fff;
    height: 36px;
    ion-label {
      font-weight: normal;
    }
  }

  .insurance-list {
    padding: 0.5rem;
  }

  .insurance-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-radius: 0.25rem;
    &-warning {
      background-color: rgba(var(--ion-color-warning-rgb), 0.1);
    }
    &:hover {
      background-color: rgba(var(--ion-color-primary-rgb), 0.05);
    }
  }

  .insurance-ref {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .icon-xl {
      margin-right: 0.5rem;
      ion-icon {
        color: var(--ion-color-dark-tint);
      }
    }
    p {
      display: block;
      width: 100%;
      margin-top: 2px;
    }
    ion-badge {
      --background: #f2f2f2;
      --color: var(--ion-color-dark-tint);
      margin-right: 0.5rem;
      ion-icon {
        zoom: 0.9;
      }
      span + span ::after {
        display: inline-block;
        content: ' · ';
      }
      font-size: 10px;
      font-weight: normal;
    }
  }

  .insurance-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    ion-button {
      --padding-start: 0;
      --padding-end: 0;
      padding: 0;
    }
  }

  ion-button.icon-xl {
    --border-radius: 1rem;
    --padding-start: 0;
    --padding-end: 0;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0;
  }

  .insurance-address-wrapper {
    display: flex;
    align-items: center;
    .insurance-title {
      font-weight: 500;
    }
    .insurance-subtitle {
      font-size: 12px;
      text-transform: capitalize;
      span {
        color: var(--ion-color-secondary);
      }
    }
  }

  ion-footer {
    ion-toolbar {
      --background: #f2f2f2;
      --border-width: 0;
      ion-item {
        --background: transparent;
      }
    }
  }

  .more-info-wrapper {
    gap: 10px;
  }

  .sinister-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;

    &.resolve-sinister-data {
      background-color: var(--ion-color-success);
    }

    &.cancellation-request {
      background-color: var(--ion-color-tertiary-shade);
    }
  }

  .asset-rent-chip {
    min-width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .asset-date-chip,
  .asset-original-policy-chip {
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .asset-original-policy-chip {
    margin-left: 1rem;
  }

  .tabs {
    cursor: pointer;
    margin: 5px 0;
    .index {
      display: flex;
      * {
        border: 1px solid #ccc;
        background: #eee;
        padding: 4px 12px;
        border-radius: 4px 4px 0 0;
        position: relative;
        top: 1px;
      }
      .active {
        background: #fff;
        border-bottom: 1px solid transparent;
      }
    }
    .contents {
      border: 1px solid #ccc;
      min-height: 120px;
      padding: 12px;
      background-color: white;
    }
  }

  ion-card {
    --background: transparent;
  }

  .statistic-wrapper {
    padding: 0 1.25rem;
  }

  .policy-id-wrapper {
    color: var(--ion-color-secondary);
  }
}
