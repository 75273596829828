el-buen-inquilino-report-history-modal {
  ion-row {
    margin: 0;
    ion-col {
      padding: 0.5rem 0.8rem;
    }
    &.table-header {
      background-color: var(--ion-color-dark);
      color: var(--ion-color-light);
    }
    &.table-body {
      cursor: pointer;
      color: var(--ion-color-primary);
      font-size: 14px;
      p {
        margin: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
      &:first-child {
        font-weight: 500;
        background-color: var(--ion-color-dark);
      }
      &:hover {
        background-color: var(--ion-color-secondary) !important;
        color: white;
        ion-chip {
          color: white !important;
        }
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }
}
