el-buen-inquilino-backoffice-landing-page {
  .wrapper {
    background-image: url('/images/backoffice/landing-bg.png');
    background-size: cover;  
    background-repeat: no-repeat;  
    background-attachment: fixed;  
    background-position: center;  
    width: 100%;
    min-height: 100vh;  
    padding: 2.5%;
    display: grid;
    place-items: center;
    ion-row {
      width: 100%;
    }
  }

  .backoffice-title {
    color: white;
    font-size: 40px;
    font-weight: bold;
  }

  .section-card {
    border: 1px solid var(--ion-color-medium);
    border-radius: 1rem;
    cursor: pointer;
    ion-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;
      color: black;

      ion-icon {
        font-size: 70px;
      }
    }
  }

  .img-wrapper {
    padding: 5%;
    text-align: center;
    img {
      max-width: 800px;
      width: 100%;
    }
    p {
      margin-top: 3rem;
      font-size: 22px;
    }
  }

  @media (max-height: 800px) {
    img {
      max-width: initial !important;
      width: auto !important;
      max-height: 400px;
    }
  }

  @media (max-height: 600px) {
    img {
      max-width: initial !important;
      width: auto !important;
      max-height: 300px;
    }
  }
}
