el-buen-inquilino-time {
  ion-content {
    --background: #f2f2f2;
  }

  .charts-card-wrapper {
    --background: transparent;
    height: 100%;
    overflow-y: auto;
    canvas {
      width: 100%;
      height: 100%;
    }
  }

  .chart-card {
    height: 100%;
  }

  .pie-wrapper,
  .doughnut-wrapper {
    canvas {
      max-height: 300px;
    }
  }

  .map-col {
    height: 100%;
    ion-card {
      height: 100%;
      el-buen-inquilino-google-maps {
        height: 100%;
      }
    }
  }

  .row-content-map-mobile {
    // height: calc(100% - 56px) !important;
    height: 100%;
    .map-col {
      padding-left: 1rem;
    }
  }

  .maps-wrapper,
  el-buen-inquilino-google-maps {
    height: 100%;
    width: 100%;
  }

  @media (min-width: 1200px) {
    .row-content {
      // height: calc(100% - 56px);
      height: 100%;
      & > ion-col {
        height: 100%;
        & > ion-card {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .map-col {
      padding: 5px 10px;
    }
    .maps-wrapper {
      min-height: 350px;
      position: relative;
      el-buen-inquilino-google-maps {
        position: absolute;
      }
    }
  }
}
