el-buen-inquilino-email-register {
  ion-item {
    --ion-background-color: transparent;
  }
  ion-row,
  ion-grid {
    height: 100%;
  }

  .link-modal {
    text-decoration: underline;
    cursor: pointer;
  }

  .terms-class .modal-wrapper {
    --height: 80vh !important;
  }

  .custom-font-size {
    font-size: 12px;
  }

  ion-text {
    p {
      line-height: 20px;
    }
  }

  .occupation-select {
    padding-left: 0;
    --placeholder-opacity: 0.5;
  }

  .datepicker-item {
    --inner-padding-end: 0px;
  }

  .datepicker-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .datepicker-input {
      width: calc(100% - 49px);
    }

    .mat-datepicker-toggle {
      height: 44px;
      width: 44px;
      color: black;

      .mdc-icon-button {
        height: 44px;
        width: 44px;
        padding: 8px;
      }
    }
  }

  .phone-item {
    --padding-start: 0;
  }
  ion-intl-tel-input {
    .ion-intl-tel-input-code,
    .ion-intl-tel-input-flag {
      font-size: 14px;
    }
  }

  .v7-form-item {
    &::part(native) {
      align-items: end;
    }
  }

  .input-icon {
    margin-left: 10px;
  }

  .v7-phone-input {
    height: 40px;
  }

  .v7-form-item.ion-invalid.ion-touched.ion-dirty {
    .v7-select-2 {
      border: 0 !important;
      &::part(container) {
        border: 2px solid var(--ion-color-danger);
      }
    }
  }
}
