el-buen-inquilino-insurances-management-filters {
  .filters-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .filter-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;

      .internal-code-wrapper {
        background-color: white;
        height: 36px;
        border-radius: 10px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        ion-select {
          padding: 0;
        }
      }
    }

    .policy-searchbar {
      min-width: 300px;
      width: 100%;
      max-width: 325px;
      border: 1px solid #ccc;
      border-radius: 10px;
      background: #fff;
      padding: 0 10px;
      height: 36px;
    }
  }

  .select-filter {
    border: 1px solid #ccc;
    border-radius: 10px;
    --background: #fff;
    background: #fff;
    height: 36px;
    min-height: 36px;
    ion-label {
      font-weight: normal;
    }
    max-width: 250px;
    width: 100%;

    ion-select {
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-bottom: 0;
      --padding-top: 0;
      display: grid;
      grid-template-columns: 90% 10%;
      min-height: 36px;
    }
  }

  .date-select {
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    height: 36px;
    min-height: 36px;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-bottom: 0;
    --padding-top: 0;
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .month-select {
    min-width: 130px;
  }
  .year-select {
    min-width: 85px;
  }

  .building-filter-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    ion-select {
      border: 1px solid #ccc;
      border-radius: 10px;
      background: #fff;
      height: 36px;
      min-height: 36px;
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-bottom: 0;
      --padding-top: 0;
      min-width: 105px;
      display: grid;
      grid-template-columns: 80% 20%;
    }
  }

  .searchbar-filter {
    max-width: 250px;
    width: 100%;

    ion-searchbar {
      border: 1px solid #ccc;
      border-radius: 10px;
      min-height: 36px;
    }
  }

  .insurance-searchbar-filter {
    max-width: 275px;
    width: 100%;

    ion-searchbar {
      border: 1px solid #ccc;
      border-radius: 10px;
      min-height: 36px;
    }
  }

  .internal-code-select::part(icon) {
    margin-right: 5px;
  }

  ion-segment {
    height: 36px;
    max-width: 120px;
    width: 100%;
    min-width: 120px;
    --background: var(--ion-color-light-shade) !important;
    ion-segment-button {
      height: 26px;
      margin: 4px !important;
      min-width: 50px !important;
      --padding-end: 4px;
      --padding-start: 4px;
    }
  }

  ion-button {
    min-height: 36px;
    min-width: 110px;

    &.policy-searchbar-icon-btn {
      height: 36px;
      width: 36px;
      min-width: initial;
      margin: 0;
    }
  }
}
