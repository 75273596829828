el-buen-inquilino-add-tenant {
  // deshabilita desplazamiento por cabecera del stepper
  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }
  .mat-step-icon-state-edit,
  .mat-step-icon-selected,
  .mat-step-icon-state-done {
    background-color: var(--ion-color-secondary) !important;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  ion-footer {
    ion-toolbar {
      --padding-start: 1rem;
      --padding-end: 1rem;
      --padding-top: 1rem;
      --padding-bottom: 1rem;
    }
    ion-button {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    .ionic-stepper-horizontal-header-container {
      padding: 1rem;
      background: var(--ion-color-light);
    }

    ion-step-header {
      flex-direction: column !important;
    }

    .ionic-step-header-icon {
      margin: 0 !important;
    }

    .ionic-step-header-text-container .ionic-step-header-label {
      white-space: break-spaces !important;
      text-align: center !important;
    }

    .add-tenant-container {
      height: 100%;
    }
  }
}
