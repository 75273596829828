el-buen-inquilino-sinister-documents {
  .sinisters-wrapper {
    border: 1px solid var(--ion-color-medium);
    padding: 1rem;
    border-radius: 1rem;
  }

  .sinister-more-info {
    display: grid;
    align-items: center;
    grid-template-columns: 15% 50% 35%;
    .sinister-status-wrapper {
      text-align: right;
    }
  }

  .document-chip {
    --background: var(--ion-color-success);
    --color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-top: 0.5rem;
  }
}
