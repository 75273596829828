el-buen-inquilino-profile-preferences {
  ion-card {
    margin: 0;

    ion-card-title {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
    }

    box-shadow: none;
    border-radius: 0.5rem;
    h3 {
      font-size: 16px;
      color: black;
      font-weight: 400;
    }
  }

  .update-btn {
    --padding-start: 1rem;
    --padding-end: 1rem;
  }
}
