el-buen-inquilino-landing-page {
  :root {
    --breakpoint: 600px;
  }

  ion-content {
    --background: #f2f2f2;
    color: var(--ion-color-primary);
    font-size: 18px;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    margin-top: 30px;
  }

  h2 {
    margin: 0;
    font-size: 26px;
    font-weight: bold;
  }

  h3 {
    color: var(--ion-color-secondary);
    text-transform: uppercase;
  }

  hr {
    max-width: 10rem;
  }

  ion-text.title {
    b {
      color: var(--ion-color-secondary);
    }
  }

  .container {
    min-height: calc(50vh + 50px);
    background: linear-gradient(
      150deg,
      rgba(20, 17, 102, 1) 0%,
      rgba(0, 174, 215, 1) 100%
    );
    z-index: -2;
    position: relative;
    color: var(--ion-color-light);
    border-radius: 0;
    overflow: hidden;

    .background {
      background: url('/images/home/stars2.png');
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-size: contain;
      background-repeat: round;
    }

    .scene {
      margin: 0;
      min-height: inherit;
      width: 100%;
      padding: 0;
    }

    .layer {
      width: inherit;
      min-height: inherit;
      div {
        position: absolute;
      }
    }

    .clouds {
      bottom: -20px;
      left: 0;
      width: 100%;
    }

    .rocket {
      height: 100%;
      right: 15%;
      bottom: -20px;
      display: flex;
      align-items: flex-end;
      img {
        height: inherit;
      }
    }

    .planets {
      max-width: 1024px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      padding: 2rem;
      img {
        height: inherit;
      }
    }

    .texts {
      top: 0;
      left: 0;
      padding: 5rem;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        max-width: 100px;
        margin-bottom: 1rem;
      }

      h1 {
        margin: 0;
        font-weight: bolder;
        font-size: 40px;
      }
      h2 {
        font-weight: normal;
        font-size: 32px;
        strong {
          font-weight: bolder;
        }
      }

      h2:first-child {
        margin-bottom: 2rem;
      }
      h2:last-child {
        margin-top: 2rem;
        font-weight: bold;
        display: none;
      }
      ::ng-deep .ebi-span {
        color: #00d8e0 !important;
      }
    }
    .ebi-line {
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 2rem;
      background-color: #021f37;
    }

    ::ng-deep .ebi-span-line {
      color: #00d8e0 !important;
    }

    .lines {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: inherit;
        height: inherit;
      }
    }
  }

  .more-info {
    position: relative;

    .column-hover:hover {
      border-radius: 1.5rem;
      -webkit-box-shadow: 0px 0px 117px 1px rgb(163 157 163);
      -moz-box-shadow: 0px 0px 117px 1px rgba(163, 157, 163, 1);
      box-shadow: 0px 0px 117px 1px rgb(163 157 163);
    }
    .italicDescription {
      font-style: italic;
    }

    .hastag {
      color: var(--ion-color-secondary);
      font-size: 40px;
    }

    .container-row {
      min-height: inherit;
    }
    .dual-phone-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      text-align: center;
      transform: scale(1.75);
      top: 75px;
      z-index: -1;
      img {
        height: inherit;
      }
    }

    .more-info-texts-row {
      padding: 5rem 0;
      h1 {
        margin: 0;
      }
    }

    h2 {
      color: var(--ion-color-medium);
      text-transform: none;
      font-weight: normal;
    }

    .more-info-footer {
      background-color: var(--ion-color-secondary);
      color: var(--ion-color-light);

      .footer-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3rem 2rem;
        gap: 30px;
        font-size: 25px;

        .footer-individuals {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .numbers {
          font-size: 40px;
          font-weight: bolder;
        }

        .separator {
          font-size: 40px;
        }
      }
    }
  }

  .video {
    padding: 3rem 0;

    .video-promo {
      overflow: hidden;
      border-radius: 1rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
      iframe {
        width: 100%;
        height: 100%;
      }
      &-main {
        height: 500px;
        max-width: 889px;
      }
      // &-homeowner {
      //   height: 350px;
      //   max-width: 500px;
      // }
    }
  }

  .video-promo-homeowner {
    height: 350px;
    max-width: 500px;
    margin-top: 30px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .screens {
    &:hover {
      .home_slide1 {
        right: 40%;
        transition: all 0.5s ease;
      }
      .home_slide2 {
        right: 20%;
        z-index: 1;
        transition: all 0.5s ease;
      }
      .home_slide3 {
        right: 0%;
        z-index: 0;
        transition: all 0.5s ease;
      }
    }
    ion-img {
      position: absolute;
      top: 50%;
      margin-top: 20px;
      max-width: 420px;
    }
    .home_slide1 {
      right: 40%;
      z-index: 2;
      transition: all 0.5s ease;
    }
    .home_slide2 {
      right: 30%;
      z-index: 1;
      transition: all 0.5s ease;
    }
    .home_slide3 {
      right: 20%;
      z-index: 0;
      transition: all 0.5s ease;
    }
  }

  .features {
    background: #ffffff;
    padding: 3rem inherit;
    ion-icon {
      position: absolute;
      left: 0;
      top: 2px;
      height: 24px;
      width: 24px;
    }
    ul {
      padding: 0;
      margin: 2rem 0 0 0;
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      li {
        position: relative;
        list-style: none;
        padding: 0 0 1rem 2rem;
        margin-left: 0.7rem;
      }
    }
    .mobile {
      margin-top: 3rem;
    }
  }

  .users {
    background: #ffffff;
    padding: 3rem inherit;
    ion-icon {
      position: absolute;
      left: 0;
      top: 1px;
      height: 24px;
      width: 24px;
    }
    ul {
      padding: 0;
      margin: 2rem 0 0 0;
      li {
        position: relative;
        list-style: none;
        padding: 0 0 1rem 2rem;
        margin-left: 0.7rem;
        p {
          margin: 0;
        }
      }
    }
  }

  .values {
    background: url('/images/home/bg_pattern.png') #ffffff no-repeat center
      bottom;
    padding-top: 3rem;
    height: 820px;
    h3 {
      font-size: 24px;
      font-weight: bold;
    }
    .item {
      padding-top: 30px;
      text-align: center;
      ion-icon {
        width: 40px;
        height: 40px;
        color: var(--ion-color-primary);
        background: #fff;
        padding: 1rem;
        border: 1px solid var(--ion-color-primary);
        border-radius: 100%;
        cursor: pointer;
        &:hover {
          background: var(--ion-color-primary);
          color: #fff;
          transition: all 0.2s ease 0s;
        }
      }
      &-first {
        margin-top: 200px;
      }
      &-third {
        margin-top: 200px;
      }
    }
  }

  .images {
    margin-top: 5rem;
  }
  .extra_feature_ph {
    margin-top: 3rem;
  }
  .app_phone {
    background: url('/images/home/sprite-img.png') no-repeat -49px -104px;
    height: 90px;
    width: 46px;
    margin: auto;
  }
  .pc {
    background: url('/images/home/sprite-img.png') no-repeat -112px -105px;
    height: 90px;
    width: 123px;
    margin: auto;
  }
  .tablet {
    background: url('/images/home/sprite-img.png') no-repeat -253px -104px;
    height: 90px;
    width: 61px;
    margin: auto;
  }

  /* Section - Parallax */
  /**************************/
  .parallax ion-grid {
    ion-icon {
      margin: 1rem 1rem 0 0;
      width: 40px;
      height: 40px;
    }
  }

  .parallax .parallax-one,
  .parallax .parallax-footer,
  .parallax .parallax-metrics {
    position: relative;
    width: 100%;
    background-image: url('/images/bg-colab.jpg');
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    & .parallax-bg {
      background-color: RGBA(2, 31, 55, 0.9);
      color: #fff;
      text-align: center;
      padding-top: 100px;
      padding-bottom: 100px;
    }
    & h3 {
      margin-top: 2rem;
      color: white;
      font-weight: 600;
    }
  }

  .parallax .parallax-footer {
    background-image: url('/images/home/bg_footer.jpg');
    & .parallax-bg {
      background-color: RGBA(2, 31, 55, 0.9);
      text-align: left;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .parallax .parallax-metrics {
    background-image: url('/images/home/bg_footer.jpg');
    & .parallax-bg {
      padding: 2rem;
    }
  }

  .ion-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    &:hover {
      background-color: var(--ion-color-secondary);
      transition: all 0.5s ease;
      h2,
      ion-icon,
      p {
        color: white;
        transition: all 0.3s ease;
      }
    }
    ion-icon {
      width: 50px;
      height: 50px;
    }
    h2 {
      width: 100%;
      font-size: 30px;
    }
    p {
      font-size: 20px;
    }
  }

  ion-grid {
    padding: 0 6rem;
  }

  /* Media Queries */
  /**************************/
  @media screen and (max-width: 1920px) {
    .texts {
      padding: 4rem 5rem !important;
      h2 {
        font-size: 30px !important;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .texts {
      padding: 3rem 5rem !important;
      h2 {
        font-size: 28px !important;
      }
    }

    .dual-phone-wrapper {
      transform: scale(1.5) !important;
    }

    .footer-container {
      font-size: 20px !important;
    }
  }
  @media screen and (max-width: 1024px) {
    h1 {
      font-weight: bold;
      font-size: 30px;
      margin: 0;
    }
    p {
      margin: 0;
    }
    h2 {
      margin: 0;
      font-size: 22px;
      font-weight: bold;
    }
    h3 {
      color: var(--ion-color-secondary);
      text-transform: uppercase;
    }
    ion-grid {
      padding: 0 2rem;
    }
    .features {
      padding: 2rem 0;
      ul {
        padding: 0;
        margin: 0;
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
      }
      .mobile {
        margin-top: 1rem;
      }
    }
    .values {
      padding-top: 3rem;
      height: auto;
      .item {
        &-first {
          margin-top: 0;
        }
        &-third {
          margin-top: 0;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .texts {
      padding: 3rem !important;
      h2 {
        font-size: 22px !important;
      }
    }

    .rocket {
      right: 8% !important;
    }

    .dual-phone-wrapper {
      transform: scale(1.25) !important;
      top: 10px !important;
    }

    .footer-container {
      font-size: 20px !important;
      .numbers {
        font-size: 30px !important;
      }
    }
  }
  /* Media Queries */
  /**************************/
  @media (min-width: 1024px) and (max-width: 1280px) {
    .features {
      ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
      }
    }
  }
  @media (max-width: 991px) {
    .rocket {
      height: 90% !important;
    }

    .dual-phone-wrapper {
      transform: none !important;
      position: initial !important;
      display: flex;
      align-items: center;
      img {
        height: auto !important;
      }
    }

    .footer-container {
      font-size: 18px !important;
      flex-direction: column;
      gap: 10px !important;
      .numbers {
        font-size: 25px !important;
      }
      .separator-container {
        transform: rotate(90deg);
      }
    }
    .video {
      padding: 3rem 1rem;
    }

    .video-promo-main {
      width: 100%;
    }
  }

  @media (max-width: 767px) and (orientation: portrait) {
    .texts {
      padding: 2rem 3rem !important;
      max-width: 100% !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
      padding: 0 !important;
      div {
        width: 100%;
      }
      h2 {
        font-size: 25px !important;
      }
    }

    .container,
    .more-info,
    .values,
    .multidevice-section {
      min-height: calc(100vh - 50px);
    }

    .container {
      .texts {
        text-align: center;
      }
    }

    .rocket {
      justify-content: center !important;
      right: initial !important;
      height: 80% !important;
      bottom: -40px !important;
    }

    .more-info {
      .container-row {
        padding: 1rem 0;
        min-height: initial;
      }
    }

    .video {
      min-height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  @media (max-width: 575px) {
    ion-content {
      --padding-bottom: 130px;
    }
    .rocket {
      height: 60% !important;
      left: initial !important;
      width: 100% !important;
    }

    .h2-slogan {
      display: none !important;
    }
    .texts {
      h2:last-child {
        display: block !important;
      }
    }
  }

  @media (max-width: 575px) and (orientation: portrait) {
    .rocket {
      height: 60% !important;
    }
  }

  @media (max-height: 800px) {
    .container {
      min-height: calc(100vh - 50px) !important;
    }
  }
}
