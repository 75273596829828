el-buen-inquilino-name-avatar-generator {
  .wrapper {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    font-weight: bold;
  }
}
