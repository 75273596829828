el-buen-inquilino-more-filters-popover {
  .btn-item {
    --border-width: 1px;
    --border-color: #ccc;
    --border-radius: 10px;
  }

  ion-col {
    padding: 10px;
  }

  .date-select {
    --padding-start: 7.5px;
    --padding-end: 5px;
    --padding-bottom: 0;
    --padding-top: 0;
    font-weight: 500;
  }

  .date-error {
    --border-color: red !important;
  }

  @media (min-width: 768px) {
    .month-col {
      padding-right: 5px;
    }
    .year-col {
      padding-left: 5px;
    }
  }
}
