el-buen-inquilino-candidatures-page {
  ion-content {
    --background: #f2f2f2;
  }

  ion-card {
    border-radius: 1rem;
  }

  .card-content-ios p {
    margin: 0;
  }

  ion-title ion-label {
    font-weight: normal;
    & + ion-label::before {
      content: ' · ';
      font-size: 10px;
      vertical-align: 2px;
      padding: 0 0.25rem;
      color: #aaa;
    }
    &.link {
      cursor: pointer;
      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }

  ion-footer {
    ion-toolbar {
      --background: #f2f2f2;
      --border-width: 0;
      ion-item {
        --background: transparent;
      }
    }
  }
  .expiration-date {
    padding-top: 10px;
    float: left;
    color: var(--ion-color-primary);
  }
  .request-date {
    font-size: 12px;
    float: right;
    margin-right: 1rem;
    color: var(--ion-color-primary);
  }
  .request-date-end {
    font-size: 12px;
    float: right;
    margin-right: 1rem;
    color: var(--ion-color-primary);
    padding-top: 10px;
  }
  .end-row {
    display: flex;
    justify-content: flex-end;

    .expiration-date {
      margin-right: auto;
    }
    // ion-button {
    //   float: right;
    // }
  }
  ion-buttons {
    ion-chip,
    ion-button,
    ion-label {
      margin-right: 0.5rem;
    }
  }

  .border-dotted {
    border-bottom: 1px solid #eee;
  }
  .user-info {
    --padding-start: 0.5rem;
  }

  ion-avatar {
    ion-icon {
      border-radius: 100%;
      border: 2px solid var(--ion-color-light-shade);
      color: var(--ion-color-light-shade);
      font-size: 2.4rem;
      margin-top: -3px;
      &.guarantor {
        border: 2px solid var(--ion-color-success);
        color: var(--ion-color-success);
      }
      &.freelance {
        border: 2px solid var(--ion-color-dark);
        color: var(--ion-color-dark);
      }
      &.student {
        color: var(--ion-color-warning);
        border: 2px solid var(--ion-color-warning);
      }
      &.retired {
        border: 2px solid var(--ion-color-medium);
        color: var(--ion-color-medium);
      }
      &.business {
        border: 2px solid var(--ion-color-primary);
        color: var(--ion-color-primary);
      }
      &.worker {
        border: 2px solid var(--ion-color-secondary);
        color: var(--ion-color-secondary);
      }
    }
  }

  $dot-size: 14px;

  p {
    &.guarantor {
      &::before {
        content: '·';
        border-radius: 100%;
        display: inline-block;
        height: $dot-size;
        width: $dot-size;
        vertical-align: sub;
        background-color: var(--ion-color-success);
        color: var(--ion-color-success);
      }
    }
    &.freelance {
      &::before {
        content: '·';
        border-radius: 100%;
        display: inline-block;
        height: $dot-size;
        width: $dot-size;
        vertical-align: sub;
        background-color: var(--ion-color-dark);
        color: var(--ion-color-dark);
      }
    }
    &.student {
      &::before {
        content: '·';
        border-radius: 100%;
        display: inline-block;
        height: $dot-size;
        width: $dot-size;
        vertical-align: sub;
        background-color: var(--ion-color-warning);
        color: var(--ion-color-warning);
      }
    }
    &.retired {
      &::before {
        content: '·';
        border-radius: 100%;
        display: inline-block;
        height: $dot-size;
        width: $dot-size;
        vertical-align: sub;
        background-color: var(--ion-color-medium);
        color: var(--ion-color-medium);
      }
    }
    &.business {
      &::before {
        content: '·';
        border-radius: 100%;
        display: inline-block;
        height: $dot-size;
        width: $dot-size;
        vertical-align: sub;
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary);
      }
    }
    &.worker {
      &::before {
        content: '·';
        border-radius: 100%;
        display: inline-block;
        height: $dot-size;
        width: $dot-size;
        vertical-align: sub;
        background-color: var(--ion-color-light-shade);
        color: var(--ion-color-light-shade);
      }
    }
  }

  ion-text.price {
    cursor: help;
  }

  ion-card.pendingToDownload {
    border: 1px solid var(--ion-color-warning);
    ion-row.pendingToDownload {
      background-color: var(--ion-color-warning);
      color: white;
    }
  }

  .no-candidatures-text-wrapper {
    border: 1px solid #f2994a;
    padding: 1rem;
    border-radius: 1rem;
    background: rgba(242, 153, 74, 0.08);
    .no-candidatures-text-title {
      color: #f2994a;
    }
  }

  .see-doc-btn {
    margin-left: auto;
  }
}
