el-buen-inquilino-update-sinister-modal {
  .btn {
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 10px;
    --padding-bottom: 10px;
  }

  .datepicker-wrapper {
    &.error-input {
      border-color: var(--highlight-color-invalid);
      border-width: 2px;
    }
  }

  .datepicker-wrapper {
    width: 100%;
    input {
      width: calc(100% - 40px);
      padding-left: 1rem;
    }
  }

  $mat-dapepicker-toggle-px: 36px;
  .mat-datepicker-toggle {
    height: $mat-dapepicker-toggle-px !important;
    width: $mat-dapepicker-toggle-px !important;

    .mdc-icon-button {
      height: $mat-dapepicker-toggle-px !important;
      width: $mat-dapepicker-toggle-px !important;
      padding: 4px !important;
    }
  }

  .datepicker-label {
    font-size: 13px !important;
    margin-bottom: 3px !important;
  }

  fieldset {
    border: 1px solid #ddd;
    padding: 1rem;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    legend {
      padding: 0 10px;
      float: none;
      margin: 0;
      width: auto;
      font-size: 1rem;
    }
  }

  .label-detail {
    font-weight: bold;
  }

  ul {
    padding-left: 1rem;
  }

  .notify-email-item {
    &::part(native) {
      display: flex;
      align-items: end;
    }
  }

  .notify-email-btn {
    margin: 0;
    font-size: 18px !important;
    margin-bottom: 8px;
  }
}
