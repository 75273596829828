el-buen-inquilino-homeowner-insurance-statistics {
  .statistics-container,
  .numbers-wrapper {
    display: flex;
    gap: 1rem;
  }

  .numbers-wrapper {
    flex-direction: column;
  }

  .number-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    min-width: 200px;
    height: 142px;
    justify-content: center;
  }

  .year-wrapper {
    padding: 1rem;
    min-width: 600px;
    height: 300px;
    background-color: white;
    border-radius: 10px;
  }
}
