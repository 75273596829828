el-buen-inquilino-snack-bar {
  .snackbar-wrap {
    height: 0px;
    position: fixed;
    top: 1rem;
    left: 50%;
    min-width: 20%;
    transform: translate(-50%, 0%);
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;

    &.success {
      background: rgb(0, 173, 72);
    }
    .snackbar-text {
      color: white;
      margin: 0;
    }
  }

  ion-item {
    background-color: rgba(0, 0, 0, 0);
  }

  .bg-transparent {
    --ion-item-background: transparent;
    --ion-item-color: #fff;
  }
}
