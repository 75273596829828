el-buen-inquilino-policies-page {
  ion-row {
    margin: 0;
    ion-col {
      padding: 0.5rem 0.8rem;
    }
    &.table-header {
      background-color: var(--ion-color-dark);
      color: var(--ion-color-light);
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
    }
    &.table-body {
      cursor: pointer;
      color: var(--ion-color-primary);
      font-size: 14px;
      p {
        margin: 0;
      }
      &:last-child {
        border-bottom-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
      &:first-child {
        font-weight: 500;
        background-color: var(--ion-color-dark);
      }
      &:hover {
        background-color: var(--ion-color-secondary) !important;
        color: white;
        ion-chip {
          color: white !important;
        }
        .insurance-subtitle {
          span {
            color: white;
          }
        }
      }
      &:nth-child(even) {
        background-color: var(--ion-color-light-tint);
      }
    }
  }

  .filters-col {
    margin-top: 1rem;
    padding: 0.5rem 1.5rem !important;
  }

  .table-col {
    padding: 0.5rem 0.5rem !important;
  }

  .pocket-profits {
    margin: 0;
  }

  .tabs {
    cursor: pointer;
    margin: 5px 0;
    .index {
      display: flex;
      * {
        border: 1px solid #ccc;
        background: #eee;
        padding: 4px 12px;
        border-radius: 4px 4px 0 0;
        position: relative;
        top: 1px;
      }
      .active {
        background: #fff;
        border-bottom: 1px solid transparent;
      }
    }
    .contents {
      border: 1px solid #ccc;
      min-height: 120px;
      padding: 12px;
    }
  }

  .insurance-address-wrapper {
    .insurance-title {
      font-weight: 500;
    }
    .insurance-subtitle {
      font-size: 12px;
      text-transform: capitalize;
      span {
        color: var(--ion-color-secondary);
        &:hover {
          color: white;
        }
      }
    }
  }
}
