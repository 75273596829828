el-buen-inquilino-move-candidature-modal {
  // deshabilita desplazamiento por cabecera del stepper
  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }
  .mat-step-icon-state-edit,
  .mat-step-icon-selected,
  .mat-step-icon-state-done {
    background-color: var(--ion-color-secondary) !important;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }

  .wrapper {
    overflow-y: auto;
  }

  .confirmation-wrapper {
    padding: 1rem 1.5rem;
  }

  .tenants-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    ion-chip {
      font-weight: bold;
    }
  }
}
