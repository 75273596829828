el-buen-inquilino-incidences-list {
  width: 100%;

  .filters-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .filter-wrapper {
  }

  .incidence-types-select {
    width: 100%;

    &::part(icon) {
      margin-top: 18px;
    }
  }

  .material-picker-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;

    .mat-datepicker-toggle {
      height: 35px;
      width: 35px;

      .mdc-icon-button {
        height: 35px;
        width: 35px;
        padding: 4px;
      }

      .mat-mdc-button-touch-target {
        height: 35px;
        width: 35px;
      }
    }
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .icon-medium {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    font-weight: bold;
  }

  table {
    width: 100%;

    .info-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--ion-color-medium);
      font-size: 0.83rem;
      margin: 0 0.3rem;

      ion-icon {
        font-size: 1.3rem;
        margin-right: 0.2rem;
      }
    }

    ion-button {
      --background: transparent;
      font-size: 20px !important;
    }

    .status-wrapper {
      border: 1px solid;
      width: 100%;
      padding: 6px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &.type-incidence {
        border-color: var(--ion-color-white-shade);
        color: var(--ion-color-dark);
        background: var(--ion-color-white-shade);
      }

      &.status-new {
        border-color: var(--ion-color-warning);
        color: var(--ion-color-warning);
      }

      &.status-escalated {
        border-color: var(--ion-color-secondary);
        color: var(--ion-color-secondary);
      }

      &.status-closed {
        border-color: var(--ion-color-success);
        color: var(--ion-color-success);
      }

      &.status-cancelled {
        border-color: var(--ion-color-danger);
        color: var(--ion-color-danger);
      }

      &.status-ejecuccion {
        border-color: var(--ion-color-primary);
        color: var(--ion-color-primary);
      }
    }
  }
}
