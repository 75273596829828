el-buen-inquilino-guarantor-home-page {
  .profile {
    background-color: #f5f6f9;
    height: 100%;
    overflow-y: scroll;
    .ebi-alert {
      background-color: var(--ion-color-warning-tint);
    }
  }

  ion-item {
    --inner-padding-end: 0px;
    --padding-start: 0px;
    color: var(--ion-color-primary);
  }

  ul > li {
    font-size: 14px;
    margin-bottom: 4px;
  }

  ion-label.guarantor-name-label {
    color: var(--ion-color-primary);
    opacity: 0.5;
  }

  ::ng-deep .custom-alert {
    .danger {
      color: tomato;
    }
  }

  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
  }

  ion-card {
    margin: 16px;
  }
}
