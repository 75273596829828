el-buen-inquilino-header {
  .header {
    ion-toolbar {
      --background: white;
    }
    .logo-box {
      height: 100%;
      width: 200px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .only-logo {
      border: 0px;
    }
    ion-buttons {
      padding-right: 1rem;
    }
    ion-button:not(.landing-buttons) {
      margin: 0 !important;
      font-size: 14px;
    }
  }
  .headerIframe {
    font-size: 18px;
  }

  .landing-buttons {
    margin-right: 1rem;
    --padding-bottom: 10px;
    --padding-top: 10px;
    --padding-start: 1rem;
    --padding-end: 1rem;
  }

  .ebi-mobile {
    max-height: 30px;
  }
  .userName {
    span {
      text-transform: capitalize;
      margin-right: 0.5rem;
    }
  }
  @media (max-width: 767px) {
    .landing-buttons {
      margin-right: 0;
    }
  }
}
