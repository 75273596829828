el-buen-inquilino-modal-building-report {
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .rotate-icon {
    animation: rotate 2s infinite linear; /* Ajusta la duración y las propiedades según tus necesidades */
  }

  .charts-container {
    overflow: hidden;
  }
}
