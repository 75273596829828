el-buen-inquilino-confirmation-sinister-modal {
  .image-wrapper {
    width: 100%;
    height: 350px;
    text-align: center;
    margin: 2rem 0;
    img {
      height: 100%;
      width: auto;
    }
  }
}
