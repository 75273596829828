el-buen-inquilino-asset-page {
  ion-content {
    --background: #f2f2f2;
    --padding-bottom: 3rem;
  }
  ion-header ion-button {
    margin: 0 !important;
  }

  ion-title ion-label {
    font-weight: normal;
    & + ion-label::before {
      content: ' · ';
      font-size: 10px;
      vertical-align: 2px;
      padding: 0 0.25rem;
      color: #aaa;
    }
    &.link {
      cursor: pointer;
      &:hover {
        color: var(--ion-color-secondary);
      }
    }
  }

  .notVisitedAsset {
    background-color: rgba(var(--ion-color-warning-rgb), 0.1);
  }

  .ion-flex {
    display: flex;
  }

  .asset-actions {
    display: flex;
    align-items: flex-start;
    ion-button {
      --padding-start: 0;
      --padding-end: 0;
      padding: 0;
    }
  }

  ion-button.icon-xl {
    --border-radius: 1rem;
    --padding-start: 0;
    --padding-end: 0;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0;
  }

  ion-toolbar ion-buttons ion-icon {
    margin-right: 0.3rem;
  }

  ion-buttons {
    ion-button {
      font-size: 14px;
      margin-left: 0.5rem !important;
    }
  }

  /* Set the flex in order to size the text width to its content */
  ion-select::part(placeholder),
  ion-select::part(text) {
    flex: 0 0 auto;
  }

  /* Set the placeholder color and opacity */
  ion-select::part(placeholder) {
    opacity: 1;
  }

  /* Set the icon color and opacity */
  ion-select::part(icon) {
    opacity: 1;
  }

  .label-witout-checking {
    text-align: end;
  }
  .request-date {
    display: inline-flex;
    font-size: 12px;
    float: right;
    margin-right: 1rem;
  }
  .document-icon {
    font-size: medium;
  }
  .document-icon-red {
    color: var(--ion-color-danger);
  }
  ion-item.ion-no-padding {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }
  .percentage-box {
    @media (min-width: 500px) {
      padding-left: 1rem;
    }
  }
  @media (max-width: 380px) {
    ion-item {
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --padding-start: 0.5rem;
      --inner-padding-start: 0px;
    }
  }

  .pill-round {
    display: flex;
    padding: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: white;
    margin: 1px 4px 0 0;
  }

  .incidence-item {
    --background: transparent;
    --padding-start: 0;
  }

  .incidence-checkbox {
    margin-right: 5px;
  }

  .toggle-show-open-closed {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
