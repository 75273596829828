el-buen-inquilino-more-register-info-modal {
  ion-text.error-msg {
    margin-left: 1rem;
  }

  .save-btn {
    --padding-start: 10px;
    --padding-end: 10px;
  }

  ion-radio-group.role-radio-group {
    display: flex;
    gap: 5%;
    ion-radio {
      width: 50%;
      border: 2px solid #ddd;
      border-radius: 8px;
      padding: 1rem 0.5rem;

      &::part(label) {
        width: 100%;
        margin: 0;
        text-align: center;
      }

      &::part(container) {
        display: none;
      }

      &.radio-checked {
        background-color: var(--ion-color-primary);
        color: white;
        border: 2px solid var(--ion-color-primary);
      }
    }
  }

  .datepicker-wrapper {
    width: 100%;
    max-height: 44px;
    height: 100%;
    padding: 10px 10px 10px 1rem;
    display: grid !important;
    grid-template-columns: auto 30px;
  }

  .datepicker-toggle-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  $mat-dapepicker-toggle-px: 24px;
  .mat-datepicker-toggle {
    display: block;
    height: $mat-dapepicker-toggle-px !important;
    width: $mat-dapepicker-toggle-px !important;

    .mdc-icon-button {
      height: $mat-dapepicker-toggle-px !important;
      width: $mat-dapepicker-toggle-px !important;
      padding: 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mat-mdc-button-touch-target {
      height: calc($mat-dapepicker-toggle-px + 4px) !important;
      width: calc($mat-dapepicker-toggle-px + 4px) !important;
    }

    .mat-datepicker-toggle-default-icon {
      height: calc($mat-dapepicker-toggle-px - 6px) !important;
      width: calc($mat-dapepicker-toggle-px - 6px) !important;
    }
  }

  ion-list-header {
    padding: 0;
    ion-label {
      margin: 0;
    }
  }

  .radios-wrapper {
    display: flex;
    ion-radio {
      max-width: 75px;
      &::part(container) {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #ddd;
      }
      &.radio-checked::part(container) {
        background: var(--ion-color-primary);
        border-color: transparent;
      }
      &.radio-checked::part(mark) {
        width: 6px;
        height: 10px;
        border-width: 0px 2px 2px 0px;
        border-style: solid;
        border-color: #fff;
        transform: rotate(45deg);
      }
    }
  }
}
