el-buen-inquilino-step-two {
  .email-text {
    font-size: 12px;
  }

  .icon-unselect {
    float: right;
  }

  .tenant {
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  ion-item {
    --padding-end: 10px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  .guarantor {
    border: 2px solid green;
  }

  ion-avatar {
    margin: 0 1.5rem 0 1rem;
  }

  ion-badge.guarantor {
    position: absolute;
    left: 9px;
    bottom: 12px;
    font-size: 8px;
    border: none;
  }
}
