el-buen-inquilino-status-info-modal {
  .content-wrapper {
    height: 100%;
  }

  .status-wrapper {
    height: 100%;
  }

  .title-wrapper {
    padding: 1rem;
    text-align: center;
  }

  .status-desc-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    p {
      width: 75%;
      text-align: center;
    }

    img {
      max-height: 350px;
      max-width: 90%;
    }
  }

  .status-desc-image-wrapper {
    text-align: center;
  }

  div {
    p {
      margin: 0;
    }
  }

  .status-badge {
    --padding-bottom: 5px;
    --padding-top: 5px;
    --padding-start: 10px;
    --padding-end: 10px;
    min-width: 115px;
  }

  .status-unfilled {
    --background: #f2994a;
  }
  .status-incidence {
    --background: #eb5757;
  }
  .status-revision {
    --background: #2d9cdb;
  }
  .status-selected {
    --background: #219653;
  }
  .status-pre-selected {
    --background: var(--ion-color-primary);
  }
  .status-rejected {
    --background: #828282;
  }
  .status-rejected-tenant {
    --background: var(--ion-color-danger);
  }
  .status-expired {
    --background: #828282;
  }
}
