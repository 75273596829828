el-buen-inquilino-tab-document-page {
  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
    --padding-bottom: 3rem !important;
  }

  ion-item.-student {
    --border-radius: 8px;
  }

  .-tab-height {
    height: 60vh;
  }

  ion-card {
    &[color='transparent'] {
      border: 2px dashed var(--ion-color-primary);
    }
    min-height: 240px;
    height: 100%;
    &.document-card {
      display: flex;
      flex-direction: column;
    }
  }

  ion-card-header {
    padding-bottom: 20px;
  }

  ion-card-content {
    padding: 1rem 36px 20px 36px !important;
    margin: 0 !important;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.document-content,
    &.upload-content {
      flex-grow: 1;
      padding: 0 20px 20px !important;
    }
  }

  .card-btn-wrapper {
    flex-grow: 1;
    display: grid;
    place-items: end stretch;
  }

  .ebi-card-footer {
    &.document-footer {
      display: grid;
      grid-template-columns: repeat(auto-fill, 75px);
      gap: 0.5rem;
      place-items: end;
      flex-grow: 1;
    }
    ion-button {
      margin: 0;
      --padding-start: 26px;
      --padding-end: 26px;
    }
  }

  .truncate {
    margin: 0;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .-no-min-height {
    min-height: auto;
  }
}
