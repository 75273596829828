el-buen-inquilino-phone-register {
  ion-item {
    --background: #fff;
  }

  .no-show {
    display: none;
  }

  .show {
    display: block;
  }

  .phone-item,
  .code-item {
    --padding-top: 5px;
    --padding-bottom: 1rem;
  }
  .v7-form-item {
    &::part(native) {
      align-items: end;
    }
  }

  .input-icon {
    margin-left: 10px;
  }
}
