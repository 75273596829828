el-buen-inquilino-home-owner-profile-page {
  ion-header {
    ion-toolbar {
      --background: white;
    }
  }

  ion-content {
    --background: #f2f2f2;
    --padding-bottom: 3rem;
  }

  ion-label,
  ion-icon {
    color: var(--ion-color-primary);
    opacity: 0.5;
  }

  /* ::placeholder {
  opacity: 1 !important;
  color: red !important;
} */

  ::ng-deep ::placeholder {
    opacity: 0.5 !important;
    color: #aaa !important;
  }

  .language-select-wrapper {
    display: grid;
    grid-template-columns: 54px max-content min-content;
    align-items: center;
    .icon-wrapper {
      text-align: center;
      ion-icon {
        font-size: 22px;
      }
    }
  }

  .phone-wrapper {
    display: grid;
    grid-template-columns: 54px max-content auto;
    align-items: center;
    .icon-wrapper {
      text-align: center;
      ion-icon {
        font-size: 22px;
      }
    }

    ion-label {
      margin-right: 8px;
    }

    .ion-intl-wrapper {
      ion-intl-tel-input {
        color: black;

        .ion-intl-tel-input-code,
        .ion-intl-tel-input-flag {
          font-size: 14px;
        }
      }
    }
  }

  ::ng-deep .ionic-selectable-value-item {
    font-size: 14px;
  }

  .has-error,
  .language-select-wrapper-error {
    border-bottom: 2px solid var(--ion-color-danger);
  }

  ion-card {
    margin: 1rem;
    h4 {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
      color: var(--ion-color-primary);
    }
  }

  ion-toggle::part(label) {
    white-space: break-spaces;
  }
}
