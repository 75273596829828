el-buen-inquilino-insurance-page {
  ion-card {
    margin: 0;
  }

  h3,
  p {
    margin: 0;
  }

  .center-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .insurance-banner {
    background-color: var(--ion-color-primary);
    color: white;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;

    .icon-wrapper {
      background-color: white;
      color: var(--ion-color-primary);
      height: 50px;
      width: 50px;
      min-width: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      ion-icon {
        font-size: 25px;
      }
    }

    .text-wrapper {
      p {
        margin-top: 0.5rem !important;
      }
    }
  }

  h3 {
    font-weight: bold !important;
    font-size: 18px !important;
  }

  .insurance-wrapper {
    border: 1px solid var(--ion-color-secondary);
    border-radius: 1rem;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    cursor: pointer;

    h3 {
      color: var(--ion-color-secondary);
    }
  }

  .insurance-more-info {
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    position: absolute;
    left: 2rem;
    top: 30px;
    border-radius: 50%;
    color: white;
    cursor: pointer;

    ion-icon {
      font-size: 20px;
    }

    &.contracted-insurance {
      background-color: #219652;
    }
    &.in-progress-insurance {
      background-color: var(--ion-color-warning);
    }
  }

  .download-wrapper {
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    position: absolute;
    right: 2rem;
    top: 30px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    background-color: var(--ion-color-secondary);

    ion-icon {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    ion-content {
      --padding-bottom: 150px;
    }
  }

  @media (min-width: 768px) {
    .insurance-banner {
      padding: 2rem;
      gap: 1.5rem;
      flex-direction: initial;
      text-align: initial;
    }
  }
}
