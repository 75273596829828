el-buen-inquilino-tenant-profile-page {
  ion-icon {
    color: var(--ion-color-primary);
    opacity: 0.5;
  }

  fieldset {
    padding: 0;
  }

  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
    --padding-bottom: 3rem;
  }

  ::ng-deep .custom-alert {
    .danger {
      color: tomato;
    }
  }

  ion-item {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  .select-language-item {
    display: grid;
    grid-template-columns: 44px auto auto;
    align-items: center;
  }

  ion-icon[name='language-outline'] {
    font-size: 22px;
  }

  .phone-wrapper {
    display: grid;
    grid-template-columns: 42px max-content auto;
    align-items: center;
    .icon-wrapper {
      ion-icon {
        font-size: 22px;
      }
    }
  }
  .ion-intl-wrapper {
    width: 100%;
    ion-intl-tel-input {
      width: 100%;
      color: black;
      text-align: right;

      .ion-intl-tel-input-code,
      .ion-intl-tel-input-flag {
        font-size: 14px;
      }
    }
  }

  ion-item.-info {
    --border-radius: 10px;
    text-align: left;
    --padding-top: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
  }

  ::ng-deep .ionic-selectable-value-item {
    font-size: 14px;
  }

  .has-error {
    border-bottom: 2px solid var(--ion-color-danger);
  }

  ion-card {
    margin: 1rem;
    h4 {
      font-size: 1.1rem !important;
      font-weight: 500 !important;
      color: var(--ion-color-primary);
    }

    .current-address-wrapper,
    .professional-data-wrapper {
      margin-top: 1rem;
    }
  }

  .user-data-form,
  .form {
    ion-item {
      &::part(native) {
        align-items: end;
      }
    }
  }

  .separator {
    height: 100%;
    border: 2px solid var(--ion-color-light-shade);
    width: 0;
    border-radius: 5px;
  }

  ion-text.error-msg {
    margin-left: 54px;
  }
}
