el-buen-inquilino-associate-asset {
  .modal-content-wrapper {
    overflow-y: scroll;
    position: relative;
    ion-searchbar {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: white;
    }
  }

  .select-list ion-item {
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
    --inner-padding-start: 0px;
  }

  ion-card-content ion-item {
    --inner-padding-end: 0px;
    --padding-start: 0px;
  }
  .ion-label {
    white-space: break-spaces !important;
  }
}
