el-buen-inquilino-demo {
  ion-grid {
    height: 100%;
  }

  ion-item {
    --ion-background-color: transparent;
  }

  .logo {
    width: 70%;
  }

  h2 {
    font-size: 2em;
    font-weight: bold;
  }
  h3 {
    font-size: 1.25em;
    font-weight: bold;
  }
  h4 {
    font-size: 1.15em;
  }
  h5 {
    font-size: 1.1em;
  }

  .demo-container {
    height: 90%;
  }
}
