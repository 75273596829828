el-buen-inquilino-documents-page {
  ion-content {
    --background: #f2f2f2;
    --offset-bottom: 0px !important;
    // --padding-bottom: 3rem;
  }
  ion-card-header {
    padding: 20px 20px 0 20px;
  }
  ion-card-content {
    ion-button {
      --padding-start: 0;
      --padding-end: 0;
    }
  }
  hr {
    margin: 8px 0 0 0;
  }

  ion-item.-student {
    --border-radius: 10px;
  }

  .-tab-height {
    height: 100%;
  }

  ion-tab-bar {
    height: 5rem;
  }

  ion-tab-button {
    text-align: left;
  }

  .isActive {
    background-color: var(--ion-color-primary) !important;
    ion-label {
      color: white !important;
    }
  }

  ion-item {
    --ion-background-color: transparent;
  }

  ion-avatar {
    border: 2px solid white;
  }

  .-not-register-user {
    border: 3px solid #ffc409;
  }

  swiper-slide {
    .name {
      height: 100%;
      &::part(native) {
        height: 100%;
      }
    }
  }

  .carousel-container {
    height: 100%;
    width: 100%;
  }

  .carousel {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .carousel-inner {
    display: flex;
    transition: transform 0.3s ease-in-out;
    height: 100%;
  }

  .carousel-item {
    height: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
