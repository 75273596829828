el-buen-inquilino-portfolio-management-page {
  el-buen-inquilino-portfolio-roles-page {
    ion-content {
      --padding-top: 1rem;
      --padding-start: 1rem;
      --padding-end: 1rem;
      --padding-bottom: 1rem;
    }
  }
}
