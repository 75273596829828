el-buen-inquilino-selected-candidature-sinisters {
  ion-toolbar {
    --background: var(--ion-color-light);

    ion-segment {
      --background: var(--ion-color-light);

      ion-segment-button {
        --background: var(--ion-color-light-tint);
        --indicator-color: var(--ion-color-light-shade);
        --background-checked: var(--ion-color-light-tint);
        --border-color: var(--ion-color-dark-tint)
      }
    }
  }


  table {
    border: 0px !important;
    min-width: 100% !important;

    tbody {
      tr {
        margin: 0.5rem 0;

        td {
          padding-top: 0.7rem !important;
          padding-bottom: 0.7rem !important;
        }
      }
    }
  }

  .icon-medium {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    font-weight: bold;
  }

  el-buen-inquilino-shared-sinisters {
    ion-card {
      padding: 0 !important;
    }

    ion-grid {
      padding: 0 !important;
    }
  }
}
