el-buen-inquilino-cancel-policy-request-modal {
  .footer-btn {
    --padding-start: 10px;
    --padding-end: 10px;
    margin: 0;
  }

  ion-textarea {
    border: 1px solid var(--ion-color-light-shade);
    --padding-start: 10px !important;
    border-radius: 1rem;
    height: 150px;
  }

  .file-wrapper {
    position: relative;
    border: 1px solid var(--ion-color-secondary);
    border-radius: 10px;
    padding: 0.5rem 1rem;
    width: fit-content;

    .delete-file-btn {
      position: absolute;
      top: -12.5px;
      right: -12.5px;
      background: black;
      color: white;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }

  .image-wrapper {
    width: 100%;
    height: 150px;
    text-align: center;
    margin: 1rem 0;
    img {
      height: 100%;
      width: auto;
    }
  }

  ion-textarea {
    .textarea-wrapper-inner {
      height: 100%;
    }
  }

  .material-picker-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    .mat-datepicker-toggle {
      height: 35px;
      width: 35px;

      .mdc-icon-button {
        height: 35px;
        width: 35px;
        padding: 4px;
      }

      .mat-mdc-button-touch-target {
        height: 35px;
        width: 35px;
      }
    }
  }
}
