el-buen-inquilino-user-search-list {
  .user-info-item {
    p {
      font-weight: bold;
    }
  }

  ion-card {
    border: 1px solid rgba(5, 28, 44, 0.1);
    ion-card-header {
      padding-top: 20px !important;
    }
  }

  .user-type-select {
    min-width: 100px;
    margin-right: 1rem;
  }

  .mat-mdc-card-header {
    flex-direction: column !important;
  }

  .mat-card-header-text {
    margin: 0 !important;
  }

  mat-expansion-panel {
    border-radius: 0 !important;
    border-top: 1px solid #e6e6e6;
    margin-top: 5px;
    box-shadow: none !important;
    mat-expansion-panel-header {
      height: auto !important;
      padding: 5px 0;
      &:hover {
        background: white !important;
      }
      .tenant-info-item {
        width: 100%;
      }
    }
  }
}
