el-buen-inquilino-home-owner-incidences-page {
  ion-header {
    ion-toolbar {
      --background: white;
    }
  }

  ion-content {
    --background: #f2f2f2;
  }

  el-buen-inquilino-incidences-list {
    font-size: 0.875rem;
  } 
}
